import React from 'react';

import { RangeInputProps } from './types';

/**
 * Range input component.
 * @param value - Initial value.
 * @param unit - Unit of the value
 * @param className - Custom classes.
 * @param onChange - A function what will be called when the value was changed.
 */
const RangeInput: React.FC<RangeInputProps> = ({
  value = '0',
  unit,
  className = '',
  onChange,
  ...rest
}) => {
  const [currentValue, setCurrentValue] = React.useState<string>(value);

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div>
      <div className="input-group">
        <input
          {...rest}
          type="range"
          value={currentValue}
          className={`mr-2 p-0 form-control bg-secondary border border-dark rounded-0 shadow-none ${className}`}
          onChange={handleChange}
        />
        <span className="input-group-text center ml-1 w-25 d-inline-block">
          {currentValue + (unit ? unit : '')}
        </span>
      </div>
    </div>
  );
};

export default RangeInput;
