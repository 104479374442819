import React, { useEffect, useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useFormValidation } from 'shared';

import { Card, Image, Button, Input } from 'web-components';

import { FormControl, Error, Link } from '@components';

import { ROUTE } from '@navigation/types';

import { resetPassword } from '../../store/auth/actions';
import { AppState } from '../../store/types';

import Images from '../../utils/images';
import ResetPasswordSchema from '../../utils/validation/ResetPasswordSchema';

import styles from './ResetPasswordScreen.module.scss';
import { ResetPasswordFields } from './types';

const ResetPasswordScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { resetPasswordError, isResetPasswordLoading, isResetPasswordSuccess } = useSelector(
    (state: AppState) => state.auth,
  );

  const [fields, setFields] = React.useState<ResetPasswordFields>({
    password: '',
    verifyPassword: '',
  });
  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [token, setToken] = React.useState<string | null>(null);
  const [userId, setUserId] = React.useState<string | null>(null);
  const [isUrlValid, setIsUrlValid] = React.useState<boolean | null>(false);

  const { isFormValid, validationErrors } = useFormValidation<ResetPasswordFields>(
    ResetPasswordSchema,
    fields,
  );

  useLayoutEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tokenParam = params.get('token');
    const idParam = params.get('id');
    setToken(tokenParam);
    setUserId(idParam);

    setIsUrlValid(!!tokenParam && !!idParam);
  }, [setToken, setUserId, setIsUrlValid]);

  useEffect(() => {
    setErrorMessage(
      (isFormSubmitted && validationErrors[Object.keys(validationErrors)[0]]) || resetPasswordError,
    );
  }, [isFormSubmitted, validationErrors, resetPasswordError]);

  const onFieldChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    setIsFormSubmitted(true);

    if (isFormValid) {
      dispatch(
        resetPassword({
          token: token as string,
          userId: userId as string,
          password: fields.password,
        }),
      );
    }
  };

  return (
    <div
      className={`container-fluid vh-100 d-flex bg-primary mt-2 ${styles.resetPasswordScreenContainer}`}
    >
      <div className={`d-flex flex-column align-items-center ${styles.resetPasswordContainerSize}`}>
        <Image src={Images.LOGIN_LOGO} />
        {!isUrlValid ? (
          <div>{t('reset_password_screen.invalid_url')}</div>
        ) : (
          <form className="text-right text-secondary mt-4 w-100" onSubmit={onSubmit} noValidate>
            <Card additionalStyles={`text-primary mb-5 mt-2 w-100 ${styles.card}`}>
              <>
                <FormControl>
                  <Input
                    placeholder={t('reset_password_screen.password')}
                    type="password"
                    name="password"
                    value={fields.password}
                    onChange={onFieldChange}
                    className={styles.input}
                  />
                </FormControl>

                <FormControl>
                  <Input
                    placeholder={t('reset_password_screen.verify_password')}
                    type="password"
                    name="verifyPassword"
                    value={fields.verifyPassword}
                    onChange={onFieldChange}
                    className={styles.input}
                  />
                </FormControl>

                <div className="mt-4">{errorMessage && <Error error={errorMessage} />}</div>

                {isResetPasswordSuccess && (
                  <div className="mt-3 text-success">{t('reset_password_screen.success')}</div>
                )}

                <Link to={ROUTE.Login} type="small" className="text-decoration-none">
                  {t('common.go_to_login')}
                </Link>
              </>
            </Card>

            <Button
              type="submit"
              additionalStyles={`${styles.button} w-100 text-uppercase text-primary btn-lg font-italic text-primary bg-light mb-5 text-center text-normal`}
              disabled={isResetPasswordSuccess || isResetPasswordLoading}
            >
              {isResetPasswordLoading
                ? t('reset_password_screen.sending')
                : t('reset_password_screen.send')}
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
