import React, { useEffect, useRef, useState, useCallback } from 'react';

import { Trainer } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useListModal, useScrollPagination, getShortenedText, getFullName } from 'shared';

import { Button } from 'web-components';

import {
  AreYouSureModal,
  ListItem,
  ListItemActions,
  SearchBar,
  Loading,
  TopScrollButton,
  Error,
  RatingStars,
} from '@components';

import useToastNotification from '../../hooks/useToastNotification';

import {
  createTrainer,
  deleteTrainer,
  getTrainers,
  incTrainersCurrentPage,
  resetTrainersCurrentPage,
  setCreateTrainerError,
  setTrainersGetParams,
  setUpdateTrainerError,
  setGetTrainersError,
  updateTrainer,
} from '../../store/trainer/actions';
import { AppState } from '../../store/types';

import TrainerDetailsModal from './TrainerDetailsModal';
import TrainerFormModal from './TrainerFormModal';
import { TrainerFormData } from './TrainerFormModal/types';
import styles from './TrainerListScreen.module.scss';

const TrainerListScreen: React.FC = () => {
  const { t, i18n } = useTranslation();
  const {
    trainerList,
    getParams,
    isTrainerListLastPageReached,
    isUpdateTrainerSuccess,
    isCreateTrainerSuccess,
    isDeleteTrainerSuccess,
    isDeleteTrainerLoading,
    isGetTrainersLoading,
    getTrainersError,
    getAllTrainersError,
    createTrainerError,
    updateTrainerError,
    deleteTrainerError,
  } = useSelector((state: AppState) => state.trainer);

  const dispatch = useDispatch();

  const { loadingIndicator } = useScrollPagination(trainerList.length > 0, () =>
    dispatch(incTrainersCurrentPage()),
  );

  const { errorNotification, removeAllNotifications } = useToastNotification();
  const [enableToastMessage, setEnableToastMessage] = useState<boolean>(false);
  const [validationErrorMessage, setValidationErrorMessage] =
    useState<string | undefined>(undefined);

  const needToFetchData = useRef(!trainerList.length);

  const showErrorMessage = useCallback((message: string) => {
    errorNotification(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setGetTrainersError(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getTrainersError && enableToastMessage) {
      showErrorMessage(getTrainersError);
    }
    if (getAllTrainersError && enableToastMessage) {
      showErrorMessage(getAllTrainersError);
    }
    if (createTrainerError && enableToastMessage) {
      showErrorMessage(createTrainerError);
    }
    if (updateTrainerError && enableToastMessage) {
      showErrorMessage(updateTrainerError);
    }
    if (deleteTrainerError && enableToastMessage) {
      showErrorMessage(deleteTrainerError);
    }
  }, [
    getTrainersError,
    getAllTrainersError,
    createTrainerError,
    updateTrainerError,
    deleteTrainerError,
    enableToastMessage,
    showErrorMessage,
  ]);

  useEffect(() => {
    if (isCreateTrainerSuccess) {
      onFormModalClose();

      dispatch(resetTrainersCurrentPage());
      dispatch(setTrainersGetParams({ ...getParams, page: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateTrainerSuccess, dispatch]);

  useEffect(() => {
    if (isUpdateTrainerSuccess) {
      onFormModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateTrainerSuccess, dispatch]);

  useEffect(() => {
    if (isDeleteTrainerSuccess) {
      dispatch(getTrainers(getParams));
      onAreYouSureModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteTrainerSuccess, dispatch]);

  useEffect(() => {
    //prevent unnecessary reload after return to the current screen with loaded data
    if (needToFetchData.current && !isTrainerListLastPageReached) {
      dispatch(getTrainers(getParams, (getParams.page ?? 1) > 1));
    }

    if (!needToFetchData.current) {
      needToFetchData.current = true;
    }
  }, [getParams, dispatch, isTrainerListLastPageReached]);

  useEffect(() => {
    removeAllNotifications();
    // (removeAllNotifications is excluded bc it doesn't work with that)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateTrainerSuccess, isUpdateTrainerSuccess, isDeleteTrainerSuccess]);

  const onSaveButtonClicked = (data: TrainerFormData, id?: string) => {
    setEnableToastMessage(true);
    if (validationErrorMessage) {
      showErrorMessage(validationErrorMessage);
    } else if (data) {
      if (!id) {
        dispatch(createTrainer(data));
      } else {
        dispatch(updateTrainer(id, data));
      }
    }
  };

  const onDelete = (item: Trainer) => {
    setEnableToastMessage(true);
    dispatch(deleteTrainer(item._id));
  };

  const onStartSearch = (searchTerm: string): void => {
    dispatch(resetTrainersCurrentPage());
    dispatch(setTrainersGetParams({ ...getParams, page: undefined, search: searchTerm }));
  };

  const onCancelButtonClicked = () => {
    dispatch(setUpdateTrainerError(undefined));
    dispatch(setCreateTrainerError(undefined));

    onFormModalClose();
  };

  const {
    showDetailsModal,
    showFormModal,
    showAreYouSureModal,
    modalData,
    onItemDetailsClick,
    onNewItemClick,
    onEditItemClick,
    onDeleteItemClick,
    onDetailsModalClose,
    onFormModalClose,
    onAreYouSureModalClose,
  } = useListModal();

  const listItemLeftContent = (item: Trainer) => (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <RatingStars
            visibleOnly
            rating={item.rateSum}
            ratersNumber={item.rateCount}
            containerAdditionalStyles="p-0"
            colAdditionalStyles="p-0"
          />
        </div>
      </div>
      <div className="row">
        <div className={`col ${styles.description}`}>
          <p>{getShortenedText(item.description, 60)}</p>
        </div>
      </div>
    </div>
  );

  const listItemRightContent = (item: Trainer): React.ReactElement => (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <ListItemActions
            editLabel={t('trainer_list_screen.edit_trainer')}
            deleteLabel={t('trainer_list_screen.delete_trainer')}
            onDetailsClick={(): void => onItemDetailsClick(item)}
            onEditClick={(): void => onEditItemClick(item)}
            onDeleteClick={(): void => onDeleteItemClick(item)}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="container p-5">
      <div className="row">
        <div className="col-7">
          <SearchBar
            className={`${styles.input} w-100 font-weight-bold`}
            initialValue={getParams.search}
            onStartSearch={onStartSearch}
          />
        </div>
        <div className="col-3">
          <Button
            additionalStyles={`bg-secondary text-primary btn-sm font-weight-bold w-100 ${styles.button}`}
            onClick={onNewItemClick}
          >
            {t('trainer_list_screen.add_new')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-10">
          <div className="container-fluid mt-5 mb-5">
            {getTrainersError && <Error error={getTrainersError} />}

            {trainerList.map((item) => (
              <ListItem
                key={item._id}
                title={getFullName(item.firstName, item.lastName, i18n.language)}
                imageHash={item.pictures?.hash ?? ''}
                imageUrl={item.pictures?.defaultThumbnail ?? ''}
                imageClassName={styles.listItemImage}
                className="my-2"
                leftContent={listItemLeftContent(item)}
                rightContent={listItemRightContent(item)}
              />
            ))}

            {isGetTrainersLoading && <Loading />}

            <div ref={loadingIndicator} />
          </div>
        </div>
      </div>
      <TopScrollButton containerId="root_router" />
      {modalData && (
        <TrainerDetailsModal
          data={modalData as Trainer}
          show={showDetailsModal}
          onCancel={onDetailsModalClose}
        />
      )}
      <TrainerFormModal
        data={modalData as Trainer}
        show={showFormModal}
        onSave={onSaveButtonClicked}
        onCancel={onCancelButtonClicked}
        onError={(message) => setValidationErrorMessage(message)}
      />
      <AreYouSureModal
        show={showAreYouSureModal}
        isLoading={isDeleteTrainerLoading}
        error={deleteTrainerError}
        onNoClick={onAreYouSureModalClose}
        onYesClick={() => onDelete(modalData as Trainer)}
        title={t('trainer_form_modal.delete_title')}
        yesButtonTitle={t('trainer_form_modal.delete_yes_button_title')}
      />
    </div>
  );
};

export default TrainerListScreen;
