import { getErrorMessage } from 'shared';
import * as yup from 'yup';

import {
  AdminFormData,
  AdminFormFieldName,
} from '../../screens/AdminListScreen/AdminFormModal/types';
const AdminSchema: yup.SchemaOf<AdminFormData> = yup.object().shape({
  [AdminFormFieldName.email]: yup
    .string()
    .required(getErrorMessage('email', 'email_required'))
    .email(getErrorMessage('email', 'email_invalid')),
  [AdminFormFieldName.firstName]: yup
    .string()
    .required(getErrorMessage('firstName', 'first_name_required')),
  [AdminFormFieldName.lastName]: yup
    .string()
    .required(getErrorMessage('lastName', 'last_name_required')),
  [AdminFormFieldName.roles]: yup
    .array().min(1, getErrorMessage('roles', 'roles_required')),
});

export default AdminSchema;
