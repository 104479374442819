import React, { useEffect } from 'react';

import { PromoCode, PromoCodeType } from 'interfaces';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { useFormValidation } from 'shared';

import { Modal, ModalRow } from 'web-components';

import {
  DateSelector,
  FormControl,
  RangeInput,
  Switch,
  Select,
  TextInput,
  Loading,
} from '@components';

import { AppState } from '../../../store/types';
import PromoCodeSchema from '../../../utils/validation/PromoCodeSchema';
import styles from '../PromoCodeListScreen.module.scss';

import { PromoCodeFormData, PromoCodeFormFieldName, PromoCodeFormModalProps } from './types';

const formData = (data?: PromoCode) => ({
  code: data?.code || '',
  type: data?.type || PromoCodeType.OneTime,
  percentage: data?.percentage || 0,
  isActive: data?.isActive || false,
  startDate: data?.startDate || '',
  expirationDate: data?.expirationDate || '',
  description: data?.description || undefined,
  email: data?.email || undefined,
});

/**
 * Promo code form modal to add or edit a user.
 * @param data - The promo code's data.
 * @param show - If 'true', the modal is displayed.
 * @param onSave - A function what will be called when the save button was pressed.
 * @param onCancel - A function what will be called when the cancel button was pressed.
 */
const PromoCodeFormModal: React.FC<PromoCodeFormModalProps> = ({
  data,
  onSave,
  onCancel,
  onError,
  ...rest
}) => {
  const { t } = useTranslation();

  const [form, setForm] = React.useState<PromoCodeFormData>(formData(data));
  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const {
    isUpdatePromoCodeLoading,
    isCreatePromoCodeLoading,
    isGenerateCodeLoading,
    generatedCode,
  } = useSelector((state: AppState) => state.promoCode);
  const { validationErrors } = useFormValidation<PromoCodeFormData>(PromoCodeSchema, form);

  useEffect(() => {
    setForm({ ...form, code: generatedCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedCode]);

  useEffect(() => {
    setIsFormSubmitted(false);
    setForm(formData(data));
  }, [rest.show, data]);

  React.useEffect(() => {
    const errorMessage = validationErrors[Object.keys(validationErrors)[0]];

    onError(errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationErrors]);

  React.useEffect(() => {
    if (isFormSubmitted) {
      onSave(form, data?._id);

      setIsFormSubmitted(false);
    }
  }, [isFormSubmitted, onSave, form, data?._id]);

  useEffect(() => {
    setIsSaving(isCreatePromoCodeLoading || isUpdatePromoCodeLoading);
  }, [isCreatePromoCodeLoading, isUpdatePromoCodeLoading]);

  const onFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onNumberFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: +value });
  };

  const onDateFieldChange = (date: Date, field: string): void => {
    setForm({ ...form, [field]: date });
  };

  const onSwitchChange = (name: string, value: boolean): void => {
    setForm({ ...form, [name]: value });
  };

  const onSaveButtonClicked = () => {
    setIsFormSubmitted(true);
  };

  const onCancelButtonClicked = () => {
    setIsFormSubmitted(false);

    onCancel();
  };

  return (
    <Modal
      {...rest}
      title={t(
        data ? 'promo_code_form_modal.edit_promo_code' : 'promo_code_form_modal.new_promo_code',
      )}
      actionButtons={[
        {
          text: t('common.rejection'),
          onClick: onCancelButtonClicked,
          classes: ['bg-rejection', 'text-primary', 'font-weight-bold', styles.button],
        },
        {
          text: isSaving ? t('common.saving') : t('common.save'),
          arguments: isSaving ? [{ name: 'disabled', value: true }] : [],
          onClick: (): void => onSaveButtonClicked(),
          classes: ['bg-secondary', 'text-primary', 'font-weight-bold', styles.button],
        },
      ]}
    >
      {isGenerateCodeLoading ? (
        <Loading />
      ) : (
        <form className="text-uppercase" noValidate>
          <ModalRow
            items={[
              <div key="1">
                <label>{t('promo_code_form_modal.code')}</label>
                <FormControl>
                  <TextInput
                    name={PromoCodeFormFieldName.code}
                    value={form.code}
                    disabled={!!data}
                    onChange={onFieldChange}
                  />
                </FormControl>
              </div>,
              <div key="2">
                <label>{t('promo_code_form_modal.type')}</label>
                <FormControl>
                  <Select
                    name={PromoCodeFormFieldName.type}
                    value={form.type}
                    options={[
                      {
                        text: t('promo_code_list_screen.single_use'),
                        value: PromoCodeType.OneTime,
                      },
                      {
                        text: t('promo_code_list_screen.period_based'),
                        value: PromoCodeType.Period,
                      },
                    ]}
                    onChange={onFieldChange}
                  />
                </FormControl>
              </div>,
            ]}
            className="p-2"
          />
          <ModalRow
            items={[
              <div key="3">
                <label>{t('promo_code_form_modal.discount')}</label>
                <FormControl>
                  <RangeInput
                    name={PromoCodeFormFieldName.percentage}
                    value={form.percentage.toString()}
                    onChange={onNumberFieldChange}
                    unit="%"
                  />
                </FormControl>
              </div>,
              <div key="4">
                <label>{t('promo_code_form_modal.status')}</label>
                <FormControl>
                  <Switch
                    label={form.isActive ? t('common.active_status') : t('common.inactive_status')}
                    name={PromoCodeFormFieldName.isActive}
                    checked={form.isActive}
                    onChange={onSwitchChange}
                  />
                </FormControl>
              </div>,
            ]}
            className="p-2"
          />
          <ModalRow
            items={[
              <div key="5">
                <label>{t('promo_code_form_modal.description')}</label>
                <FormControl>
                  <TextInput
                    name={PromoCodeFormFieldName.description}
                    value={form.description}
                    onChange={onFieldChange}
                  />
                </FormControl>
              </div>,
              <div key="6">
                <label>{t('promo_code_form_modal.email')}</label>
                <FormControl>
                  <TextInput
                    name={PromoCodeFormFieldName.email}
                    disabled={form.type != PromoCodeType.OneTime || !!data}
                    value={form.type === PromoCodeType.OneTime ? form.email : ''}
                    onChange={onFieldChange}
                  />
                </FormControl>
              </div>,
            ]}
            className="p-2"
          />
          <ModalRow
            items={[
              <div key="7">
                <label>{t('promo_code_form_modal.start_date')}</label>
                <FormControl>
                  <DateSelector
                    name={PromoCodeFormFieldName.startDate}
                    value={form.startDate}
                    dateFormat="yyyy-MM-dd"
                    onChange={(date: Date) =>
                      onDateFieldChange(date ?? undefined, PromoCodeFormFieldName.startDate)
                    }
                  />
                </FormControl>
              </div>,
              <div key="8">
                <label>{t('promo_code_form_modal.end_date')}</label>
                <FormControl>
                  <DateSelector
                    name={PromoCodeFormFieldName.expirationDate}
                    value={form.expirationDate}
                    dateFormat="yyyy-MM-dd"
                    onChange={(date: Date) =>
                      onDateFieldChange(date ?? undefined, PromoCodeFormFieldName.expirationDate)
                    }
                  />
                </FormControl>
              </div>,
            ]}
            className="p-2"
          />
        </form>
      )}
    </Modal>
  );
};

export default PromoCodeFormModal;
