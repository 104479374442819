import { GetPromoCodesParams, PromoCodeClient } from 'api_client';

import { isLastPageReached } from 'api_client';
import { PromoCode } from 'interfaces';

import { ThunkDispatch } from 'redux-thunk';

import { Action, Dispatch } from "redux";


import { PromoCodeFormData } from '../../screens/PromoCodeListScreen/PromoCodeFormModal/types';

import { TAppState } from "../index";

import { AppThunk } from '../types';

import {
  SET_CREATE_PROMO_CODE_ERROR,
  SET_CREATE_PROMO_CODE_LOADING,
  SET_CREATE_PROMO_CODE_SUCCESS,
  SET_GENERATED_CODE,
  SET_GET_PROMO_CODES_ERROR,
  SET_GET_PROMO_CODES_LOADING,
  SET_UPDATE_PROMO_CODE_ERROR,
  SET_UPDATE_PROMO_CODE_LOADING,
  SET_UPDATE_PROMO_CODE_STATUS_ERROR,
  SET_UPDATE_PROMO_CODE_STATUS_LOADING,
  SET_UPDATE_PROMO_CODE_STATUS_SUCCESS,
  SET_UPDATE_PROMO_CODE_SUCCESS,
  SET_PROMO_CODES,
  SET_DELETE_PROMO_CODE_SUCCESS,
  SET_GENERATE_CODE_SUCCESS,
  SET_DELETE_PROMO_CODE_ERROR,
  SET_GENERATE_CODE_ERROR,
  SET_DELETE_PROMO_CODE_LOADING,
  SET_GENERATE_CODE_LOADING,
  APPEND_PROMO_CODES,
  SET_PROMO_CODES_LAST_PAGE_REACHED,
  INC_PROMO_CODES_CURRENT_PAGE,
  RESET_PROMO_CODES_CURRENT_PAGE,
  SET_PROMO_CODES_GET_PARAMS,
} from './actionTypes';

export const setPromoCodes = (promoCodes: PromoCode[]): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_PROMO_CODES,
    payload: promoCodes,
  });
};

export const setPromoCodesGetParams = (params: GetPromoCodesParams): AppThunk => (
  dispatch: Dispatch,
): void => {
  dispatch({
    type: SET_PROMO_CODES_GET_PARAMS,
    payload: params,
  });
};

export const appendPromoCodes = (users: PromoCode[]): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: APPEND_PROMO_CODES,
    payload: users,
  });
};

export const setPromoCodesLastPageReached = (reached: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_PROMO_CODES_LAST_PAGE_REACHED,
    payload: reached,
  });
};

export const setGeneratedCode = (code?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GENERATED_CODE,
    payload: code,
  });
};

export const setCreatePromoCodeSuccess = (isSuccess: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_CREATE_PROMO_CODE_SUCCESS,
    payload: isSuccess,
  });
};

export const setUpdatePromoCodeSuccess = (isSuccess: boolean, data?: PromoCode): AppThunk => (
  dispatch: Dispatch,
): void => {
  dispatch({
    type: SET_UPDATE_PROMO_CODE_SUCCESS,
    payload: {
      success: isSuccess,
      data: data,
    },
  });
};

export const setUpdatePromoCodeStatusSuccess = (isSuccess: boolean, data?: PromoCode): AppThunk => (
  dispatch: Dispatch,
): void => {
  dispatch({
    type: SET_UPDATE_PROMO_CODE_STATUS_SUCCESS,
    payload: {
      success: isSuccess,
      data: data,
    },
  });
};

export const setDeletePromoCodeSuccess = (isSuccess: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_DELETE_PROMO_CODE_SUCCESS,
    payload: isSuccess,
  });
};

export const setGenerateCodeSuccess = (isSuccess: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GENERATE_CODE_SUCCESS,
    payload: isSuccess,
  });
};

export const setGetPromoCodesError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GET_PROMO_CODES_ERROR,
    payload: error,
  });
};

export const setCreatePromoCodeError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_CREATE_PROMO_CODE_ERROR,
    payload: error,
  });
};

export const setUpdatePromoCodeError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_UPDATE_PROMO_CODE_ERROR,
    payload: error,
  });
};

export const setUpdatePromoCodeStatusError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_UPDATE_PROMO_CODE_STATUS_ERROR,
    payload: error,
  });
};

export const setDeletePromoCodeError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_DELETE_PROMO_CODE_ERROR,
    payload: error,
  });
};

export const setGenerateCodeError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GENERATE_CODE_ERROR,
    payload: error,
  });
};

export const setGetPromoCodesLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GET_PROMO_CODES_LOADING,
    payload: isLoading,
  });
};

export const setCreatePromoCodeLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_CREATE_PROMO_CODE_LOADING,
    payload: isLoading,
  });
};

export const setUpdatePromoCodeLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_UPDATE_PROMO_CODE_LOADING,
    payload: isLoading,
  });
};

export const setUpdatePromoCodeStatusLoading = (isLoading: boolean): AppThunk => (
  dispatch: Dispatch,
): void => {
  dispatch({
    type: SET_UPDATE_PROMO_CODE_STATUS_LOADING,
    payload: isLoading,
  });
};

export const setDeletePromoCodeLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_DELETE_PROMO_CODE_LOADING,
    payload: isLoading,
  });
};

export const setGenerateCodeLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GENERATE_CODE_LOADING,
    payload: isLoading,
  });
};

export const incPromoCodesCurrentPage = (): AppThunk => (dispatch: Dispatch, getState): void => {
  if (!getState().promoCode.isPromoCodeListLastPageReached) {
    dispatch({
      type: INC_PROMO_CODES_CURRENT_PAGE,
    });
  }
};

export const resetPromoCodesCurrentPage = (): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: RESET_PROMO_CODES_CURRENT_PAGE,
  });
};

export const getPromoCodes = (params?: GetPromoCodesParams, lazyLoad = false): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setGetPromoCodesLoading(true));

    const promoCodes = await PromoCodeClient.getAll(params);

    if (lazyLoad) {
      dispatch(appendPromoCodes(promoCodes));
    } else {
      dispatch(setPromoCodes(promoCodes));
    }

    dispatch(setPromoCodesLastPageReached(isLastPageReached(promoCodes, params)));
  } catch (error) {
    dispatch(setGetPromoCodesError(error.message));
  } finally {
    dispatch(setGetPromoCodesLoading(false));
  }
};

export const generateCode = (): AppThunk => async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
  try {
    dispatch(setGenerateCodeLoading(true));
    dispatch(setGenerateCodeSuccess(false));

    const code = await PromoCodeClient.generate();

    dispatch(setGeneratedCode(code));
    dispatch(setGenerateCodeSuccess(true));
  } catch (error) {
    dispatch(setGenerateCodeError(error.message));
  } finally {
    dispatch(setGenerateCodeLoading(false));
  }
};

export const createPromoCode = (promoCodeData: PromoCodeFormData): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setCreatePromoCodeLoading(true));
    dispatch(setCreatePromoCodeSuccess(false));

    const requestData = {
      code: promoCodeData.code,
      type: promoCodeData.type,
      percentage: promoCodeData.percentage,
      startDate: promoCodeData.startDate,
      expirationDate: promoCodeData.expirationDate,
      description: promoCodeData.description,
      email: promoCodeData.email,
      isActive: promoCodeData.isActive,
    };

    await PromoCodeClient.create(requestData);

    dispatch(setCreatePromoCodeSuccess(true));
  } catch (error) {
    dispatch(setCreatePromoCodeError(error.message));
  } finally {
    dispatch(setCreatePromoCodeLoading(false));
  }
};

export const updatePromoCode = (id: string, promoCodeData: PromoCodeFormData): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setUpdatePromoCodeLoading(true));
    dispatch(setUpdatePromoCodeSuccess(false));

    const requestData = {
      type: promoCodeData.type,
      percentage: promoCodeData.percentage,
      startDate: promoCodeData.startDate,
      expirationDate: promoCodeData.expirationDate,
      description: promoCodeData.description,
      isActive: promoCodeData.isActive,
    };

    const data = await PromoCodeClient.update(id, requestData);

    dispatch(setUpdatePromoCodeSuccess(true, data));
  } catch (error) {
    dispatch(setUpdatePromoCodeError(error.message));
  } finally {
    dispatch(setUpdatePromoCodeLoading(false));
  }
};

export const updatePromoCodeStatus = (id: string, isActive: boolean): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setUpdatePromoCodeStatusLoading(true));
    dispatch(setUpdatePromoCodeStatusSuccess(false));

    const data = await PromoCodeClient.update(id, { isActive });

    dispatch(setUpdatePromoCodeStatusSuccess(true, data));
  } catch (error) {
    dispatch(setUpdatePromoCodeStatusError(error.message));
  } finally {
    dispatch(setUpdatePromoCodeStatusLoading(false));
  }
};

export const deletePromoCode = (id: string): AppThunk => async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
  try {
    dispatch(setDeletePromoCodeLoading(true));
    dispatch(setDeletePromoCodeSuccess(false));

    await PromoCodeClient.delete(id);

    dispatch(setDeletePromoCodeSuccess(true));
  } catch (error) {
    dispatch(setDeletePromoCodeError(error.message));
  } finally {
    dispatch(setDeletePromoCodeLoading(false));
  }
};
