import React, { useState } from 'react';

import styles from './ImageInput.module.scss';
import { ImageInputProps } from './types';

/**
 * Image input component.
 * @param url - The source url.
 * @param type - The type of the image (default/small).
 * @param name - The name of the image input.
 * @param alt - The input's alt text.
 * @param onChange - A function what will be called when the url was changed.
 * @param onDelete - A function what will be called when the delete button was pressed.
 * @param className - Custom classes.
 */
const ImageInput: React.FC<ImageInputProps> = (props) => {
  const [fileInputKey, setFileInputKey] = useState('');
  const { url, type = 'default', name, className = '', onChange, onDelete, ...rest } = props;

  const imageStyle = type === 'default' ? styles.image : styles.smallImage;

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files && e.target.files[0];

    setFileInputKey(file?.lastModified ? file?.lastModified.toString() : '');

    onChange(file ?? undefined);
  };

  const onImageDelete = (): void => {
    setFileInputKey('');

    onDelete();
  };

  return (
    <div className={`h-100 position-relative d-inline-block ${className}`}>
      <img {...rest} src={url} className={`border border-dark ${imageStyle}`} />
      <input
        type="file"
        name={name}
        key={fileInputKey}
        className={`h-100 position-absolute ${imageStyle} ${styles.input}`}
        onChange={onImageChange}
      />
      <div
        className={`position-absolute d-flex rounded-circle bg-dark text-white ${styles.delete}`}
        onClick={onImageDelete}
      >
        {/* TODO: if we get icons, change it */}
        &#215;
      </div>
    </div>
  );
};

export default ImageInput;
