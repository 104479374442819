import { GetUsersParams, SubscriptionClient, UserClient, isLastPageReached } from 'api_client';

import { User } from 'interfaces';

import { ThunkDispatch } from 'redux-thunk';

import { Action, Dispatch } from 'redux';

import { UserFormData } from '../../screens/UserListScreen/UserFormModal/types';

import { TAppState } from '../index';

import { AppThunk } from '../types';

import {
  APPEND_USERS,
  INC_USERS_CURRENT_PAGE,
  RESET_USERS_CURRENT_PAGE,
  SET_CANCEL_SUBSCRIPTION_ERROR,
  SET_CANCEL_SUBSCRIPTION_LOADING,
  SET_CANCEL_SUBSCRIPTION_SUCCESS,
  SET_CREATE_USER_ERROR,
  SET_CREATE_USER_LOADING,
  SET_CREATE_USER_SUCCESS,
  SET_DISABLE_PROMOCODE_LOADING,
  SET_DISABLE_PROMOCODE_SUCCESS,
  SET_DISABLE_PROMOCODE_ERROR,
  SET_GET_USERS_ERROR,
  SET_GET_USERS_LOADING,
  SET_UPDATE_USER_ERROR,
  SET_UPDATE_USER_LOADING,
  SET_UPDATE_USER_STATUS_ERROR,
  SET_UPDATE_USER_STATUS_LOADING,
  SET_UPDATE_USER_STATUS_SUCCESS,
  SET_UPDATE_USER_SUCCESS,
  SET_USERS,
  SET_USERS_GET_PARAMS,
  SET_USERS_LAST_PAGE_REACHED,
} from './actionTypes';

export const setUsers =
  (users: User[]): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_USERS,
      payload: users,
    });
  };

export const setUsersGetParams =
  (params: GetUsersParams): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_USERS_GET_PARAMS,
      payload: params,
    });
  };

export const appendUsers =
  (users: User[]): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: APPEND_USERS,
      payload: users,
    });
  };

export const setUsersLastPageReached =
  (reached: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_USERS_LAST_PAGE_REACHED,
      payload: reached,
    });
  };

export const setCreateUserSuccess =
  (isSuccess: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_CREATE_USER_SUCCESS,
      payload: isSuccess,
    });
  };

export const setUpdateUserSuccess =
  (isSuccess: boolean, data?: User): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_UPDATE_USER_SUCCESS,
      payload: {
        success: isSuccess,
        data: data,
      },
    });
  };

export const setUpdateUserStatusSuccess =
  (isSuccess: boolean, data?: User): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_UPDATE_USER_STATUS_SUCCESS,
      payload: {
        success: isSuccess,
        data: data,
      },
    });
  };

export const setCancelSubscriptionSuccess =
  (isSuccess: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_CANCEL_SUBSCRIPTION_SUCCESS,
      payload: isSuccess,
    });
  };

  export const setDisablePromoCodeSuccess =
  (isSuccess: boolean, user?: User): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_DISABLE_PROMOCODE_SUCCESS,
      payload: {
        success: isSuccess,
        data: user
      }
    })
  }

export const setGetUsersError =
  (error?: string): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_GET_USERS_ERROR,
      payload: error,
    });
  };

export const setCreateUserError =
  (error?: string): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_CREATE_USER_ERROR,
      payload: error,
    });
  };

export const setUpdateUserError =
  (error?: string): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_UPDATE_USER_ERROR,
      payload: error,
    });
  };

export const setUpdateUserStatusError =
  (error?: string): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_UPDATE_USER_STATUS_ERROR,
      payload: error,
    });
  };

export const setCancelSubscriptionError =
  (error?: string): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_CANCEL_SUBSCRIPTION_ERROR,
      payload: error,
    });
  };

  export const setDisablePromoCodeError =
  (error?: string): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_DISABLE_PROMOCODE_ERROR,
      payload: error
    })
  }

export const setGetUsersLoading =
  (isLoading: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_GET_USERS_LOADING,
      payload: isLoading,
    });
  };

export const setCreateUserLoading =
  (isLoading: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_CREATE_USER_LOADING,
      payload: isLoading,
    });
  };

export const setUpdateUserLoading =
  (isLoading: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_UPDATE_USER_LOADING,
      payload: isLoading,
    });
  };

export const setUpdateUserStatusLoading =
  (isLoading: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_UPDATE_USER_STATUS_LOADING,
      payload: isLoading,
    });
  };

export const setCancelSubscriptionLoading =
  (isLoading: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_CANCEL_SUBSCRIPTION_LOADING,
      payload: isLoading,
    });
  };

export const setDisablePromoCodeLoading = 
(isLoading: boolean): AppThunk =>
(dispatch: Dispatch): void => {
  dispatch({
    type: SET_DISABLE_PROMOCODE_LOADING,
    payload: isLoading
  })
}

export const incUsersCurrentPage =
  (): AppThunk =>
  (dispatch: Dispatch, getState): void => {
    if (!getState().user.isUserListLastPageReached) {
      dispatch({
        type: INC_USERS_CURRENT_PAGE,
      });
    }
  };

export const resetUsersCurrentPage =
  (): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: RESET_USERS_CURRENT_PAGE,
    });
  };

export const getUsers =
  (params?: GetUsersParams, lazyLoad = false): AppThunk =>
  async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
    try {
      dispatch(setGetUsersLoading(true));

      const users = await UserClient.getAll(params);

      if (lazyLoad) {
        dispatch(appendUsers(users));
      } else {
        dispatch(setUsers(users));
      }

      dispatch(setUsersLastPageReached(isLastPageReached(users, params)));
    } catch (error) {
      dispatch(setGetUsersError(error.message));
    } finally {
      dispatch(setGetUsersLoading(false));
    }
  };

export const createUser =
  (userData: UserFormData): AppThunk =>
  async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
    try {
      dispatch(setCreateUserLoading(true));
      dispatch(setCreateUserSuccess(false));

      const requestData = {
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        billingInfo: {
          companyName: userData.companyName,
          companyVatId: userData.companyVatId,
          address: userData.address,
          zipCode: userData.zipCode,
          city: userData.city,
          country: userData.country,
        },
        roles: userData.roles,
      };

      await UserClient.create(requestData);

      dispatch(setCreateUserSuccess(true));
    } catch (error) {
      dispatch(setCreateUserError(error.message));
    } finally {
      dispatch(setCreateUserLoading(false));
    }
  };

export const updateUser =
  (id: string, userData: UserFormData): AppThunk =>
  async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
    try {
      dispatch(setUpdateUserLoading(true));
      dispatch(setUpdateUserSuccess(false));

      const requestData = {
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        billingInfo: {
          companyName: userData.companyName,
          companyVatId: userData.companyVatId,
          address: userData.address,
          zipCode: userData.zipCode,
          city: userData.city,
          country: userData.country,
        },
        roles: userData.roles,
      };

      const data = await UserClient.update(id, requestData);

      dispatch(setUpdateUserSuccess(true, data));
    } catch (error) {
      dispatch(setUpdateUserError(error.message));
    } finally {
      dispatch(setUpdateUserLoading(false));
    }
  };

export const updateUserStatus =
  (id: string, disabled: boolean): AppThunk =>
  async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
    try {
      dispatch(setUpdateUserStatusLoading(true));
      dispatch(setUpdateUserStatusSuccess(false));

      const data = await UserClient.update(id, { disabled });

      dispatch(setUpdateUserStatusSuccess(true, data));
    } catch (error) {
      dispatch(setUpdateUserStatusError(error.message));
    } finally {
      dispatch(setUpdateUserStatusLoading(false));
    }
  };

export const cancelSubscription =
  (id: string): AppThunk =>
  async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
    try {
      dispatch(setCancelSubscriptionLoading(true));
      dispatch(setCancelSubscriptionSuccess(false));

      await SubscriptionClient.cancel(id);

      dispatch(setCancelSubscriptionSuccess(true));
    } catch (error) {
      dispatch(setCancelSubscriptionError(error.message));
    } finally {
      dispatch(setCancelSubscriptionLoading(false));
    }
  };

  export const disablePromoCode = 
  (id: string, status: boolean): AppThunk =>
  async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
    try {

      dispatch(setDisablePromoCodeLoading(true));
      dispatch(setDisablePromoCodeSuccess(false));

      const { data } = await SubscriptionClient.disablePromoCode({subscriptionId: id, status})
      dispatch(setDisablePromoCodeSuccess(true, data));
    } catch (error) {
      dispatch(setDisablePromoCodeError(error.message));
    } finally {
      dispatch(setDisablePromoCodeLoading(false))
    }
 }
