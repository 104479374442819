import { PromoCodeType } from 'interfaces';
import { getErrorMessage } from 'shared';
import * as yup from 'yup';

import {
  PromoCodeFormData,
  PromoCodeFormFieldName,
} from '../../screens/PromoCodeListScreen/PromoCodeFormModal/types';

const PromoCodeSchema: yup.SchemaOf<PromoCodeFormData> = yup.object().shape({
  [PromoCodeFormFieldName.expirationDate]: yup
    .string()
    .required(getErrorMessage('expirationDate', 'promo_code_expiration_date_required'))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .when([PromoCodeFormFieldName.startDate], (startDate: string, schema: any) => {
      return schema.test({
        test: (endDate: string) => !!startDate && new Date(endDate) > new Date(startDate),
        message: getErrorMessage('startDate', 'promo_code_expiration_date_greater_than_start'),
      });
    }),
  [PromoCodeFormFieldName.startDate]: yup
    .string()
    .required(getErrorMessage('startDate', 'promo_code_start_date_required')),
  [PromoCodeFormFieldName.email]: yup
    .string()
    .email(getErrorMessage('email', 'email_invalid'))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .when([PromoCodeFormFieldName.type], (type: string, schema: any) => {
      return schema.test({
        test: (mail: string) => {
          return mail || type !== PromoCodeType.OneTime;
        },
        message: getErrorMessage('email', 'email_required'),
      });
    }),
  [PromoCodeFormFieldName.description]: yup.string(),
  [PromoCodeFormFieldName.isActive]: yup
    .boolean()
    .required(getErrorMessage('isActive', 'promo_code_status_date_required')),
  [PromoCodeFormFieldName.percentage]: yup
    .number()
    .min(0, getErrorMessage('percentage', 'promo_code_percentage_min'))
    .max(100, getErrorMessage('percentage', 'promo_code_percentage_max'))
    .required(getErrorMessage('percentage', 'promo_code_percentage_required')),
  [PromoCodeFormFieldName.type]: yup
    .mixed<PromoCodeType>()
    .required(getErrorMessage('type', 'promo_code_type_required')),
  [PromoCodeFormFieldName.code]: yup
    .string()
    .required(getErrorMessage('code', 'promo_code_code_required'))
    .min(5, getErrorMessage('code', 'promo_code_code_length'))
    .max(5, getErrorMessage('code', 'promo_code_code_length')),
});

export default PromoCodeSchema;
