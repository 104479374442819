import React, { useEffect, useRef, useState, useCallback } from 'react';

import { format } from 'date-fns';
import { Stream, TextValuePair, TrainerSortField } from 'interfaces';
import { Trainer } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  useListModal,
  getFormattedDate,
  TIME_FORMAT,
  useScrollPagination,
  getFullName,
  DATE_FORMAT,
} from 'shared';

import { Button, Image } from 'web-components';

import {
  AreYouSureModal,
  ListItem,
  ListItemActions,
  SearchBar,
  Loading,
  TopScrollButton,
  Error,
} from '@components';

import useToastNotification from '../../hooks/useToastNotification';

import {
  createStream,
  deleteStream,
  getStreams,
  incStreamsCurrentPage,
  resetStreamsCurrentPage,
  setCreateStreamError,
  setStreamsGetParams,
  setUpdateStreamError,
  setGetStreamsError,
  updateStream,
} from '../../store/stream/actions';
import { getAllTrainers } from '../../store/trainer/actions';
import { AppState } from '../../store/types';

import StreamDetailsModal from './StreamDetailsModal';
import StreamFormModal from './StreamFormModal';
import { StreamFormData } from './StreamFormModal/types';
import styles from './StreamListScreen.module.scss';

const StreamListScreen: React.FC = () => {
  const { t, i18n } = useTranslation();

  const {
    streamList,
    getParams,
    isStreamListLastPageReached,
    isUpdateStreamSuccess,
    isCreateStreamSuccess,
    isDeleteStreamSuccess,
    isGetStreamsLoading,
    isDeleteStreamLoading,
    getStreamsError,
    createStreamError,
    updateStreamError,
    deleteStreamError,
  } = useSelector((state: AppState) => state.stream);
  const { allTrainers } = useSelector((state: AppState) => state.trainer);
  const isTrainersLoading = useSelector((state: AppState) => state.trainer).isGetAllTrainersLoading;

  const dispatch = useDispatch();

  const { loadingIndicator } = useScrollPagination(streamList.length > 0, () =>
    dispatch(incStreamsCurrentPage()),
  );

  const { errorNotification, removeAllNotifications } = useToastNotification();
  const [enableToastMessage, setEnableToastMessage] = useState<boolean>(false);
  const [validationErrorMessage, setValidationErrorMessage] =
    useState<string | undefined>(undefined);

  const needToFetchData = useRef(!streamList.length);

  const showErrorMessage = useCallback((message: string) => {
    errorNotification(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setGetStreamsError(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getStreamsError && enableToastMessage) {
      showErrorMessage(getStreamsError);
    }
    if (createStreamError && enableToastMessage) {
      showErrorMessage(createStreamError);
    }
    if (updateStreamError && enableToastMessage) {
      showErrorMessage(updateStreamError);
    }
    if (deleteStreamError && enableToastMessage) {
      showErrorMessage(deleteStreamError);
    }
  }, [
    getStreamsError,
    createStreamError,
    updateStreamError,
    deleteStreamError,
    enableToastMessage,
    showErrorMessage,
  ]);

  useEffect(() => {
    if (isCreateStreamSuccess) {
      onFormModalClose();

      dispatch(resetStreamsCurrentPage());
      dispatch(setStreamsGetParams({ ...getParams, page: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateStreamSuccess, dispatch]);

  useEffect(() => {
    if (isUpdateStreamSuccess) {
      onFormModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateStreamSuccess, dispatch]);

  useEffect(() => {
    if (isDeleteStreamSuccess) {
      dispatch(getStreams(getParams));

      onAreYouSureModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteStreamSuccess, dispatch]);

  useEffect(() => {
    //prevent unnecessary reload after return to the current screen with loaded data
    if (needToFetchData.current && !isStreamListLastPageReached) {
      dispatch(getStreams(getParams, (getParams.page ?? 1) > 1));
    }

    if (!needToFetchData.current) {
      needToFetchData.current = true;
    }
  }, [getParams, dispatch, isStreamListLastPageReached]);

  useEffect(() => {
    removeAllNotifications();
  // (removeAllNotifications is excluded bc it doesn't work with that)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateStreamSuccess, isUpdateStreamSuccess, isDeleteStreamSuccess]);

  const onSaveButtonClicked = async (data: StreamFormData, id?: string) => {
    setEnableToastMessage(true);
    if (validationErrorMessage) {
      showErrorMessage(validationErrorMessage);
    } else if (data) {
      if (!id) {
        await dispatch(createStream(data));
      } else {
        await dispatch(updateStream(id, data));
      }

      dispatch(getStreams(getParams));
    }
  };

  const onNewButtonClicked = () => {
    dispatch(getAllTrainers({ sort: TrainerSortField.LastName }));
    onNewItemClick();
  };

  const onEditButtonClicked = (item: Stream) => {
    dispatch(getAllTrainers({ sort: TrainerSortField.LastName }));
    onEditItemClick(item);
  };

  const onDelete = (item: Stream) => {
    setEnableToastMessage(true);
    dispatch(deleteStream(item._id));
  };

  const getTrainerListOptions = (): TextValuePair[] => {
    return Array.isArray(allTrainers)
      ? allTrainers.map((trainer) => ({
          text: getFullName(trainer.firstName, trainer.lastName, i18n.language),
          value: trainer._id,
        }))
      : [];
  };

  const onStartSearch = (searchTerm: string): void => {
    dispatch(resetStreamsCurrentPage());
    dispatch(setStreamsGetParams({ ...getParams, page: undefined, search: searchTerm }));
  };

  const onCancelButtonClicked = () => {
    dispatch(setUpdateStreamError(undefined));
    dispatch(setCreateStreamError(undefined));

    onFormModalClose();
  };

  const {
    showDetailsModal,
    showFormModal,
    showAreYouSureModal,
    modalData,
    onItemDetailsClick,
    onNewItemClick,
    onEditItemClick,
    onDeleteItemClick,
    onDetailsModalClose,
    onFormModalClose,
    onAreYouSureModalClose,
  } = useListModal();

  const listItemLeftContent = (item: Stream): React.ReactElement => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-1 p-0 my-auto">
          <Image className={styles.iconSize} src="/icons/user.svg" alt="user" />
        </div>
        <div className="col-11">
          {`${(item.trainer as Trainer).lastName} ${(item.trainer as Trainer).firstName}`}
        </div>
      </div>
      <div className="row">
        <div className="col-1 p-0 my-auto">
          <Image className={styles.iconSize} src="/icons/calendar.svg" alt="calendar" />
        </div>
        <div className="col-11">{format(new Date(item.startTime), DATE_FORMAT)}</div>
      </div>
      <div className="row">
        <div className="col-1 p-0 my-auto">
          <Image className={styles.iconSize} src="/icons/clock.svg" alt="clock" />
        </div>
        <div className="col-11">
          {`${item?.startTime && getFormattedDate(item?.startTime, TIME_FORMAT)} - ${
            item?.endTime && getFormattedDate(item?.endTime, TIME_FORMAT)
          }`}
        </div>
      </div>
    </div>
  );

  const listItemRightContent = (item: Stream): React.ReactElement => (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <ListItemActions
            editLabel={t('stream_list_screen.edit_stream')}
            deleteLabel={t('stream_list_screen.delete_stream')}
            onDetailsClick={(): void => onItemDetailsClick(item)}
            onEditClick={(): void => onEditButtonClicked(item)}
            onDeleteClick={(): void => onDeleteItemClick(item)}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="container p-5">
      <div className="row">
        <div className="col-7">
          <SearchBar
            className={`${styles.input} w-100 font-weight-bold`}
            initialValue={getParams.search}
            onStartSearch={onStartSearch}
          />
        </div>
        <div className="col-3">
          <Button
            onClick={onNewButtonClicked}
            additionalStyles={`bg-secondary text-primary btn-sm font-weight-bold w-100 ${styles.button}`}
          >
            {t('stream_form_modal.new_stream')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-10">
          <div className="container-fluid mt-5 mb-5">
            {getStreamsError && <Error error={getStreamsError} />}

            {streamList.map((item) => (
              <ListItem
                key={item._id}
                title={item.name}
                imageHash={item.pictures?.hash ?? ''}
                imageUrl={item.pictures?.coverThumbnail ?? ''}
                imageClassName={styles.listItemImage}
                className="my-2"
                leftContent={listItemLeftContent(item)}
                rightContent={listItemRightContent(item)}
              />
            ))}

            {isGetStreamsLoading && <Loading />}

            <div ref={loadingIndicator} />
          </div>
        </div>
      </div>
      <TopScrollButton containerId="root_router" />
      {modalData && (
        <StreamDetailsModal
          data={modalData as Stream}
          show={showDetailsModal}
          onCancel={onDetailsModalClose}
        />
      )}
      <StreamFormModal
        data={modalData as Stream}
        trainerOptions={getTrainerListOptions()}
        isTrainersLoading={isTrainersLoading}
        show={showFormModal}
        onSave={onSaveButtonClicked}
        onCancel={onCancelButtonClicked}
        onError={(message) => setValidationErrorMessage(message)}
      />
      <AreYouSureModal
        show={showAreYouSureModal}
        isLoading={isDeleteStreamLoading}
        error={deleteStreamError}
        onNoClick={onAreYouSureModalClose}
        onYesClick={() => onDelete(modalData as Stream)}
        title={t('stream_form_modal.delete_title')}
        yesButtonTitle={t('stream_form_modal.delete_yes_button_title')}
      />
    </div>
  );
};

export default StreamListScreen;
