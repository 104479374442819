import { User, UserSubscriptionData } from 'interfaces';

import { UserRole } from "../../AdminListScreen/AdminFormModal/types";

export interface UserFormProps {
  data?: User;
  show: boolean;
  onSave: (data: UserFormData, id?: string) => void;
  onSubscriptionCancel: (id: string) => void;
  onCancel: () => void;
  onDisablePromoCode: (subscription: UserSubscriptionData) => void;
  onError: (message: string) => void;
}

export enum UserFormFieldName {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  companyName = 'companyName',
  companyVatId = 'companyVatId',
  address = 'address',
  zipCode = 'zipCode',
  city = 'city',
  country = 'country',
  roles = 'roles',
}

export interface UserFormData {
  [UserFormFieldName.email]: string;
  [UserFormFieldName.firstName]: string;
  [UserFormFieldName.lastName]: string;
  [UserFormFieldName.companyName]?: string;
  [UserFormFieldName.companyVatId]?: string;
  [UserFormFieldName.address]: string;
  [UserFormFieldName.zipCode]: string;
  [UserFormFieldName.city]: string;
  [UserFormFieldName.country]: string;
  [UserFormFieldName.roles]: UserRole[];
}
