import React from 'react';

import countries from 'i18n-iso-countries';

import { useTranslation } from 'react-i18next';

import { Modal } from 'web-components';

import { UserDetailsModalProps } from './types';

/**
 * User details modal content.
 * @param data - The user's data.
 * @param show - If 'true', the modal is displayed.
 * @param onCancel - A function what will be called when the cancel button was pressed.
 */
const UserDetailsModal: React.FC<UserDetailsModalProps> = ({ data, onCancel, ...rest }) => {
  const { t } = useTranslation();

  const countriesOptions = Object.entries(countries.getNames('hu', { select: 'official' }));

  const country: [string, string] | null | undefined = data.billingInfo?.country
    ? countriesOptions.find((a) => {
        return a[0] == data.billingInfo?.country;
      })
    : null;

  return (
    <Modal
      {...rest}
      title={t('user_details_modal.title')}
      actionButtons={[
        {
          text: t('common.back'),
          classes: ['bg-secondary', 'text-primary', 'font-weight-bold'],
          onClick: onCancel,
        },
      ]}
    >
      <div className="text-primary">
        <div className="row mt-3 mb-3">
          <div className="col">
            <div>{t('user_details_modal.email')}</div>
            <div className="font-weight-bold">{data.email}</div>
          </div>
          <div className="col">
            <div>{t('user_details_modal.roles')}</div>
            <div className="font-weight-bold">{data?.roles?.join(', ')}</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div>{t('user_details_modal.lastname')}</div>
            <div className="font-weight-bold">{data.lastName}</div>
          </div>
          <div className="col">
            <div>{t('user_details_modal.firstname')}</div>
            <div className="font-weight-bold">{data.firstName}</div>
          </div>
        </div>

        <div className="mt-3 mb-3 text-uppercase">{t('user_details_modal.billing_info')}</div>
        <div className="row">
          <div className="col">
            <div>{t('user_details_modal.company_name')}</div>
            <div className="font-weight-bold">{data.billingInfo?.companyName}</div>
          </div>
          <div className="col">
            <div>{t('user_details_modal.vat_id')}</div>
            <div className="font-weight-bold">{data.billingInfo?.companyVatId}</div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <div>{t('user_details_modal.address')}</div>
            <div className="font-weight-bold">{data.billingInfo?.address}</div>
          </div>
          <div className="col">
            <div>{t('user_details_modal.zip_code')}</div>
            <div className="font-weight-bold">{data.billingInfo?.zipCode}</div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <div>{t('user_details_modal.city')}</div>
            <div className="font-weight-bold">{data.billingInfo?.city}</div>
          </div>
          <div className="col">
            <div>{t('user_details_modal.country')}</div>
            <div className="font-weight-bold">{country && country[1]}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
