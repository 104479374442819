import { GetUsersParams } from 'api_client';
import { User } from 'interfaces';

export const SET_USERS = 'SET_USERS';
export const SET_USERS_GET_PARAMS = 'SET_USERS_GET_PARAMS';
export const INC_USERS_CURRENT_PAGE = 'INC_USERS_CURRENT_PAGE';
export const RESET_USERS_CURRENT_PAGE = 'RESET_USERS_CURRENT_PAGE';
export const APPEND_USERS = 'APPEND_USERS';
export const SET_USERS_LAST_PAGE_REACHED = 'SET_USERS_LAST_PAGE_REACHED';

export const SET_CREATE_USER_SUCCESS = 'SET_CREATE_USER_SUCCESS';
export const SET_UPDATE_USER_SUCCESS = 'SET_UPDATE_USER_SUCCESS';
export const SET_UPDATE_USER_STATUS_SUCCESS = 'SET_UPDATE_USER_STATUS_SUCCESS';
export const SET_CANCEL_SUBSCRIPTION_SUCCESS = 'SET_CANCEL_SUBSCRIPTION_SUCCESS';
export const SET_DISABLE_PROMOCODE_SUCCESS = 'SET_DISABLE_PROMOCODE_SUCCESS';

export const SET_GET_USERS_ERROR = 'SET_GET_USERS_ERROR';
export const SET_CREATE_USER_ERROR = 'SET_CREATE_USER_ERROR';
export const SET_UPDATE_USER_ERROR = 'SET_UPDATE_USER_ERROR';
export const SET_UPDATE_USER_STATUS_ERROR = 'SET_UPDATE_USER_STATUS_ERROR';
export const SET_CANCEL_SUBSCRIPTION_ERROR = 'SET_CANCEL_SUBSCRIPTION_ERROR';
export const SET_DISABLE_PROMOCODE_ERROR = 'SET_DISABLE_PROMOCODE_ERROR';

export const SET_GET_USERS_LOADING = 'SET_GET_USERS_LOADING';
export const SET_CREATE_USER_LOADING = 'SET_CREATE_USER_LOADING';
export const SET_UPDATE_USER_LOADING = 'SET_UPDATE_USER_LOADING';
export const SET_UPDATE_USER_STATUS_LOADING = 'SET_UPDATE_USER_STATUS_LOADING';
export const SET_CANCEL_SUBSCRIPTION_LOADING = 'SET_CANCEL_SUBSCRIPTION_LOADING';
export const SET_DISABLE_PROMOCODE_LOADING = 'SET_DISABLE_PROMOCODE_LOADING';

export interface SetUsers {
  type: typeof SET_USERS;
  payload: User[];
}

export interface SetUsersGetParams {
  type: typeof SET_USERS_GET_PARAMS;
  payload: GetUsersParams;
}

export interface IncUsersCurrentPage {
  type: typeof INC_USERS_CURRENT_PAGE;
}

export interface ResetUsersCurrentPage {
  type: typeof RESET_USERS_CURRENT_PAGE;
}

export interface AppendUsers {
  type: typeof APPEND_USERS;
  payload: User[];
}

export interface SetUsersLastPageReached {
  type: typeof SET_USERS_LAST_PAGE_REACHED;
  payload: boolean;
}

export interface SetCreateUserSuccess {
  type: typeof SET_CREATE_USER_SUCCESS;
  payload: boolean;
}

export interface SetUpdateUserSuccess {
  type: typeof SET_UPDATE_USER_SUCCESS;
  payload: {
    success: boolean;
    data?: User;
  };
}

export interface SetUpdateUserStatusSuccess {
  type: typeof SET_UPDATE_USER_STATUS_SUCCESS;
  payload: {
    success: boolean;
    data?: User;
  };
}

export interface SetCancelSubscriptionSuccess {
  type: typeof SET_CANCEL_SUBSCRIPTION_SUCCESS;
  payload: boolean;
}

export interface SetDisablePromoCodeSuccess {
  type: typeof SET_DISABLE_PROMOCODE_SUCCESS;
  payload: {
    isSuccess: boolean;
    data: User
  };
}

export interface SetGetUsersError {
  type: typeof SET_GET_USERS_ERROR;
  payload?: string;
}

export interface SetCreateUserError {
  type: typeof SET_CREATE_USER_ERROR;
  payload?: string;
}

export interface SetUpdateUserError {
  type: typeof SET_UPDATE_USER_ERROR;
  payload?: string;
}

export interface SetUpdateUserStatusError {
  type: typeof SET_UPDATE_USER_STATUS_ERROR;
  payload?: string;
}

export interface SetCancelSubscriptionError {
  type: typeof SET_CANCEL_SUBSCRIPTION_ERROR;
  payload?: string;
}

export interface SetDisablePromoCodeError {
  type: typeof SET_DISABLE_PROMOCODE_ERROR;
  payload?: string;
}

export interface SetGetUsersLoading {
  type: typeof SET_GET_USERS_LOADING;
  payload: boolean;
}

export interface SetCreateUserLoading {
  type: typeof SET_CREATE_USER_LOADING;
  payload: boolean;
}

export interface SetUpdateUserLoading {
  type: typeof SET_UPDATE_USER_LOADING;
  payload: boolean;
}

export interface SetUpdateUserStatusLoading {
  type: typeof SET_UPDATE_USER_STATUS_LOADING;
  payload: boolean;
}

export interface SetCancelSubscriptionLoading {
  type: typeof SET_CANCEL_SUBSCRIPTION_LOADING;
  payload: boolean;
}

export interface SetDisablePromoCodeLoading {
  type: typeof SET_DISABLE_PROMOCODE_LOADING;
  payload: boolean
}

export type UserActionTypes =
  | SetUsers
  | SetUsersGetParams
  | IncUsersCurrentPage
  | ResetUsersCurrentPage
  | AppendUsers
  | SetUsersLastPageReached
  | SetCreateUserSuccess
  | SetUpdateUserSuccess
  | SetUpdateUserStatusSuccess
  | SetCancelSubscriptionSuccess
  | SetDisablePromoCodeSuccess
  | SetGetUsersError
  | SetCreateUserError
  | SetUpdateUserError
  | SetCancelSubscriptionError
  | SetUpdateUserStatusError
  | SetDisablePromoCodeError
  | SetGetUsersLoading
  | SetCreateUserLoading
  | SetUpdateUserLoading
  | SetCancelSubscriptionLoading
  | SetUpdateUserStatusLoading
  | SetDisablePromoCodeLoading;
