import { PromoCode, PromoCodeType } from 'interfaces';

export interface PromoCodeFormModalProps {
  data?: PromoCode;
  show: boolean;
  onSave: (data: PromoCodeFormData, id?: string) => void;
  onCancel: () => void;
  onError: (message: string) => void;
}

export enum PromoCodeFormFieldName {
  code = 'code',
  type = 'type',
  percentage = 'percentage',
  startDate = 'startDate',
  expirationDate = 'expirationDate',
  email = 'email',
  description = 'description',
  isActive = 'isActive',
}

export interface PromoCodeFormData {
  [PromoCodeFormFieldName.code]?: string;
  [PromoCodeFormFieldName.type]: PromoCodeType;
  [PromoCodeFormFieldName.percentage]: number;
  [PromoCodeFormFieldName.startDate]: string;
  [PromoCodeFormFieldName.expirationDate]: string;
  [PromoCodeFormFieldName.email]?: string;
  [PromoCodeFormFieldName.description]?: string;
  [PromoCodeFormFieldName.isActive]: boolean;
}
