import React, { useEffect, useRef, useState, useCallback } from 'react';

import { PromoCode, PromoCodeType } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useListModal, useScrollPagination } from 'shared';

import { Button } from 'web-components';

import {
  AreYouSureModal,
  Error,
  ListItem,
  ListItemActions,
  Loading,
  RadioButton,
  Switch,
  TopScrollButton,
} from '@components';

import useToastNotification from '../../hooks/useToastNotification';

import {
  createPromoCode,
  deletePromoCode,
  generateCode,
  getPromoCodes,
  incPromoCodesCurrentPage,
  resetPromoCodesCurrentPage,
  setCreatePromoCodeError,
  setGetPromoCodesError,
  setPromoCodesGetParams,
  setUpdatePromoCodeError,
  updatePromoCode,
  updatePromoCodeStatus,
} from '../../store/promo-code/actions';
import { AppState } from '../../store/types';

import PromoCodeFormModal from './PromoCodeFormModal';
import { PromoCodeFormData } from './PromoCodeFormModal/types';
import styles from './PromoCodeListScreen.module.scss';

const PromoCodeListScreen: React.FC = () => {
  const { t } = useTranslation();
  const {
    promoCodeList,
    getParams,
    isPromoCodeListLastPageReached,
    isUpdatePromoCodeSuccess,
    isCreatePromoCodeSuccess,
    isDeletePromoCodeSuccess,
    isGetPromoCodesLoading,
    isUpdatePromoCodeStatusLoading,
    getPromoCodesError,
    createPromoCodeError,
    updatePromoCodeStatusError,
    updatePromoCodeError,
    deletePromoCodeError,
    generateCodeError,
  } = useSelector((state: AppState) => state.promoCode);
  const [activePromoCodeType, setActivePromoCodeType] = React.useState<PromoCodeType>(
    PromoCodeType.OneTime,
  );

  const { loadingIndicator } = useScrollPagination(promoCodeList.length > 0, () =>
    dispatch(incPromoCodesCurrentPage()),
  );

  const dispatch = useDispatch();

  const { errorNotification, removeAllNotifications } = useToastNotification();
  const [enableToastMessage, setEnableToastMessage] = useState<boolean>(false);
  const [validationErrorMessage, setValidationErrorMessage] =
    useState<string | undefined>(undefined);

  const needToFetchData = useRef(!promoCodeList.length);

  const showErrorMessage = useCallback((message: string) => {
    errorNotification(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setGetPromoCodesError(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getPromoCodesError && enableToastMessage) {
      showErrorMessage(getPromoCodesError);
    }
    if (createPromoCodeError && enableToastMessage) {
      showErrorMessage(createPromoCodeError);
    }
    if (updatePromoCodeStatusError && enableToastMessage) {
      showErrorMessage(updatePromoCodeStatusError);
    }
    if (updatePromoCodeError && enableToastMessage) {
      showErrorMessage(updatePromoCodeError);
    }
    if (deletePromoCodeError && enableToastMessage) {
      showErrorMessage(deletePromoCodeError);
    }
    if (generateCodeError && enableToastMessage) {
      showErrorMessage(generateCodeError);
    }
  }, [
    getPromoCodesError,
    createPromoCodeError,
    updatePromoCodeStatusError,
    updatePromoCodeError,
    deletePromoCodeError,
    generateCodeError,
    enableToastMessage,
    showErrorMessage,
  ]);

  useEffect(() => {
    if (isCreatePromoCodeSuccess) {
      onFormModalClose();

      dispatch(resetPromoCodesCurrentPage());
      dispatch(setPromoCodesGetParams({ ...getParams, page: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatePromoCodeSuccess, dispatch]);

  useEffect(() => {
    if (isUpdatePromoCodeSuccess) {
      onFormModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatePromoCodeSuccess, dispatch]);

  useEffect(() => {
    if (isDeletePromoCodeSuccess) {
      dispatch(getPromoCodes(getParams));
      onAreYouSureModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeletePromoCodeSuccess, dispatch]);

  useEffect(() => {
    //prevent unnecessary reload after return to the current screen with loaded data
    if (needToFetchData.current && !isPromoCodeListLastPageReached) {
      dispatch(getPromoCodes(getParams, (getParams.page ?? 1) > 1));
    }

    if (!needToFetchData.current) {
      needToFetchData.current = true;
    }
  }, [getParams, dispatch, isPromoCodeListLastPageReached]);

  useEffect(() => {
    dispatch(resetPromoCodesCurrentPage());
    dispatch(
      setPromoCodesGetParams({
        ...getParams,
        page: undefined,
        filter: { type: activePromoCodeType },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePromoCodeType, dispatch]);

  useEffect(() => {
    removeAllNotifications();
  // (removeAllNotifications is excluded bc it doesn't work with that)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCreatePromoCodeSuccess,
    isUpdatePromoCodeSuccess,
    isDeletePromoCodeSuccess,
  ]);

  const onSaveButtonClicked = (data: PromoCodeFormData, id?: string) => {
    setEnableToastMessage(true);
    if (validationErrorMessage) {
      showErrorMessage(validationErrorMessage);
    } else if (data) {
      if (!id) {
        dispatch(createPromoCode(data));
      } else {
        dispatch(updatePromoCode(id, data));
      }
    }
  };

  const onStatusToggle = (item: PromoCode) => {
    setEnableToastMessage(true);
    dispatch(updatePromoCodeStatus(item._id, !item.isActive));
  };

  const onDelete = (item: PromoCode) => {
    setEnableToastMessage(true);
    dispatch(deletePromoCode(item._id));
  };

  const onNewButtonClicked = () => {
    setEnableToastMessage(true);
    dispatch(generateCode());

    onNewItemClick();
  };

  const onCancelButtonClicked = () => {
    dispatch(setUpdatePromoCodeError(undefined));
    dispatch(setCreatePromoCodeError(undefined));

    onFormModalClose();
  };

  const {
    showFormModal,
    showAreYouSureModal,
    modalData,
    onNewItemClick,
    onEditItemClick,
    onDeleteItemClick,
    onFormModalClose,
    onAreYouSureModalClose,
  } = useListModal();

  const listItemLeftContent = (item: PromoCode): React.ReactElement => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 pl-0">
          <div>{`${item.percentage} %`}</div>
        </div>
        <div className="col-10 pl-0">
          <div>
            {item.type === PromoCodeType.OneTime
              ? t('promo_code_list_screen.single_use')
              : t('promo_code_list_screen.period_based')}
          </div>
        </div>
      </div>
      <div className="row">
        <Switch
          name={`status${item._id}`}
          disabled={isUpdatePromoCodeStatusLoading}
          label={item.isActive ? t('common.active_status') : t('common.inactive_status')}
          checked={item.isActive}
          onChange={() => onStatusToggle(item)}
          className="col"
        />
      </div>
    </div>
  );

  const listItemRightContent = (item: PromoCode) => (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <ListItemActions
            editLabel={t('promo_code_list_screen.edit_promo_code')}
            deleteLabel={t('promo_code_list_screen.delete_promo_code')}
            onEditClick={(): void => onEditItemClick(item)}
            onDeleteClick={(): void => onDeleteItemClick(item)}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="container p-5">
      <div className="row">
        <div className="col-7">
          <RadioButton
            label={t('promo_code_list_screen.single_use')}
            value={PromoCodeType.OneTime}
            name="ActivePromoCodeType"
            checked={activePromoCodeType === PromoCodeType.OneTime}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setActivePromoCodeType(e.target.value as PromoCodeType)
            }
            className="text-light"
          />
          <RadioButton
            label={t('promo_code_list_screen.period_based')}
            value={PromoCodeType.Period}
            name="ActivePromoCodeType"
            checked={activePromoCodeType === PromoCodeType.Period}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setActivePromoCodeType(e.target.value as PromoCodeType)
            }
            className="text-light"
          />
        </div>
        <div className="col-3">
          <Button
            onClick={onNewButtonClicked}
            additionalStyles={`bg-secondary text-primary btn-sm font-weight-bold w-100 ${styles.button}`}
          >
            {t('promo_code_list_screen.add_new')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-10">
          <div className="container-fluid mt-5 mb-5">
            {getPromoCodesError && <Error error={getPromoCodesError} />}

            {promoCodeList.map((item) => (
              <ListItem
                key={item._id}
                title={item.code ? item.code : ''}
                leftContent={listItemLeftContent(item)}
                rightContent={listItemRightContent(item)}
                rightContentClassName="col-6"
              />
            ))}

            {isGetPromoCodesLoading && <Loading />}

            <div ref={loadingIndicator} />
          </div>
        </div>
      </div>
      <TopScrollButton containerId="root_router" />
      <PromoCodeFormModal
        data={modalData as PromoCode}
        show={showFormModal}
        onSave={onSaveButtonClicked}
        onCancel={onCancelButtonClicked}
        onError={(message) => setValidationErrorMessage(message)}
      />
      <AreYouSureModal
        show={showAreYouSureModal}
        isLoading={isUpdatePromoCodeStatusLoading}
        error={updatePromoCodeStatusError}
        onNoClick={onAreYouSureModalClose}
        onYesClick={() => onDelete(modalData as PromoCode)}
        title={t('promo_code_form_modal.delete_title')}
        yesButtonTitle={t('promo_code_form_modal.delete_yes_button_title')}
      />
    </div>
  );
};

export default PromoCodeListScreen;
