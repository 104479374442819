import { NUMBER_REGEX, YEAR_REGEX, getErrorMessage } from 'shared';
import * as yup from 'yup';

import {
  TrainerFormData,
  TrainerFormFieldName,
} from '../../screens/TrainerListScreen/TrainerFormModal/types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const TrainerSchema: yup.SchemaOf<TrainerFormData> = yup.object().shape({
  [TrainerFormFieldName.picture]: yup.object().nullable(),
  [TrainerFormFieldName.pictureUrl]: yup.string(),
  [TrainerFormFieldName.description]: yup.string(),
  [TrainerFormFieldName.qualifications]: yup
    .string()
    .required(getErrorMessage('qualifications', 'trainer_qualifications_required')),
  [TrainerFormFieldName.hobbies]: yup.string(),
  [TrainerFormFieldName.favoriteMusic]: yup.string(),
  [TrainerFormFieldName.profession]: yup
    .string()
    .required(getErrorMessage('profession', 'trainer_profession_required')),
  [TrainerFormFieldName.carrierStartYear]: yup
    .string()
    .matches(YEAR_REGEX, {
      message: getErrorMessage('carrierStartYear', 'trainer_carrier_start_format'),
    })
    .required(getErrorMessage('carrierStartYear', 'trainer_carrier_start_required')),
  [TrainerFormFieldName.rideHours]: yup
    .string()
    .matches(NUMBER_REGEX, { message: getErrorMessage('rideHours', 'trainer_ride_hours_format') })
    .required(getErrorMessage('rideHours', 'trainer_ride_hours_required')),
  [TrainerFormFieldName.firstName]: yup
    .string()
    .required(getErrorMessage('firstName', 'first_name_required')),
  [TrainerFormFieldName.lastName]: yup
    .string()
    .required(getErrorMessage('lastName', 'last_name_required')),
});

export default TrainerSchema;
