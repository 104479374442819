import { ReactElement } from 'react';

export enum ROUTE {
  AdminList = '/admin-list',
  Archive = '/archive',
  Login = '/',
  PromoCodeList = '/promo-code-list',
  StreamList = '/stream-list',
  TrainerList = '/trainer-list',
  UserList = '/user-list',
  ForgottenPassword = '/forgotten-password',
  ResetPassword = '/reset-password',
}

export interface PrivateRouteProps {
  component: ReactElement;
}
