import { GetTrainersParams } from 'api_client';
import { Trainer } from 'interfaces';

export const SET_TRAINERS = 'SET_TRAINERS';
export const SET_ALL_TRAINERS = 'SET_ALL_TRAINERS';
export const APPEND_TRAINERS = 'APPEND_TRAINERS';

export const SET_TRAINERS_GET_PARAMS = 'SET_TRAINERS_GET_PARAMS';
export const INC_TRAINERS_CURRENT_PAGE = 'INC_TRAINERS_CURRENT_PAGE';
export const RESET_TRAINERS_CURRENT_PAGE = 'RESET_TRAINERS_CURRENT_PAGE';
export const SET_TRAINERS_LAST_PAGE_REACHED = 'SET_TRAINERS_LAST_PAGE_REACHED';

export const SET_CREATE_TRAINER_SUCCESS = 'SET_CREATE_TRAINER_SUCCESS';
export const SET_UPDATE_TRAINER_SUCCESS = 'SET_UPDATE_TRAINER_SUCCESS';
export const SET_DELETE_TRAINER_SUCCESS = 'SET_DELETE_TRAINER_SUCCESS';

export const SET_GET_TRAINERS_ERROR = 'SET_GET_TRAINERS_ERROR';
export const SET_GET_ALL_TRAINERS_ERROR = 'SET_GET_ALL_TRAINERS_ERROR';
export const SET_CREATE_TRAINER_ERROR = 'SET_CREATE_TRAINER_ERROR';
export const SET_UPDATE_TRAINER_ERROR = 'SET_UPDATE_TRAINER_ERROR';
export const SET_DELETE_TRAINER_ERROR = 'SET_DELETE_TRAINER_ERROR';

export const SET_GET_TRAINERS_LOADING = 'SET_GET_TRAINERS_LOADING';
export const SET_GET_ALL_TRAINERS_LOADING = 'SET_GET_ALL_TRAINERS_LOADING';
export const SET_CREATE_TRAINER_LOADING = 'SET_CREATE_TRAINER_LOADING';
export const SET_UPDATE_TRAINER_LOADING = 'SET_UPDATE_TRAINER_LOADING';
export const SET_DELETE_TRAINER_LOADING = 'SET_DELETE_TRAINER_LOADING';

export interface SetTrainers {
  type: typeof SET_TRAINERS;
  payload: Trainer[];
}

export interface SetAllTrainers {
  type: typeof SET_ALL_TRAINERS;
  payload: Trainer[];
}

export interface AppendAdmins {
  type: typeof APPEND_TRAINERS;
  payload: Trainer[];
}

export interface SetTrainersGetParams {
  type: typeof SET_TRAINERS_GET_PARAMS;
  payload: GetTrainersParams;
}

export interface IncTrainersCurrentPage {
  type: typeof INC_TRAINERS_CURRENT_PAGE;
}

export interface ResetTrainersCurrentPage {
  type: typeof RESET_TRAINERS_CURRENT_PAGE;
}

export interface SetAdminsLastPageReached {
  type: typeof SET_TRAINERS_LAST_PAGE_REACHED;
  payload: boolean;
}

export interface SetCreateTrainerSuccess {
  type: typeof SET_CREATE_TRAINER_SUCCESS;
  payload: boolean;
}

export interface SetUpdateTrainerSuccess {
  type: typeof SET_UPDATE_TRAINER_SUCCESS;
  payload: {
    success: boolean;
    data?: Trainer;
  };
}

export interface SetDeleteTrainerSuccess {
  type: typeof SET_DELETE_TRAINER_SUCCESS;
  payload: boolean;
}

export interface SetGetTrainersError {
  type: typeof SET_GET_TRAINERS_ERROR;
  payload?: string;
}

export interface SetGetAllTrainersError {
  type: typeof SET_GET_ALL_TRAINERS_ERROR;
  payload?: string;
}

export interface SetCreateTrainerError {
  type: typeof SET_CREATE_TRAINER_ERROR;
  payload?: string;
}

export interface SetUpdateTrainerError {
  type: typeof SET_UPDATE_TRAINER_ERROR;
  payload?: string;
}

export interface SetDeleteTrainerError {
  type: typeof SET_DELETE_TRAINER_ERROR;
  payload?: string;
}

export interface SetGetTrainersLoading {
  type: typeof SET_GET_TRAINERS_LOADING;
  payload: boolean;
}

export interface SetGetAllTrainersLoading {
  type: typeof SET_GET_ALL_TRAINERS_LOADING;
  payload: boolean;
}

export interface SetCreateTrainerLoading {
  type: typeof SET_CREATE_TRAINER_LOADING;
  payload: boolean;
}

export interface SetUpdateTrainerLoading {
  type: typeof SET_UPDATE_TRAINER_LOADING;
  payload: boolean;
}

export interface SetDeleteTrainerLoading {
  type: typeof SET_DELETE_TRAINER_LOADING;
  payload: boolean;
}

export type TrainerActionTypes =
  | SetTrainers
  | SetTrainersGetParams
  | IncTrainersCurrentPage
  | ResetTrainersCurrentPage
  | SetAllTrainers
  | AppendAdmins
  | SetAdminsLastPageReached
  | SetCreateTrainerSuccess
  | SetUpdateTrainerSuccess
  | SetDeleteTrainerSuccess
  | SetGetTrainersError
  | SetGetAllTrainersError
  | SetCreateTrainerError
  | SetUpdateTrainerError
  | SetDeleteTrainerError
  | SetGetTrainersLoading
  | SetGetAllTrainersLoading
  | SetCreateTrainerLoading
  | SetUpdateTrainerLoading
  | SetDeleteTrainerLoading;
