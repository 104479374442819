import { GetVideosParams, VideoClient,isLastPageReached } from 'api_client';

import { Video } from 'interfaces';

import { ThunkDispatch } from 'redux-thunk';

import { Action, Dispatch } from "redux";


import { ArchiveFormData } from '../../screens/ArchiveListScreen/ArchiveFormModal/types';

import { TAppState } from "../index";

import { AppThunk } from '../types';

import {
  APPEND_VIDEOS,
  SET_GET_VIDEOS_ERROR,
  SET_GET_VIDEOS_LOADING,
  SET_UPDATE_VIDEO_ERROR,
  SET_UPDATE_VIDEO_LOADING,
  SET_UPDATE_VIDEO_STATUS_ERROR,
  SET_UPDATE_VIDEO_STATUS_LOADING,
  SET_UPDATE_VIDEO_STATUS_SUCCESS,
  SET_UPDATE_VIDEO_SUCCESS,
  SET_VIDEOS,
  SET_VIDEOS_LAST_PAGE_REACHED,
  INC_VIDEOS_CURRENT_PAGE,
  RESET_VIDEOS_CURRENT_PAGE,
  SET_VIDEOS_GET_PARAMS,
} from './actionTypes';

export const setVideos = (videos: Video[]): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_VIDEOS,
    payload: videos,
  });
};

export const setVideosGetParams = (params: GetVideosParams): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_VIDEOS_GET_PARAMS,
    payload: params,
  });
};

export const appendVideos = (users: Video[]): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: APPEND_VIDEOS,
    payload: users,
  });
};

export const setVideosLastPageReached = (reached: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_VIDEOS_LAST_PAGE_REACHED,
    payload: reached,
  });
};

export const setUpdateVideoSuccess = (isSuccess: boolean, data?: Video): AppThunk => (
  dispatch: Dispatch,
): void => {
  dispatch({
    type: SET_UPDATE_VIDEO_SUCCESS,
    payload: {
      success: isSuccess,
      data: data,
    },
  });
};

export const setUpdateVideoStatusSuccess = (isSuccess: boolean, data?: Video): AppThunk => (
  dispatch: Dispatch,
): void => {
  dispatch({
    type: SET_UPDATE_VIDEO_STATUS_SUCCESS,
    payload: {
      success: isSuccess,
      data: data,
    },
  });
};

export const setGetVideosError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GET_VIDEOS_ERROR,
    payload: error,
  });
};

export const setUpdateVideoError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_UPDATE_VIDEO_ERROR,
    payload: error,
  });
};

export const setUpdateVideoStatusError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_UPDATE_VIDEO_STATUS_ERROR,
    payload: error,
  });
};

export const setGetVideosLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GET_VIDEOS_LOADING,
    payload: isLoading,
  });
};

export const setUpdateVideoLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_UPDATE_VIDEO_LOADING,
    payload: isLoading,
  });
};

export const setUpdateVideoStatusLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_UPDATE_VIDEO_STATUS_LOADING,
    payload: isLoading,
  });
};

export const incVideosCurrentPage = (): AppThunk => (dispatch: Dispatch, getState): void => {
  if (!getState().archive.isVideoListLastPageReached) {
    dispatch({
      type: INC_VIDEOS_CURRENT_PAGE,
    });
  }
};

export const resetVideosCurrentPage = (): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: RESET_VIDEOS_CURRENT_PAGE,
  });
};

export const getVideos = (params?: GetVideosParams, lazyLoad = false): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setGetVideosLoading(true));

    const videos = await VideoClient.getAll(params);

    if (lazyLoad) {
      dispatch(appendVideos(videos));
    } else {
      dispatch(setVideos(videos));
    }

    dispatch(setVideosLastPageReached(isLastPageReached(videos, params)));
  } catch (error) {
    dispatch(setGetVideosError(error.message));
  } finally {
    dispatch(setGetVideosLoading(false));
  }
};

export const updateVideo = (id: string, videoData: ArchiveFormData): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setUpdateVideoLoading(true));
    dispatch(setUpdateVideoSuccess(false));

    const requestData = {
      name: videoData.name,
      picture: videoData.picture,
      isPublished: videoData.isPublished,
    };

    const data = await VideoClient.update(id, requestData);
    if (data.pictures) data.pictures.hash = Date.now().toString();
    dispatch(setUpdateVideoSuccess(true, data));
  } catch (error) {
    dispatch(setUpdateVideoError(error.message));
  } finally {
    dispatch(setUpdateVideoLoading(false));
  }
};

export const updateVideoStatus = (id: string, isPublished: boolean): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setUpdateVideoStatusLoading(true));
    dispatch(setUpdateVideoStatusSuccess(false));

    const data = await VideoClient.update(id, { isPublished });

    dispatch(setUpdateVideoStatusSuccess(true, data));
  } catch (error) {
    dispatch(setUpdateVideoStatusError(error.message));
  } finally {
    dispatch(setUpdateVideoStatusLoading(false));
  }
};
