import { getErrorMessage } from 'shared';
import * as yup from 'yup';

import {
  ArchiveFormData,
  ArchiveFormFieldName,
} from '../../screens/ArchiveListScreen/ArchiveFormModal/types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ArchiveSchema: yup.SchemaOf<ArchiveFormData> = yup.object().shape({
  [ArchiveFormFieldName.isPublished]: yup
    .boolean()
    .required(getErrorMessage('isPublished', 'status_required')),
  [ArchiveFormFieldName.name]: yup.string().required(getErrorMessage('name', 'name_required')),
  [ArchiveFormFieldName.picture]: yup.object().nullable(),
  [ArchiveFormFieldName.pictureUrl]: yup.string(),
});

export default ArchiveSchema;
