import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth', 'admin', 'archive', 'user', 'promoCode', 'stream', 'trainer'],
  timeout: 10000,
};

export const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['accessToken', 'refreshToken', 'isAuthenticated', 'user'],
};

export default persistConfig;
