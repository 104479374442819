import {
  AuthActionTypes,
  SET_ACCESS_TOKEN,
  SET_FORGOTTEN_PASSWORD_ERROR,
  SET_FORGOTTEN_PASSWORD_LOADING,
  SET_FORGOTTEN_PASSWORD_SUCCESS,
  SET_LOGIN_ERROR,
  SET_LOGIN_LOADING,
  SET_LOGIN_SUCCESS,
  SET_LOGOUT_ERROR,
  SET_LOGOUT_LOADING,
  SET_LOGOUT_SUCCESS,
  SET_REFRESH_TOKEN,
  SET_RESET_PASSWORD_ERROR,
  SET_RESET_PASSWORD_LOADING,
  SET_RESET_PASSWORD_SUCCESS,
  LOGOUT,
  SET_USER,
} from './actionTypes';
import { AuthState } from './types';

const authDefaultState: AuthState = {
  isLoginLoading: false,
  isLogoutLoading: false,
  isForgottenPasswordLoading: false,
  isResetPasswordLoading: false,
  isLogoutSuccess: false,
  isForgottenPasswordSuccess: false,
  isResetPasswordSuccess: false,
  loginError: undefined,
  logoutError: undefined,
  forgottenPasswordError: undefined,
  resetPasswordError: undefined,
  isAuthenticated: false,
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
};

const authReducers = (state = authDefaultState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload,
      };

    case SET_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: !!action.payload.accessToken,
        loginError: undefined,
      };

    case SET_LOGOUT_SUCCESS:
      return authDefaultState;

    case SET_FORGOTTEN_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgottenPasswordSuccess: action.payload,
        forgottenPasswordError: undefined,
      };

    case SET_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordSuccess: action.payload,
        resetPasswordError: undefined,
      };

    case SET_LOGIN_ERROR:
      return {
        ...state,
        loginError: action.payload,
      };

    case SET_LOGOUT_ERROR:
      return {
        ...state,
        logoutError: action.payload,
        isLogoutSuccess: false,
      };

    case SET_FORGOTTEN_PASSWORD_ERROR:
      return {
        ...state,
        forgottenPasswordError: action.payload,
        isForgottenPasswordSuccess: false,
      };

    case SET_RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordError: action.payload,
        isResetPasswordSuccess: false,
      };

    case SET_LOGIN_LOADING:
      return {
        ...state,
        isLoginLoading: action.payload,
      };

    case SET_LOGOUT_LOADING:
      return {
        ...state,
        isLogoutLoading: action.payload,
      };

    case SET_FORGOTTEN_PASSWORD_LOADING:
      return {
        ...state,
        isForgottenPasswordLoading: action.payload,
      };

    case SET_RESET_PASSWORD_LOADING:
      return {
        ...state,
        isResetPasswordLoading: action.payload,
      };

    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
        isAuthenticated: !!action.payload,
      };

    case SET_REFRESH_TOKEN:
      return { ...state, refreshToken: action.payload };

    case LOGOUT:
      return authDefaultState;

    default:
      return state;
  }
};

export default authReducers;
