import React from 'react';

import { useTranslation } from 'react-i18next';

import { Modal } from 'web-components';

import { AdminDetailsModalProps } from './types';

/**
 * User details modal content.
 * @param data - The user's data.
 * @param show - If 'true', the modal is displayed.
 * @param onCancel - A function what will be called when the cancel button was pressed.
 */
const UserDetailsModal: React.FC<AdminDetailsModalProps> = ({ data, onCancel, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Modal
      {...rest}
      title={t('admin_details_modal.title')}
      actionButtons={[
        {
          text: t('common.back'),
          onClick: onCancel,
          classes: ['bg-secondary', 'font-weight-bold'],
        },
      ]}
    >
      <div className="text-primary">
        <div className="row">
          <div className="col-6">
            <div>{t('admin_details_modal.email')}</div>
            <div className="font-weight-bold">{data.email}</div>
          </div>
          <div className="col-6">
            <div>{t('admin_details_modal.roles')}</div>
            <div className="font-weight-bold">{data?.roles?.join(', ')}</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-6">
            <div>{t('admin_details_modal.lastname')}</div>
            <div className="font-weight-bold">{data.lastName}</div>
          </div>
          <div className="col-6">
            <div>{t('admin_details_modal.firstname')}</div>
            <div className="font-weight-bold">{data.firstName}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
