import { URI_REGEX, getErrorMessage } from 'shared';
import * as yup from 'yup';

import {
  StreamFormData,
  StreamFormFieldName,
} from '../../screens/StreamListScreen/StreamFormModal/types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StreamSchema: yup.SchemaOf<StreamFormData> = yup.object().shape({
  [StreamFormFieldName.picture]: yup.object().nullable(),
  [StreamFormFieldName.pictureUrl]: yup.string(),
  [StreamFormFieldName.description]: yup.string(),

  [StreamFormFieldName.endTime]: yup
    .string()
    .required(getErrorMessage('endTime', 'stream_end_time_required'))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .when([StreamFormFieldName.startTime], (startTime: string, schema: any) => {
      return schema.test({
        test: (endTime: string) => !!startTime && new Date(endTime) > new Date(startTime),
        message: getErrorMessage('endTime', 'stream_end_time_greater_than_start'),
      });
    }),

  [StreamFormFieldName.startTime]: yup
    .string()
    .required(getErrorMessage('startTime', 'stream_start_time_required')),

  [StreamFormFieldName.url]: yup
    .string()
    .matches(URI_REGEX, { message: getErrorMessage('url', 'stream_url_format') })
    .required(getErrorMessage('url', 'stream_url_required')),

  [StreamFormFieldName.trainer]: yup
    .string()
    .required(getErrorMessage('trainer', 'stream_trainer_required')),
  [StreamFormFieldName.name]: yup.string().required(getErrorMessage('name', 'name_required')),
});

export default StreamSchema;
