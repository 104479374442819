import React from 'react';

import { NavLink as RNavLink } from 'react-router-dom';

import styles from './NavLink.module.scss';
import { NavLinkProps } from './types';

/**
 * Navigation link component for navigation bar.
 * @param to - The route where the app navigates when pressed the link.
 * @param children - The label.
 * @param className - Custom classes.
 */
const NavLink: React.FC<NavLinkProps> = ({ children, className = '', ...rest }) => {
  return (
    <div className={`my-3 ${styles.link}`}>
      <RNavLink {...rest} className={className}>
        {children}
      </RNavLink>
    </div>
  );
};

export default NavLink;
