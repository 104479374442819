import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { NavBar } from '@components';

import { logout } from '../store/auth/actions';
import { AppState } from '../store/types';

import { PrivateRouteProps, ROUTE } from './types';

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component }) => {
  const { isAuthenticated } = useSelector(({ auth }: AppState) => auth);

  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
  };

  return isAuthenticated ? (
    <>
      <NavBar onLogout={onLogout} />
      {component}
    </>
  ) : (
    <Navigate to={ROUTE.Login} />
  );
};

export default PrivateRoute;
