import React, { useEffect } from 'react';

import { AppType } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useFormValidation } from 'shared';

import { Card, Image, Button, Input } from 'web-components';

import { FormControl, Error, Link } from '@components';

import { ROUTE } from '@navigation/types';

import { sendForgottenPassword } from '../../store/auth/actions';
import { AppState } from '../../store/types';

import Images from '../../utils/images';
import ForgottenPasswordSchema from '../../utils/validation/ForgottenPasswordSchema';

import styles from './ForgottenPasswordScreen.module.scss';
import { ForgottenPasswordFields } from './types';

const ForgottenPasswordScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { forgottenPasswordError, isForgottenPasswordLoading, isForgottenPasswordSuccess } =
    useSelector((state: AppState) => state.auth);

  const [fields, setFields] = React.useState<ForgottenPasswordFields>({ email: '' });
  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

  const { isFormValid, validationErrors } = useFormValidation<ForgottenPasswordFields>(
    ForgottenPasswordSchema,
    fields,
  );

  useEffect(() => {
    setErrorMessage(
      (isFormSubmitted && validationErrors[Object.keys(validationErrors)[0]]) ||
        forgottenPasswordError,
    );
  }, [isFormSubmitted, validationErrors, forgottenPasswordError]);

  const onFieldChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    setIsFormSubmitted(true);

    if (isFormValid) {
      dispatch(sendForgottenPassword({ email: fields.email, app: AppType.Admin }));
    }
  };

  return (
    <div
      className={`container-fluid vh-100 d-flex bg-primary mt-2 ${styles.forgottenPasswordScreenContainer}`}
    >
      <div
        className={`d-flex flex-column align-items-center ${styles.forgottenPasswordContainerSize}`}
      >
        <Image src={Images.LOGIN_LOGO} />
        <form
          className={`text-right text-secondary mt-4 w-100 ${styles.form}`}
          onSubmit={onSubmit}
          noValidate
        >
          <Card additionalStyles={`text-primary mb-5 mt-2 w-100 ${styles.card}`}>
            <>
              <FormControl>
                <Input
                  placeholder={t('forgotten_password_screen.email')}
                  type="email"
                  name="email"
                  value={fields.email}
                  onChange={onFieldChange}
                  className={styles.input}
                />
              </FormControl>

              <div className="mt-2">{errorMessage && <Error error={errorMessage} />}</div>

              {isForgottenPasswordSuccess && (
                <div className="mt-2 text-success">{t('forgotten_password_screen.success')}</div>
              )}

              <Link to={ROUTE.Login} type="small" className="text-decoration-none">
                {t('common.go_to_login')}
              </Link>
            </>
          </Card>

          <Button
            type="submit"
            additionalStyles={`${styles.button} w-100 text-uppercase text-primary btn-lg font-italic text-primary bg-light mb-5 text-center text-normal`}
            disabled={isForgottenPasswordSuccess || isForgottenPasswordLoading}
          >
            {isForgottenPasswordLoading
              ? t('forgotten_password_screen.sending')
              : t('forgotten_password_screen.send')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ForgottenPasswordScreen;
