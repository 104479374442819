import { User } from 'interfaces';

export const SET_USER = 'SET_USER';

export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';
export const SET_LOGOUT_SUCCESS = 'SET_LOGOUT_SUCCESS';
export const SET_FORGOTTEN_PASSWORD_SUCCESS = 'SET_FORGOTTEN_PASSWORD_SUCCESS';
export const SET_RESET_PASSWORD_SUCCESS = 'SET_RESET_PASSWORD_SUCCESS';

export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_LOGOUT_ERROR = 'SET_LOGOUT_ERROR';
export const SET_FORGOTTEN_PASSWORD_ERROR = 'SET_FORGOTTEN_PASSWORD_ERROR';
export const SET_RESET_PASSWORD_ERROR = 'SET_RESET_PASSWORD_ERROR';

export const SET_LOGIN_LOADING = 'SET_LOGIN_LOADING';
export const SET_LOGOUT_LOADING = 'SET_LOGOUT_LOADING';
export const SET_FORGOTTEN_PASSWORD_LOADING = 'SET_FORGOTTEN_PASSWORD_LOADING';
export const SET_RESET_PASSWORD_LOADING = 'SET_RESET_PASSWORD_LOADING';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';

export const LOGOUT = 'LOGOUT';

export interface SetUser {
  type: typeof SET_USER;
  payload: {
    user: User;
  };
}

export interface SetLoginSuccess {
  type: typeof SET_LOGIN_SUCCESS;
  payload: {
    accessToken: string;
    refreshToken: string;
    user: User;
  };
}

export interface SetLogoutSuccess {
  type: typeof SET_LOGOUT_SUCCESS;
  payload: boolean;
}

export interface SetForgottenPasswordSuccess {
  type: typeof SET_FORGOTTEN_PASSWORD_SUCCESS;
  payload: boolean;
}

export interface SetResetPasswordSuccess {
  type: typeof SET_RESET_PASSWORD_SUCCESS;
  payload: boolean;
}

export interface SetLoginError {
  type: typeof SET_LOGIN_ERROR;
  payload?: string;
}

export interface SetLogoutError {
  type: typeof SET_LOGOUT_ERROR;
  payload?: string;
}

export interface SetForgottenPasswordError {
  type: typeof SET_FORGOTTEN_PASSWORD_ERROR;
  payload?: string;
}

export interface SetResetPasswordError {
  type: typeof SET_RESET_PASSWORD_ERROR;
  payload?: string;
}

export interface SetLoginLoading {
  type: typeof SET_LOGIN_LOADING;
  payload: boolean;
}

export interface SetLogoutLoading {
  type: typeof SET_LOGOUT_LOADING;
  payload: boolean;
}

export interface SetForgottenPasswordLoading {
  type: typeof SET_FORGOTTEN_PASSWORD_LOADING;
  payload: boolean;
}

export interface SetResetPasswordLoading {
  type: typeof SET_RESET_PASSWORD_LOADING;
  payload: boolean;
}

export interface SetAccessToken {
  type: typeof SET_ACCESS_TOKEN;
  payload?: string;
}

export interface SetRefreshToken {
  type: typeof SET_REFRESH_TOKEN;
  payload?: string;
}

export interface Logout {
  type: typeof LOGOUT;
}

export type AuthActionTypes =
  | SetUser
  | SetLoginSuccess
  | SetLogoutSuccess
  | SetForgottenPasswordSuccess
  | SetResetPasswordSuccess
  | SetLoginError
  | SetLogoutError
  | SetForgottenPasswordError
  | SetResetPasswordError
  | SetLoginLoading
  | SetLogoutLoading
  | SetForgottenPasswordLoading
  | SetResetPasswordLoading
  | SetAccessToken
  | SetRefreshToken
  | Logout;
