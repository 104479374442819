import React from 'react';

import { Provider } from 'react-redux';
import { DefaultToastContainer, ToastProvider, ToastContainerProps } from 'react-toast-notifications';
import { PersistGate } from 'redux-persist/integration/react';

import RootRouter from '@navigation/RootRouter';

import { store, persistor } from '../store';

import styles from './App.module.scss';

export const CustomToastContainer = (props: ToastContainerProps): React.ReactElement => (
  <DefaultToastContainer {...props} className={styles.toast} />
);

const App: React.FC = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ToastProvider components={{ ToastContainer: CustomToastContainer }}  autoDismiss >
        <RootRouter />
      </ToastProvider>
    </PersistGate>
  </Provider>
);

export default App;
