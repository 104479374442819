import React, { useEffect, useState } from 'react';

import { Button } from '@components';

import styles from './TopScrollButton.module.scss';
import { TopScrollButtonProps } from './types';

/**
 * Button to scroll top component
 * @param containerId - HTML element to scroll.
 * @param show - Is button visible.
 */
const TopScrollButton: React.FC<TopScrollButtonProps> = ({ containerId }) => {
  const [isScrollTop, setIsScrollTop] = useState<boolean>(false);
  const [isClicked, setClicked] = useState<boolean>(false);

  const clicked = () => {
    setClicked(true);
  };

  useEffect(() => {
    const element = document.getElementById(containerId);
    element && element.scrollTo({ top: 0, behavior: 'smooth' });
    setClicked(false);
  }, [containerId, isClicked]);

  useEffect(() => {
    const element = document.getElementById(containerId);
    const handler = () => {
      element && setIsScrollTop(element.scrollTop > 0);
    };
    element && element.addEventListener('scroll', handler);
    return function cleanup() {
      element && element.removeEventListener('scroll', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isScrollTop && (
        <Button onClick={clicked} className={styles.topScrollButton}>
          <div className={styles.topScrollButtonInsideBox}>
            <span className={styles.arrowTopBorder} />
            <span className={styles.arrow} />
          </div>
        </Button>
      )}
    </>
  );
};

export default TopScrollButton;
