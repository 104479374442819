import React from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'web-components';

import { Image } from '@components';

import { ArchiverDetailsModalProps } from './types';

/**
 * Archive details modal.
 * @param data - The video's data.
 * @param show - If 'true', the modal is displayed.
 * @param onCancel - A function what will be called when the cancel button was pressed.
 */
const ArchiveDetailsModal: React.FC<ArchiverDetailsModalProps> = ({ data, onCancel, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Modal
      {...rest}
      title={t('archive_details_modal.title')}
      actionButtons={[{ text: t('common.cancel'), onClick: onCancel, classes: ['bg-secondary', 'text-primary', 'font-weight-bold'], }]}
    >
      <div className="text-primary">
        <div className="row ">
          <div className="col">
            <div className="mb-2">{t('archive_form_modal.cover_image')}</div>
            <Image
              src={data.pictures?.cover ?? ''}
              alt={t('archive_details_modal.image_alt')}
              className="border border-dark"
            />
          </div>
        </div>

        <div className="row py-3">
          <div className="col">
            <div className="mb-1">{t('archive_details_modal.name')}</div>
            <div className="mb-3 font-weight-bold">{data.name}</div>
          </div>

          <div className="col">
            <div className="mb-1">{t('archive_details_modal.status')}</div>
            <div className="mb-3 font-weight-bold">
              {data.isPublished
                ? t('archive_details_modal.status_published')
                : t('archive_details_modal.status_unpublished')}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ArchiveDetailsModal;
