import { User, UserRelationships, UserRole } from 'interfaces';

import { appendToList, updateListItem } from 'shared';

import {
  SET_CREATE_ADMIN_ERROR,
  SET_CREATE_ADMIN_LOADING,
  SET_CREATE_ADMIN_SUCCESS,
  SET_GET_ADMINS_ERROR,
  SET_GET_ADMINS_LOADING,
  SET_UPDATE_ADMIN_ERROR,
  SET_UPDATE_ADMIN_LOADING,
  SET_UPDATE_ADMIN_SUCCESS,
  SET_ADMINS,
  SET_DELETE_ADMIN_ERROR,
  SET_DELETE_ADMIN_LOADING,
  SET_DELETE_ADMIN_SUCCESS,
  APPEND_ADMINS,
  SET_ADMINS_LAST_PAGE_REACHED,
  INC_ADMINS_CURRENT_PAGE,
  RESET_ADMINS_CURRENT_PAGE,
  SET_ADMINS_GET_PARAMS,
  AdminActionTypes,
} from './actionTypes';
import { AdminState } from './types';

const defaultState: AdminState = {
  adminList: [],
  getParams: {
    include: [UserRelationships.Subscription],
    filter: { role: UserRole.ADMIN },
  },
  isAdminListLastPageReached: false,
  isCreateAdminSuccess: false,
  isUpdateAdminSuccess: false,
  isDeleteAdminSuccess: false,
  getAdminsError: undefined,
  createAdminError: undefined,
  updateAdminError: undefined,
  deleteAdminError: undefined,
  isGetAdminsLoading: false,
  isCreateAdminLoading: false,
  isUpdateAdminLoading: false,
  isDeleteAdminLoading: false,
};

export default (state = defaultState, action: AdminActionTypes): AdminState => {
  switch (action.type) {
    case SET_ADMINS:
      return {
        ...state,
        adminList: action.payload,
      };

    case SET_ADMINS_GET_PARAMS:
      return {
        ...state,
        getParams: action.payload,
      };

    case INC_ADMINS_CURRENT_PAGE:
      return {
        ...state,
        getParams: { ...state.getParams, page: (state.getParams.page ?? 1) + 1 },
      };

    case RESET_ADMINS_CURRENT_PAGE:
      return {
        ...state,
        isAdminListLastPageReached: false,
        getParams: { ...state.getParams, page: 1 },
      };

    case APPEND_ADMINS:
      return {
        ...state,
        adminList: appendToList(state.adminList, action.payload) as User[],
      };

    case SET_ADMINS_LAST_PAGE_REACHED:
      return {
        ...state,
        isAdminListLastPageReached: action.payload,
      };

    case SET_CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        isCreateAdminSuccess: action.payload,
        createAdminError: undefined,
      };

    case SET_UPDATE_ADMIN_SUCCESS: {
      const admin = action.payload.data;

      return {
        ...state,
        adminList: admin ? (updateListItem(state.adminList, admin) as User[]) : state.adminList,
        isUpdateAdminSuccess: action.payload.success,
        updateAdminError: undefined,
      };
    }

    case SET_DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        isDeleteAdminSuccess: action.payload,
        deleteAdminError: undefined,
      };

    case SET_GET_ADMINS_ERROR:
      return {
        ...state,
        getAdminsError: action.payload,
      };

    case SET_CREATE_ADMIN_ERROR:
      return {
        ...state,
        createAdminError: action.payload,
        isCreateAdminSuccess: false,
      };

    case SET_UPDATE_ADMIN_ERROR:
      return {
        ...state,
        updateAdminError: action.payload,
        isUpdateAdminSuccess: false,
      };

    case SET_DELETE_ADMIN_ERROR:
      return {
        ...state,
        deleteAdminError: action.payload,
        isDeleteAdminSuccess: false,
      };

    case SET_GET_ADMINS_LOADING:
      return {
        ...state,
        isGetAdminsLoading: action.payload,
      };

    case SET_CREATE_ADMIN_LOADING:
      return {
        ...state,
        isCreateAdminLoading: action.payload,
      };

    case SET_UPDATE_ADMIN_LOADING:
      return {
        ...state,
        isUpdateAdminLoading: action.payload,
      };

    case SET_DELETE_ADMIN_LOADING:
      return {
        ...state,
        isDeleteAdminLoading: action.payload,
      };

    default:
      return state;
  }
};
