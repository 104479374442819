import React from 'react';

import { TFunctionResult } from 'i18next';
import { useTranslation } from 'react-i18next';

import styles from './ListItemActions.module.scss';
import { ListItemActionsProps } from './types';

/**
 * Actions for the general list item component.
 * @param detailsLabel - The label of the details action.
 * @param editLabel - The label of the edit action.
 * @param deleteLabel - The label of the delete action.
 * @param className - Custom classes.
 * @param onDetailsClick - A function what will be called when the details label was clicked.
 * @param onEditClick - A function what will be called when the edit label was clicked.
 * @param onDeleteClick - A function what will be called when the delete label was clicked.
 */
const ListItemActions: React.FC<ListItemActionsProps> = (props) => {
  const {
    detailsLabel,
    editLabel,
    deleteLabel,
    className = '',
    onDetailsClick,
    onEditClick,
    onDeleteClick,
  } = props;

  const { t } = useTranslation();

  const renderAction = (
    label: string | TFunctionResult,
    onClick: () => void,
  ): React.ReactElement => (
    <div className={`text-dark text-right mt-2 ${styles.action}`}>
      <span onClick={onClick}>{label}</span>
    </div>
  );

  return (
    <div className={`col ${className}`}>
      {onDetailsClick && renderAction(detailsLabel || t('list_item.details'), onDetailsClick)}
      {renderAction(editLabel || t('list_item.edit'), onEditClick)}
      {onDeleteClick && renderAction(deleteLabel || t('list_item.delete'), onDeleteClick)}
    </div>
  );
};

export default ListItemActions;
