import React, { useEffect } from 'react';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './DateSelector.module.scss';
import { DateSelectorProps } from './types';

/**
 * Date selector component.
 * @param selected - Default selected value.
 * @param placeholder - The placeholder.
 * @param onChange - Change event handler.
 */
const DateSelector: React.FC<DateSelectorProps> = ({
  selected = null,
  placeholder = '',
  onChange,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(selected);

  const handleChange = (date: Date, event: React.SyntheticEvent<unknown> | undefined) => {
    setSelectedDate(date);

    if (onChange) {
      onChange(date, event);
    }
  };

  useEffect(() => {
    setSelectedDate(selected);
  }, [selected]);

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleChange}
      showTimeSelect={false}
      timeFormat="HH:mm"
      timeCaption="time"
      placeholderText={placeholder}
      dateFormat="yyyy-MM-dd hh:mm aa"
      className={`form-control text-dark border border-dark shadow-none text-uppercase ${styles.dateSelector}`}
      wrapperClassName="w-100"
      {...rest}
    />
  );
};

export default DateSelector;
