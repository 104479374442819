import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './LogoutButton.module.scss';
import { LogoutButtonProps } from './types';

const LogoutButton: React.FC<LogoutButtonProps> = ({ className = '', ...rest }) => {
  const { t } = useTranslation();

  return (
    <div {...rest} className={`container ${className}`}>
      <div className={`row ${styles.logoutBtn}`}>
        <span className={`col-2 p-0 my-auto ${styles.logoutIconContainer}`}>
          <span className={styles.arrow} />
        </span>
        <span className="col-10 pl-3 my-auto">{t('navbar.sign_out')}</span>
      </div>
    </div>
  );
};

export default LogoutButton;
