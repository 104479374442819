import { NUMBER_REGEX, getErrorMessage } from 'shared';
import * as yup from 'yup';

import { UserFormData, UserFormFieldName } from '../../screens/UserListScreen/UserFormModal/types';

const UserSchema: yup.SchemaOf<UserFormData> = yup.object().shape({
  [UserFormFieldName.country]: yup
    .string()
    .required(getErrorMessage('country', 'user_country_required')),
  [UserFormFieldName.city]: yup.string().required(getErrorMessage('city', 'user_city_required')),
  [UserFormFieldName.zipCode]: yup
    .string()
    .matches(NUMBER_REGEX, { message: getErrorMessage('zipCode', 'user_zip_code_format') })
    .required(getErrorMessage('zipCode', 'user_zip_code_required')),
  [UserFormFieldName.address]: yup
    .string()
    .required(getErrorMessage('address', 'user_address_required')),
  [UserFormFieldName.companyVatId]: yup.string(),
  [UserFormFieldName.companyName]: yup.string(),
  [UserFormFieldName.firstName]: yup
    .string()
    .required(getErrorMessage('firstName', 'first_name_required')),
  [UserFormFieldName.lastName]: yup
    .string()
    .required(getErrorMessage('lastName', 'last_name_required')),
  [UserFormFieldName.email]: yup
    .string()
    .required(getErrorMessage('email', 'email_required'))
    .email(getErrorMessage('email', 'email_invalid')),
  [UserFormFieldName.roles]: yup
      .array().min(1, getErrorMessage('roles', 'roles_required')),
});

export default UserSchema;
