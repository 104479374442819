import { GetUsersParams, UserClient, isLastPageReached } from 'api_client';

import { User } from 'interfaces';

import { ThunkDispatch } from 'redux-thunk';

import { Action, Dispatch } from 'redux';

import { AdminFormData } from '../../screens/AdminListScreen/AdminFormModal/types';

import { setUser } from '../auth/actions';
import { TAppState } from '../index';

import { AppThunk } from '../types';

import {
  SET_CREATE_ADMIN_ERROR,
  SET_CREATE_ADMIN_LOADING,
  SET_CREATE_ADMIN_SUCCESS,
  SET_GET_ADMINS_ERROR,
  SET_GET_ADMINS_LOADING,
  SET_UPDATE_ADMIN_ERROR,
  SET_UPDATE_ADMIN_LOADING,
  SET_UPDATE_ADMIN_SUCCESS,
  SET_ADMINS,
  SET_DELETE_ADMIN_SUCCESS,
  SET_DELETE_ADMIN_ERROR,
  SET_DELETE_ADMIN_LOADING,
  SET_ADMINS_LAST_PAGE_REACHED,
  APPEND_ADMINS,
  INC_ADMINS_CURRENT_PAGE,
  RESET_ADMINS_CURRENT_PAGE,
  SET_ADMINS_GET_PARAMS,
} from './actionTypes';

export const setAdmins =
  (users: User[]): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_ADMINS,
      payload: users,
    });
  };

export const setAdminsGetParams =
  (params: GetUsersParams): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_ADMINS_GET_PARAMS,
      payload: params,
    });
  };

export const appendAdmins =
  (users: User[]): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: APPEND_ADMINS,
      payload: users,
    });
  };

export const setAdminsLastPageReached =
  (reached: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_ADMINS_LAST_PAGE_REACHED,
      payload: reached,
    });
  };

export const setCreateAdminSuccess =
  (isSuccess: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_CREATE_ADMIN_SUCCESS,
      payload: isSuccess,
    });
  };

export const setUpdateAdminSuccess =
  (isSuccess: boolean, data?: User): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_UPDATE_ADMIN_SUCCESS,
      payload: {
        success: isSuccess,
        data: data,
      },
    });
  };

export const setDeleteAdminSuccess =
  (isSuccess: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_DELETE_ADMIN_SUCCESS,
      payload: isSuccess,
    });
  };

export const setGetAdminsError =
  (error?: string): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_GET_ADMINS_ERROR,
      payload: error,
    });
  };

export const setCreateAdminError =
  (error?: string): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_CREATE_ADMIN_ERROR,
      payload: error,
    });
  };

export const setUpdateAdminError =
  (error?: string): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_UPDATE_ADMIN_ERROR,
      payload: error,
    });
  };

export const setDeleteAdminError =
  (error?: string): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_DELETE_ADMIN_ERROR,
      payload: error,
    });
  };

export const setGetAdminsLoading =
  (isLoading: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_GET_ADMINS_LOADING,
      payload: isLoading,
    });
  };

export const setCreateAdminLoading =
  (isLoading: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_CREATE_ADMIN_LOADING,
      payload: isLoading,
    });
  };

export const setUpdateAdminLoading =
  (isLoading: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_UPDATE_ADMIN_LOADING,
      payload: isLoading,
    });
  };

export const setDeleteAdminLoading =
  (isLoading: boolean): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_DELETE_ADMIN_LOADING,
      payload: isLoading,
    });
  };

export const incAdminsCurrentPage =
  (): AppThunk =>
  (dispatch: Dispatch, getState): void => {
    if (!getState().admin.isAdminListLastPageReached) {
      dispatch({
        type: INC_ADMINS_CURRENT_PAGE,
      });
    }
  };

export const resetAdminsCurrentPage =
  (): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: RESET_ADMINS_CURRENT_PAGE,
    });
  };

export const getAdmins =
  (params?: GetUsersParams, lazyLoad = false): AppThunk =>
  async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
    try {
      dispatch(setGetAdminsLoading(true));

      const users = await UserClient.getAll(params);

      if (lazyLoad) {
        dispatch(appendAdmins(users));
      } else {
        dispatch(setAdmins(users));
      }

      dispatch(setAdminsLastPageReached(isLastPageReached(users, params)));
    } catch (error) {
      dispatch(setGetAdminsError(error.message));
    } finally {
      dispatch(setGetAdminsLoading(false));
    }
  };

export const createAdmin =
  (userData: AdminFormData): AppThunk =>
  async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
    try {
      dispatch(setCreateAdminLoading(true));
      dispatch(setCreateAdminSuccess(false));

      const requestData = {
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        roles: userData.roles,
      };

      await UserClient.create(requestData);

      dispatch(setCreateAdminSuccess(true));
    } catch (error) {
      dispatch(setCreateAdminError(error.message));
    } finally {
      dispatch(setCreateAdminLoading(false));
    }
  };

export const updateAdmin =
  (id: string, userData: AdminFormData): AppThunk =>
  async (dispatch: ThunkDispatch<TAppState, void, Action<string>>, getState): Promise<void> => {
    try {
      dispatch(setUpdateAdminLoading(true));
      dispatch(setUpdateAdminSuccess(false));

      const requestData = {
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        roles: userData.roles,
      };

      const data = await UserClient.update(id, requestData);

      const loggedInUser = getState().auth.user;

      if (data && loggedInUser) {
        dispatch(
          setUser({
            user: {
              ...loggedInUser,
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
            },
          }),
        );
      }

      dispatch(setUpdateAdminSuccess(true, data));
    } catch (error) {
      dispatch(setUpdateAdminError(error.message));
    } finally {
      dispatch(setUpdateAdminLoading(false));
    }
  };

export const deleteAdmin =
  (id: string): AppThunk =>
  async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
    try {
      dispatch(setUpdateAdminLoading(true));
      dispatch(setDeleteAdminSuccess(false));

      await UserClient.delete(id);

      dispatch(setDeleteAdminSuccess(true));
    } catch (error) {
      dispatch(setDeleteAdminError(error.message));
    } finally {
      dispatch(setUpdateAdminLoading(false));
    }
  };
