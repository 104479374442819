import { useToasts } from 'react-toast-notifications';

import { NotificationTypes, UseToastNotificationReturn } from './types';

/**
 * Hook to handle toast notifications
 */
const useToastNotification = (): UseToastNotificationReturn => {
  const { addToast, removeAllToasts } = useToasts();

  const errorNotification = (message: string, removeOtherNotifications = true) => {
    showNotification(message, NotificationTypes.Error, removeOtherNotifications);
  };

  const successNotification = (message: string, removeOtherNotifications = true) => {
    showNotification(message, NotificationTypes.Success, removeOtherNotifications);
  };

  const warningNotification = (message: string, removeOtherNotifications = true) => {
    showNotification(message, NotificationTypes.Warning, removeOtherNotifications);
  };

  const infoNotification = (message: string, removeOtherNotifications = true) => {
    showNotification(message, NotificationTypes.Info, removeOtherNotifications);
  };

  const removeAllNotifications = () => {
    removeAllToasts();
  };

  const showNotification = (
    message: string,
    type: NotificationTypes,
    removeOtherNotifications: boolean,
  ) => {
    if (removeOtherNotifications) {
      removeAllToasts();
    }
    addToast(message, { appearance: type });
  };

  return {
    errorNotification,
    successNotification,
    warningNotification,
    infoNotification,
    removeAllNotifications,
  };
};

export default useToastNotification;
