import React, { useEffect, useRef, useState, useCallback } from 'react';

import { Video } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useListModal, getDurationFromSeconds, useScrollPagination } from 'shared';

import {
  ListItem,
  ListItemActions,
  SearchBar,
  Loading,
  Switch,
  Error,
  TopScrollButton,
  AreYouSureModal,
  RatingStars,
} from '@components';

import useToastNotification from '../../hooks/useToastNotification';

import {
  getVideos,
  updateVideo,
  updateVideoStatus,
  incVideosCurrentPage,
  resetVideosCurrentPage,
  setVideosGetParams,
  setGetVideosError,
} from '../../store/archive/actions';
import { AppState } from '../../store/types';

import ArchiveDetailsModal from './ArchiveDetailsModal';
import ArchiveFormModal from './ArchiveFormModal';
import { ArchiveFormData } from './ArchiveFormModal/types';
import styles from './ArchiveListScreen.module.scss';

const ArchiveListScreen: React.FC = () => {
  const { t } = useTranslation();

  const {
    videoList,
    getParams,
    isVideoListLastPageReached,
    isGetVideosLoading,
    isUpdateVideoStatusLoading,
    isUpdateVideoSuccess,
    isUpdateVideoStatusSuccess,
    getVideosError,
    updateVideoStatusError,
    updateVideoError,
  } = useSelector((state: AppState) => state.archive);

  const dispatch = useDispatch();

  const { loadingIndicator } = useScrollPagination(videoList.length > 0, () =>
    dispatch(incVideosCurrentPage()),
  );

  const { errorNotification, removeAllNotifications } = useToastNotification();
  const [enableToastMessage, setEnableToastMessage] = useState<boolean>(false);
  const [validationErrorMessage, setValidationErrorMessage] =
    useState<string | undefined>(undefined);

  const needToFetchData = useRef(!videoList.length);

  const showErrorMessage = useCallback((message: string) => {
    errorNotification(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setGetVideosError(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getVideosError && enableToastMessage) {
      showErrorMessage(getVideosError);
    }
    if (updateVideoStatusError && enableToastMessage) {
      showErrorMessage(updateVideoStatusError);
    }
    if (updateVideoError && enableToastMessage) {
      showErrorMessage(updateVideoError);
    }
  }, [
    getVideosError,
    updateVideoStatusError,
    updateVideoError,
    enableToastMessage,
    showErrorMessage,
  ]);

  useEffect(() => {
    if (isUpdateVideoSuccess) {
      onFormModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateVideoSuccess, dispatch]);

  useEffect(() => {
    if (isUpdateVideoStatusSuccess) {
      onAreYouSureModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateVideoStatusSuccess, dispatch]);

  useEffect(() => {
    //prevent unnecessary reload after return to the current screen with loaded data
    if (needToFetchData.current && !isVideoListLastPageReached) {
      dispatch(getVideos(getParams, (getParams.page ?? 1) > 1));
    }

    if (!needToFetchData.current) {
      needToFetchData.current = true;
    }
  }, [getParams, dispatch, isVideoListLastPageReached]);

  useEffect(() => {
    removeAllNotifications();
    // (removeAllNotifications is excluded bc it doesn't work with that)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateVideoSuccess, isUpdateVideoStatusSuccess]);

  const onSaveButtonClicked = (data: ArchiveFormData, id?: string) => {
    setEnableToastMessage(true);
    if (validationErrorMessage) {
      showErrorMessage(validationErrorMessage);
    } else if (data && id) {
      dispatch(updateVideo(id, data));
    }
  };

  const onStatusToggle = (item: Video) => {
    setEnableToastMessage(true);
    dispatch(updateVideoStatus(item._id, !item.isPublished));
  };

  const onStartSearch = (searchTerm: string): void => {
    dispatch(resetVideosCurrentPage());

    dispatch(setVideosGetParams({ ...getParams, page: undefined, search: searchTerm }));
  };

  const {
    showDetailsModal,
    onDetailsModalClose,
    showFormModal,
    showAreYouSureModal,
    modalData,
    onEditItemClick,
    onItemDetailsClick,
    onFormModalClose,
    onAreYouSureModalClose,
  } = useListModal();

  const listItemLeftContent = (item: Video): React.ReactElement => (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-1">
          <RatingStars
            visibleOnly
            rating={item.stream ? item.stream.rateSum : 0}
            ratersNumber={item.stream ? item.stream.rateCount : 0}
            containerAdditionalStyles="p-0"
            colAdditionalStyles="p-0"
          />
        </div>
      </div>
      <div className="row">
        <div className="col p-0">
          <Switch
            name={`status${item._id}`}
            disabled={isUpdateVideoStatusLoading}
            label={
              item.isPublished
                ? t('archive_list_screen.status_published')
                : t('archive_list_screen.status_unpublished')
            }
            checked={item.isPublished}
            onChange={() => onStatusToggle(item)}
          />
        </div>
      </div>
    </div>
  );

  const listItemRightContent = (item: Video): React.ReactElement => (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <ListItemActions
            editLabel={t('archive_list_screen.edit_video')}
            detailsLabel={t('archive_list_screen.video_details')}
            onDetailsClick={(): void => onItemDetailsClick(item)}
            onEditClick={(): void => onEditItemClick(item)}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="container p-5">
      <div className="row">
        <div className="col-10">
          <SearchBar
            className={`${styles.input} w-100 font-weight-bold`}
            initialValue={getParams.search}
            onStartSearch={onStartSearch}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-10">
          <div className="container-fluid mt-5 mb-5">
            {getVideosError && <Error error={getVideosError} />}

            {videoList.map((item) => (
              <ListItem
                key={item._id}
                title={`${item.name} ${getDurationFromSeconds(item.duration)}`}
                imageHash={item.pictures?.hash ?? ''}
                imageUrl={item.pictures?.coverThumbnail ?? ''}
                imageClassName={styles.listItemImage}
                className="my-2"
                leftContent={listItemLeftContent(item)}
                rightContent={listItemRightContent(item)}
              />
            ))}

            {isGetVideosLoading && <Loading />}

            <div ref={loadingIndicator} />
          </div>
        </div>
      </div>
      <TopScrollButton containerId="root_router" />
      {modalData && (
        <ArchiveDetailsModal
          data={modalData as Video}
          show={showDetailsModal}
          onCancel={onDetailsModalClose}
        />
      )}
      <ArchiveFormModal
        data={modalData as Video}
        show={showFormModal}
        onSave={onSaveButtonClicked}
        onCancel={onFormModalClose}
        onError={(message) => setValidationErrorMessage(message)}
      />
      <AreYouSureModal
        show={showAreYouSureModal}
        isLoading={isUpdateVideoStatusLoading}
        error={updateVideoStatusError}
        onNoClick={onAreYouSureModalClose}
        onYesClick={() => onStatusToggle(modalData as Video)}
      />
    </div>
  );
};

export default ArchiveListScreen;
