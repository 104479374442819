import { User } from 'interfaces';

export interface AdminFormProps {
  data?: User;
  show: boolean;
  onSave: (data: AdminFormData, id?: string) => void;
  onCancel: () => void;
  onError: (message: string) => void;
}

export enum AdminFormFieldName {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  roles = 'roles',
}

export declare enum UserRole {
  ADMIN = "ADMIN",
  USER = "USER"
}

export interface AdminFormData {
  [AdminFormFieldName.firstName]: string;
  [AdminFormFieldName.lastName]: string;
  [AdminFormFieldName.email]: string;
  [AdminFormFieldName.roles]: UserRole[];
}
