import React, { useEffect, useRef, useState, useCallback } from 'react';

import { User, UserSubscriptionData } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useListModal, useScrollPagination, getFullName } from 'shared';

import { Button, Image } from 'web-components';

import {
  AreYouSureModal,
  ListItem,
  ListItemActions,
  Loading,
  SearchBar,
  TopScrollButton,
  Error,
} from '@components';

import useToastNotification from '../../hooks/useToastNotification';

import { AppState } from '../../store/types';
import {
  cancelSubscription,
  createUser,
  getUsers,
  setCreateUserError,
  incUsersCurrentPage,
  resetUsersCurrentPage,
  setUpdateUserError,
  setGetUsersError,
  updateUser,
  updateUserStatus,
  setUsersGetParams,
  disablePromoCode,
} from '../../store/user/actions';

import UserDetailsModal from './UserDetailsModal';
import UserFormModal from './UserFormModal';
import { UserFormData } from './UserFormModal/types';
import styles from './UserListScreen.module.scss';

const UserListScreen: React.FC = () => {
  const { t, i18n } = useTranslation();
  const {
    userList,
    getParams,
    isUserListLastPageReached,
    isUpdateUserSuccess,
    isCreateUserSuccess,
    isUpdateUserStatusSuccess,
    isUpdateUserStatusLoading,
    isGetUsersLoading,
    getUsersError,
    createUserError,
    updateUserError,
    cancelSubscriptionError,
    isCancelSubscriptionSuccess,
    updateUserStatusError,
    isDisablePromoCodeError,
    isDisablePromoCodeSuccess,
  } = useSelector((state: AppState) => state.user);

  const dispatch = useDispatch();

  const { loadingIndicator } = useScrollPagination(userList.length > 0, () =>
    dispatch(incUsersCurrentPage()),
  );

  const { errorNotification, removeAllNotifications } = useToastNotification();
  const [enableToastMessage, setEnableToastMessage] = useState<boolean>(false);
  const [validationErrorMessage, setValidationErrorMessage] =
    useState<string | undefined>(undefined);

  const needToFetchData = useRef(!userList.length);

  const showErrorMessage = useCallback((message: string) => {
    errorNotification(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setGetUsersError(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getUsersError && enableToastMessage) {
      showErrorMessage(getUsersError);
    }
    if (createUserError && enableToastMessage) {
      showErrorMessage(createUserError);
    }
    if (updateUserError && enableToastMessage) {
      showErrorMessage(updateUserError);
    }
    if (updateUserStatusError && enableToastMessage) {
      showErrorMessage(updateUserStatusError);
    }
    if (cancelSubscriptionError && enableToastMessage) {
      showErrorMessage(cancelSubscriptionError);
    }
    if(isDisablePromoCodeError && enableToastMessage) {
      showErrorMessage(isDisablePromoCodeError)
    }
  }, 
  [
    getUsersError,
    createUserError,
    updateUserError,
    updateUserStatusError,
    cancelSubscriptionError,
    isDisablePromoCodeError,
    isDisablePromoCodeSuccess,
    enableToastMessage,
    showErrorMessage,
  ]);

  useEffect(() => {
    if (isCreateUserSuccess) {
      onFormModalClose();

      dispatch(resetUsersCurrentPage());
      dispatch(setUsersGetParams({ ...getParams, page: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateUserSuccess, dispatch]);

  useEffect(() => {
    if (isUpdateUserSuccess) {
      onFormModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateUserSuccess, dispatch]);

  useEffect(() => {
    if (isUpdateUserStatusSuccess) {
      onAreYouSureModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateUserStatusSuccess, dispatch]);

  useEffect(() => {
    //prevent unnecessary reload after return to the current screen with loaded data
    if (needToFetchData.current && !isUserListLastPageReached) {
      dispatch(getUsers(getParams, (getParams.page ?? 1) > 1));
    }

    if (!needToFetchData.current) {
      needToFetchData.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams, dispatch, isCancelSubscriptionSuccess]);

  useEffect(() => {
    removeAllNotifications();
  // (removeAllNotifications is excluded bc it doesn't work with that)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateUserSuccess, isUpdateUserSuccess, isUpdateUserStatusSuccess]);

  const onSaveButtonClicked = (data: UserFormData, id?: string) => {
    setEnableToastMessage(true);
    if (validationErrorMessage) {
      showErrorMessage(validationErrorMessage);
    } else if (data) {
      if (!id) {
        dispatch(createUser(data));
      } else {
        dispatch(updateUser(id, data));
      }
    }
  };

  const onStatusToggle = (item: User) => {
    setEnableToastMessage(true);
    dispatch(updateUserStatus(item._id, !item.disabled));
  };

  const onStartSearch = (searchTerm: string): void => {
    dispatch(resetUsersCurrentPage());

    dispatch(setUsersGetParams({ ...getParams, page: undefined, search: searchTerm }));
  };

  const onCancelSubscriptionClick = (id: string) => {
    setEnableToastMessage(true);
    dispatch(cancelSubscription(id));

    onFormModalClose();
  };

  const onCancelButtonClicked = () => {
    dispatch(setUpdateUserError(undefined));
    dispatch(setCreateUserError(undefined));

    onFormModalClose();
  };

  const onDisablePromoCodeButtonClicked = (subscription: UserSubscriptionData) => {
    setEnableToastMessage(true);
    dispatch(disablePromoCode(subscription._id, !subscription.promoCodeDisabled));
    onFormModalClose();
  }

  const {
    showDetailsModal,
    showFormModal,
    showAreYouSureModal,
    modalData,
    onItemDetailsClick,
    onNewItemClick,
    onEditItemClick,
    onDeleteItemClick,
    onDetailsModalClose,
    onFormModalClose,
    onAreYouSureModalClose,
  } = useListModal();

  const listItemLeftContent = (item: User): React.ReactElement => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-1 p-0 my-auto">
          <Image className={styles.iconSize} src="/icons/mail.svg" alt="mail" />
        </div>
        <div className="col-11">{item.email}</div>
        {item.disabled ? (
          <>
            <div className="col-1 p-0 my-auto">
              <Image className={styles.iconSize} src="/icons/subscription.svg" alt="subscription" />
            </div>
            <div className="col-11">{t('user_list_screen.inactivated_profile')}</div>
          </>
        ) : (
          <>
            <div className="col-1 p-0 my-auto">
              <Image className={styles.iconSize} src="/icons/subscription.svg" alt="subscription" />
            </div>
            <div className="col-11">
              {item.subscription && (item.subscription as UserSubscriptionData).isActive
                ? t(
                    `subscription_plans.${(
                      item.subscription as UserSubscriptionData
                    )?.plan.toLowerCase()}`,
                  )
                : t('subscription_plans.no_subscription')}
            </div>
          </>
        )}
      </div>
    </div>
  );

  const listItemRightContent = (item: User): React.ReactElement => (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <ListItemActions
            detailsLabel={t('user_list_screen.user_details')}
            editLabel={t('user_list_screen.edit_user')}
            deleteLabel={
              item.disabled
                ? t('user_list_screen.activate_user')
                : t('user_list_screen.inactivate_user')
            }
            onDetailsClick={(): void => onItemDetailsClick(item)}
            onEditClick={(): void => onEditItemClick(item)}
            onDeleteClick={(): void => onDeleteItemClick(item)}
          />
        </div>
      </div>
    </div>
  );

  const disabled = (modalData as User)?.disabled;

  return (
    <div className="container p-5">
      <div className="row">
        <div className="col-6">
          <SearchBar
            className={`${styles.input} w-100 font-weight-bold`}
            initialValue={getParams.search}
            onStartSearch={onStartSearch}
          />
        </div>
        <div className="col-4">
          <Button
            onClick={onNewItemClick}
            additionalStyles={`bg-secondary text-primary btn-sm font-weight-bold w-100 ${styles.button}`}
          >
            {t('user_list_screen.add_new')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-10">
          <div className="container-fluid mt-5 mb-5">
            {getUsersError && <Error error={getUsersError} />}

            {userList.map((item) => (
              <ListItem
                key={item._id}
                title={getFullName(item.firstName, item.lastName, i18n.language)}
                leftContent={listItemLeftContent(item)}
                rightContent={listItemRightContent(item)}
                className="my-2"
              />
            ))}

            {isGetUsersLoading && <Loading />}

            <div ref={loadingIndicator} />
          </div>
        </div>
      </div>
      <TopScrollButton containerId="root_router" />
      {modalData && (
        <UserDetailsModal
          data={modalData as User}
          show={showDetailsModal}
          onCancel={onDetailsModalClose}
        />
      )}
      <UserFormModal
        data={modalData as User}
        show={showFormModal}
        onSave={onSaveButtonClicked}
        onSubscriptionCancel={onCancelSubscriptionClick}
        onCancel={onCancelButtonClicked}
        onDisablePromoCode={onDisablePromoCodeButtonClicked}
        onError={(message) => setValidationErrorMessage(message)}
      />
      <AreYouSureModal
        show={showAreYouSureModal}
        isLoading={isUpdateUserStatusLoading}
        error={updateUserStatusError}
        onNoClick={onAreYouSureModalClose}
        onYesClick={() => onStatusToggle(modalData as User)}
        title={t(
          disabled ? 'user_form_modal.delete_activate' : 'user_form_modal.delete_deactivate',
        )}
        yesButtonTitle={t(
          disabled
            ? 'user_form_modal.delete_yes_button_activate'
            : 'user_form_modal.delete_yes_button_deactivate',
        )}
      />
    </div>
  );
};

export default UserListScreen;
