import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './Select.module.scss';
import { SelectProps } from './types';

/**
 * Select component.
 * @param value - The selected value.
 * @param options - The options.
 * @param placeholder - The placeholder text.
 * @param className - Custom classes.
 * @param onChange - A function what will be called when changed the selected value.
 * @param multiple - Multiselect true | false
 */
const Select: React.FC<SelectProps> = ({
  value,
  options,
  placeholder,
  className = '',
  multiple,
  placeholderDisable = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const placeholderText = placeholder ? placeholder : t('common.select_placeholder');
  return (
    <select
      {...rest}
      value={value}
      className={`form-control form-select text-dark border border-dark shadow-none ${styles.select} ${className}`}
      multiple={multiple}
    >
      {!placeholderDisable && <option value="">{placeholderText}</option>}
      {options.map((item) => {

        const values = value as string[];
        const optionValue = values.includes(item.value) ? `\u2713 ${item.text}`: `\u2a2f ${item.text}`;

        return (
          <option key={item.value} value={item.value}>
            {multiple ? optionValue : item.text}
          </option>
        );
      })}
    </select>
  );
};

export default Select;