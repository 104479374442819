import React from 'react';

import styles from './FormControl.module.scss';
import { FormControlProps } from './types';

/**
 * Form control component with label, description and error text.
 * @param label - The label.
 * @param children - The content.
 * @param description - The description.
 * @param error - The error text.
 * @param className - Custom classes.
 */
const FormControl: React.FC<FormControlProps> = ({
  label,
  children,
  description,
  error,
  className = '',
}) => {
  return (
    <div className={`form-group ${className}`}>
      {label && <label className="label">{label}</label>}
      {children}
      <small className={`form-text ${styles.help} ${error ? 'text-danger' : 'text-muted'}`}>
        {error ? error : description}
      </small>
    </div>
  );
};

export default FormControl;
