import { PromoCode } from 'interfaces';

import { appendToList, updateListItem } from 'shared';

import {
  APPEND_PROMO_CODES,
  INC_PROMO_CODES_CURRENT_PAGE,
  RESET_PROMO_CODES_CURRENT_PAGE,
  SET_CREATE_PROMO_CODE_ERROR,
  SET_CREATE_PROMO_CODE_LOADING,
  SET_CREATE_PROMO_CODE_SUCCESS,
  SET_DELETE_PROMO_CODE_ERROR,
  SET_DELETE_PROMO_CODE_LOADING,
  SET_DELETE_PROMO_CODE_SUCCESS,
  SET_GENERATE_CODE_ERROR,
  SET_GENERATE_CODE_SUCCESS,
  SET_GENERATED_CODE,
  SET_GET_PROMO_CODES_ERROR,
  SET_GET_PROMO_CODES_LOADING,
  SET_PROMO_CODES,
  SET_PROMO_CODES_GET_PARAMS,
  SET_PROMO_CODES_LAST_PAGE_REACHED,
  SET_UPDATE_PROMO_CODE_ERROR,
  SET_UPDATE_PROMO_CODE_LOADING,
  SET_UPDATE_PROMO_CODE_STATUS_ERROR,
  SET_UPDATE_PROMO_CODE_STATUS_LOADING,
  SET_UPDATE_PROMO_CODE_STATUS_SUCCESS,
  SET_UPDATE_PROMO_CODE_SUCCESS,
  PromoCodeActionTypes,
} from './actionTypes';
import { PromoCodeState } from './types';

const defaultState: PromoCodeState = {
  promoCodeList: [],
  getParams: {},
  isPromoCodeListLastPageReached: false,
  generatedCode: '',
  getPromoCodesError: undefined,
  isCreatePromoCodeSuccess: false,
  isUpdatePromoCodeSuccess: false,
  isUpdatePromoCodeStatusSuccess: false,
  isDeletePromoCodeSuccess: false,
  isGenerateCodeSuccess: false,
  createPromoCodeError: undefined,
  updatePromoCodeError: undefined,
  updatePromoCodeStatusError: undefined,
  deletePromoCodeError: undefined,
  generateCodeError: undefined,
  isGetPromoCodesLoading: false,
  isUpdatePromoCodeLoading: false,
  isUpdatePromoCodeStatusLoading: false,
  isCreatePromoCodeLoading: false,
  isDeletePromoCodeLoading: false,
  isGenerateCodeLoading: false,
};

export default (state = defaultState, action: PromoCodeActionTypes): PromoCodeState => {
  switch (action.type) {
    case SET_PROMO_CODES:
      return {
        ...state,
        promoCodeList: action.payload,
      };

    case SET_PROMO_CODES_GET_PARAMS:
      return {
        ...state,
        getParams: action.payload,
      };

    case INC_PROMO_CODES_CURRENT_PAGE:
      return {
        ...state,
        getParams: { ...state.getParams, page: (state.getParams.page ?? 1) + 1 },
      };

    case RESET_PROMO_CODES_CURRENT_PAGE:
      return {
        ...state,
        isPromoCodeListLastPageReached: false,
        getParams: { ...state.getParams, page: 1 },
      };

    case APPEND_PROMO_CODES:
      return {
        ...state,
        promoCodeList: appendToList(state.promoCodeList, action.payload) as PromoCode[],
      };

    case SET_PROMO_CODES_LAST_PAGE_REACHED:
      return {
        ...state,
        isPromoCodeListLastPageReached: action.payload,
      };

    case SET_GENERATED_CODE:
      return {
        ...state,
        generatedCode: action.payload,
      };

    case SET_CREATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isCreatePromoCodeSuccess: action.payload,
        createPromoCodeError: undefined,
      };

    case SET_UPDATE_PROMO_CODE_SUCCESS: {
      const promoCode = action.payload.data;

      return {
        ...state,
        promoCodeList: promoCode
          ? (updateListItem(state.promoCodeList, promoCode) as PromoCode[])
          : state.promoCodeList,
        isUpdatePromoCodeSuccess: action.payload.success,
        updatePromoCodeError: undefined,
      };
    }

    case SET_UPDATE_PROMO_CODE_STATUS_SUCCESS: {
      const promoCode = action.payload.data;

      return {
        ...state,
        promoCodeList: promoCode
          ? (updateListItem(state.promoCodeList, promoCode) as PromoCode[])
          : state.promoCodeList,
        isUpdatePromoCodeStatusSuccess: action.payload.success,
        updatePromoCodeStatusError: undefined,
      };
    }

    case SET_DELETE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isDeletePromoCodeSuccess: action.payload,
        deletePromoCodeError: undefined,
      };

    case SET_GENERATE_CODE_SUCCESS:
      return {
        ...state,
        isGenerateCodeSuccess: action.payload,
        generateCodeError: undefined,
      };

    case SET_GET_PROMO_CODES_ERROR:
      return {
        ...state,
        getPromoCodesError: action.payload,
      };

    case SET_CREATE_PROMO_CODE_ERROR:
      return {
        ...state,
        createPromoCodeError: action.payload,
        isCreatePromoCodeSuccess: false,
      };

    case SET_UPDATE_PROMO_CODE_ERROR:
      return {
        ...state,
        updatePromoCodeError: action.payload,
        isUpdatePromoCodeSuccess: false,
      };

    case SET_UPDATE_PROMO_CODE_STATUS_ERROR:
      return {
        ...state,
        updatePromoCodeStatusError: action.payload,
        isUpdatePromoCodeStatusSuccess: false,
      };

    case SET_DELETE_PROMO_CODE_ERROR:
      return {
        ...state,
        deletePromoCodeError: action.payload,
        isDeletePromoCodeSuccess: false,
      };

    case SET_GENERATE_CODE_ERROR:
      return {
        ...state,
        generateCodeError: action.payload,
        isGenerateCodeSuccess: false,
      };

    case SET_GET_PROMO_CODES_LOADING:
      return {
        ...state,
        isGetPromoCodesLoading: action.payload,
      };

    case SET_CREATE_PROMO_CODE_LOADING:
      return {
        ...state,
        isCreatePromoCodeLoading: action.payload,
      };

    case SET_UPDATE_PROMO_CODE_LOADING:
      return {
        ...state,
        isUpdatePromoCodeLoading: action.payload,
      };

    case SET_UPDATE_PROMO_CODE_STATUS_LOADING:
      return {
        ...state,
        isUpdatePromoCodeStatusLoading: action.payload,
      };

    case SET_DELETE_PROMO_CODE_LOADING:
      return {
        ...state,
        isDeletePromoCodeLoading: action.payload,
      };

    default:
      return state;
  }
};
