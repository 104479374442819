import React from 'react';

import { Image } from '@components';

import styles from './ListItem.module.scss';
import { ListItemProps } from './types';

/**
 * General list item for lists.
 * @param title - The title.
 * @param leftContent - The left side content, under the title.
 * @param rightContent - The right side content.
 * @param imageHash - The image's hash code.
 * @param imageUrl - The image's url.
 * @param className - Custom classes.
 * @param imageClassName - Custom classes for the image.
 * @param rightContentClassName - Custom classes for the right content.
 */
const ListItem: React.FC<ListItemProps> = (props) => {
  const {
    title,
    imageHash,
    imageUrl,
    leftContent,
    rightContent,
    className = '',
    imageClassName = '',
    rightContentClassName = '',
  } = props;

  return (
    <div
      className={`row p-3 bg-light border border-dark my-3 ${styles.listItemContainer} ${className}`}
    >
      {typeof imageUrl !== 'undefined' && typeof imageHash !== 'undefined' && (
        <div className="col-2 d-flex align-items-center">
          <Image
            src={`${imageUrl}?${imageHash}`}
            className={`border border-dark img-thumbnail ${imageClassName}`}
          />
        </div>
      )}
      <div className="col">
        <div className="font-weight-bold">{title}</div>
        <div>{leftContent}</div>
      </div>
      <div className={`col ${rightContentClassName}`}>{rightContent}</div>
    </div>
  );
};

export { default as ListItemActions } from './ListItemActions';
export default ListItem;
