import { getErrorMessage, PASSWORD_REGEX } from 'shared';
import * as yup from 'yup';

import { ResetPasswordFields } from '../../screens/ResetPasswordScreen/types';

const ResetPasswordSchema: yup.SchemaOf<ResetPasswordFields> = yup.object().shape({
  password: yup
    .string()
    .required(getErrorMessage('password', 'password_required'))
    .matches(PASSWORD_REGEX, { message: getErrorMessage('password', 'password_invalid') }),
  verifyPassword: yup
    .string()
    .required(getErrorMessage('verifyPassword', 'verify_password_required'))
    .oneOf(
      [yup.ref('password'), null],
      getErrorMessage('verifyPassword', 'passwords_do_not_match'),
    ),
});

export default ResetPasswordSchema;
