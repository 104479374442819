import { TextValuePair, Stream } from 'interfaces';

export interface StreamFormModalProps {
  data?: Stream;
  trainerOptions?: TextValuePair[];
  isTrainersLoading: boolean;
  show: boolean;
  onSave: (data: StreamFormData, id?: string) => void;
  onCancel: () => void;
  onError: (message: string) => void;
}

export enum StreamFormFieldName {
  name = 'name',
  trainer = 'trainer',
  url = 'url',
  description = 'description',
  picture = 'picture',
  pictureUrl = 'pictureUrl',
  startTime = 'startTime',
  endTime = 'endTime',
}

export interface StreamFormData {
  [StreamFormFieldName.name]: string;
  [StreamFormFieldName.trainer]: string;
  [StreamFormFieldName.url]: string;
  [StreamFormFieldName.description]?: string;
  [StreamFormFieldName.picture]?: File | null;
  [StreamFormFieldName.pictureUrl]?: string;
  [StreamFormFieldName.startTime]: string;
  [StreamFormFieldName.endTime]: string;
}
