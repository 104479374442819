import { Trainer } from 'interfaces';

import { appendToList, updateListItem } from 'shared';

import {
  APPEND_TRAINERS,
  INC_TRAINERS_CURRENT_PAGE,
  RESET_TRAINERS_CURRENT_PAGE,
  SET_ALL_TRAINERS,
  SET_CREATE_TRAINER_ERROR,
  SET_CREATE_TRAINER_LOADING,
  SET_CREATE_TRAINER_SUCCESS,
  SET_DELETE_TRAINER_ERROR,
  SET_DELETE_TRAINER_LOADING,
  SET_DELETE_TRAINER_SUCCESS,
  SET_GET_TRAINERS_ERROR,
  SET_GET_TRAINERS_LOADING,
  SET_TRAINERS,
  SET_TRAINERS_GET_PARAMS,
  SET_TRAINERS_LAST_PAGE_REACHED,
  SET_UPDATE_TRAINER_ERROR,
  SET_UPDATE_TRAINER_LOADING,
  SET_UPDATE_TRAINER_SUCCESS,
  TrainerActionTypes,
} from './actionTypes';
import { TrainerState } from './types';

const defaultState: TrainerState = {
  trainerList: [],
  getParams: {},
  allTrainers: [],
  isTrainerListLastPageReached: false,
  getTrainersError: undefined,
  getAllTrainersError: undefined,
  isCreateTrainerSuccess: false,
  isUpdateTrainerSuccess: false,
  isDeleteTrainerSuccess: false,
  createTrainerError: undefined,
  updateTrainerError: undefined,
  deleteTrainerError: undefined,
  isGetTrainersLoading: false,
  isGetAllTrainersLoading: false,
  isUpdateTrainerLoading: false,
  isCreateTrainerLoading: false,
  isDeleteTrainerLoading: false,
};

export default (state = defaultState, action: TrainerActionTypes): TrainerState => {
  switch (action.type) {
    case SET_TRAINERS:
      return {
        ...state,
        trainerList: action.payload,
      };

    case SET_TRAINERS_GET_PARAMS:
      return {
        ...state,
        getParams: action.payload,
      };

    case INC_TRAINERS_CURRENT_PAGE:
      return {
        ...state,
        getParams: { ...state.getParams, page: (state.getParams.page ?? 1) + 1 },
      };

    case RESET_TRAINERS_CURRENT_PAGE:
      return {
        ...state,
        isTrainerListLastPageReached: false,
        getParams: { ...state.getParams, page: 1 },
      };

    case SET_ALL_TRAINERS:
      return {
        ...state,
        allTrainers: action.payload,
      };

    case APPEND_TRAINERS:
      return {
        ...state,
        trainerList: appendToList(state.trainerList, action.payload) as Trainer[],
      };

    case SET_TRAINERS_LAST_PAGE_REACHED:
      return {
        ...state,
        isTrainerListLastPageReached: action.payload,
      };

    case SET_CREATE_TRAINER_SUCCESS:
      return {
        ...state,
        isCreateTrainerSuccess: action.payload,
        createTrainerError: undefined,
      };

    case SET_UPDATE_TRAINER_SUCCESS: {
      const trainer = action.payload.data;

      return {
        ...state,
        trainerList: trainer
          ? (updateListItem(state.trainerList, trainer) as Trainer[])
          : state.trainerList,
        isUpdateTrainerSuccess: action.payload.success,
        updateTrainerError: undefined,
      };
    }

    case SET_DELETE_TRAINER_SUCCESS:
      return {
        ...state,
        isDeleteTrainerSuccess: action.payload,
        deleteTrainerError: undefined,
      };

    case SET_GET_TRAINERS_ERROR:
      return {
        ...state,
        getTrainersError: action.payload,
      };

    case SET_CREATE_TRAINER_ERROR:
      return {
        ...state,
        createTrainerError: action.payload,
        isCreateTrainerSuccess: false,
      };

    case SET_UPDATE_TRAINER_ERROR:
      return {
        ...state,
        updateTrainerError: action.payload,
        isUpdateTrainerSuccess: false,
      };

    case SET_DELETE_TRAINER_ERROR:
      return {
        ...state,
        deleteTrainerError: action.payload,
        isDeleteTrainerSuccess: false,
      };

    case SET_GET_TRAINERS_LOADING:
      return {
        ...state,
        isGetTrainersLoading: action.payload,
      };

    case SET_CREATE_TRAINER_LOADING:
      return {
        ...state,
        isCreateTrainerLoading: action.payload,
      };

    case SET_UPDATE_TRAINER_LOADING:
      return {
        ...state,
        isUpdateTrainerLoading: action.payload,
      };

    case SET_DELETE_TRAINER_LOADING:
      return {
        ...state,
        isDeleteTrainerLoading: action.payload,
      };

    default:
      return state;
  }
};
