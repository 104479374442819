import i18nCountries from 'i18n-iso-countries';
import englishCountry from 'i18n-iso-countries/langs/en.json';
import hungaryCountry from 'i18n-iso-countries/langs/hu.json';
import i18n from 'i18next';
import i18next from "i18next";
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LOCALE } from 'shared';

import en from './translations/en.json';
import hu from './translations/hu.json';

const resources = {
  en: { translation: en },
  hu: { translation: hu },
};

const currentLang = i18next.language?.toUpperCase();

i18nCountries.registerLocale(englishCountry);
i18nCountries.registerLocale(hungaryCountry);

i18nCountries.registerLocale(currentLang === 'HU' ? hungaryCountry : englishCountry);

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LOCALE,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
