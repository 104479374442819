import React from 'react';

import { useTranslation } from 'react-i18next';

/**
 * Loading component.
 */
const Loading: React.FC = () => {
  const { t } = useTranslation();

  return <div>{t('common.please_wait')}</div>;
};

export default Loading;
