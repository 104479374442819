import React from 'react';

import countries from 'i18n-iso-countries';
import { TextValuePair } from 'interfaces';

import { Select } from '@components';

import { CountrySelectProps } from './types';

/**
 * Country Select component.
 * @param name - The select component name
 * @param value - The selected value.
 * @param placeholder - The placeholder text.
 * @param className - Custom classes.
 * @param onChange - A function what will be called when changed the selected value.
 */
const CountrySelect: React.FC<CountrySelectProps> = ({
  name,
  value,
  placeholder,
  className = '',
  onChange,
  ...rest
}) => {
  const countriesOptions: [string, string][] = Object.entries(
    countries.getNames('hu', { select: 'official' }),
  );

  const getTrainerListOptions: TextValuePair[] = [];

  countriesOptions.map((val: [string, string]) => {
    if (val.length) {
      getTrainerListOptions.push({
        text: val[1],
        value: val[0],
      });
    }
  });

  return (
    <Select
      {...rest}
      name={name}
      value={value}
      options={getTrainerListOptions}
      placeholder={placeholder}
      className={className}
      onChange={onChange}
    />
  );
};

export default CountrySelect;
