import React from 'react';

import Images from '../../utils/images';

import styles from './Image.module.scss';
import { ImageProps } from './types';

/**
 * Image component
 * @param src - Image url
 * @param alt - Image alt
 * @param fallbackSrc - Fallback image url
 * @param className - Custom classes
 */
const Image: React.FC<ImageProps> = ({ src, fallbackSrc, alt = '', className = '', ...rest }) => {
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [imageSrc, setImageSrc] = React.useState<string | undefined>(src);

  const defaultFallbackSrc = Images.IMAGE_PLACEHOLDER;

  React.useEffect(() => {
    setImageSrc(src && src.length ? src : defaultFallbackSrc);
  }, [defaultFallbackSrc, src]);

  const onError = () => {
    if (!hasError) {
      setImageSrc(fallbackSrc ? fallbackSrc : defaultFallbackSrc);
      setHasError(true);
    }
  };

  return (
    <img
      key={imageSrc}
      src={imageSrc}
      className={`mx-auto d-block p-0 ${styles.imageSize} ${className}`}
      alt={alt}
      onError={onError}
      {...rest}
    />
  );
};

export default Image;
