import { GetUsersParams } from 'api_client';
import { User } from 'interfaces';

export const SET_ADMINS = 'SET_ADMINS';
export const APPEND_ADMINS = 'APPEND_ADMINS';

export const SET_ADMINS_GET_PARAMS = 'SET_ADMINS_GET_PARAMS';
export const INC_ADMINS_CURRENT_PAGE = 'INC_ADMINS_CURRENT_PAGE';
export const RESET_ADMINS_CURRENT_PAGE = 'RESET_ADMINS_CURRENT_PAGE';
export const SET_ADMINS_LAST_PAGE_REACHED = 'SET_ADMINS_LAST_PAGE_REACHED';

export const SET_CREATE_ADMIN_SUCCESS = 'SET_CREATE_ADMIN_SUCCESS';
export const SET_UPDATE_ADMIN_SUCCESS = 'SET_UPDATE_ADMIN_SUCCESS';
export const SET_DELETE_ADMIN_SUCCESS = 'SET_DELETE_ADMIN_SUCCESS';

export const SET_GET_ADMINS_ERROR = 'SET_GET_ADMINS_ERROR';
export const SET_CREATE_ADMIN_ERROR = 'SET_CREATE_ADMIN_ERROR';
export const SET_UPDATE_ADMIN_ERROR = 'SET_UPDATE_ADMIN_ERROR';
export const SET_DELETE_ADMIN_ERROR = 'SET_DELETE_ADMIN_ERROR';

export const SET_GET_ADMINS_LOADING = 'SET_GET_ADMINS_LOADING';
export const SET_CREATE_ADMIN_LOADING = 'SET_CREATE_ADMIN_LOADING';
export const SET_UPDATE_ADMIN_LOADING = 'SET_UPDATE_ADMIN_LOADING';
export const SET_DELETE_ADMIN_LOADING = 'SET_DELETE_ADMIN_LOADING';

export interface SetAdmins {
  type: typeof SET_ADMINS;
  payload: User[];
}

export interface SetAdminsGetParams {
  type: typeof SET_ADMINS_GET_PARAMS;
  payload: GetUsersParams;
}

export interface IncAdminsCurrentPage {
  type: typeof INC_ADMINS_CURRENT_PAGE;
}

export interface ResetAdminsCurrentPage {
  type: typeof RESET_ADMINS_CURRENT_PAGE;
}

export interface AppendAdmins {
  type: typeof APPEND_ADMINS;
  payload: User[];
}

export interface SetAdminsLastPageReached {
  type: typeof SET_ADMINS_LAST_PAGE_REACHED;
  payload: boolean;
}

export interface SetCreateAdminSuccess {
  type: typeof SET_CREATE_ADMIN_SUCCESS;
  payload: boolean;
}

export interface SetUpdateAdminSuccess {
  type: typeof SET_UPDATE_ADMIN_SUCCESS;
  payload: {
    success: boolean;
    data?: User;
  };
}

export interface SetDeleteAdminSuccess {
  type: typeof SET_DELETE_ADMIN_SUCCESS;
  payload: boolean;
}

export interface SetGetAdminsError {
  type: typeof SET_GET_ADMINS_ERROR;
  payload?: string;
}

export interface SetCreateAdminError {
  type: typeof SET_CREATE_ADMIN_ERROR;
  payload?: string;
}

export interface SetUpdateAdminError {
  type: typeof SET_UPDATE_ADMIN_ERROR;
  payload?: string;
}

export interface SetDeleteAdminError {
  type: typeof SET_DELETE_ADMIN_ERROR;
  payload?: string;
}

export interface SetGetAdminsLoading {
  type: typeof SET_GET_ADMINS_LOADING;
  payload: boolean;
}

export interface SetCreateAdminLoading {
  type: typeof SET_CREATE_ADMIN_LOADING;
  payload: boolean;
}

export interface SetUpdateAdminLoading {
  type: typeof SET_UPDATE_ADMIN_LOADING;
  payload: boolean;
}

export interface SetDeleteAdminLoading {
  type: typeof SET_DELETE_ADMIN_LOADING;
  payload: boolean;
}

export type AdminActionTypes =
  | SetAdmins
  | SetAdminsGetParams
  | IncAdminsCurrentPage
  | ResetAdminsCurrentPage
  | AppendAdmins
  | SetAdminsLastPageReached
  | SetCreateAdminSuccess
  | SetUpdateAdminSuccess
  | SetDeleteAdminSuccess
  | SetGetAdminsError
  | SetCreateAdminError
  | SetUpdateAdminError
  | SetDeleteAdminError
  | SetGetAdminsLoading
  | SetCreateAdminLoading
  | SetUpdateAdminLoading
  | SetDeleteAdminLoading;
