import { AuthClient, ForgotPasswordRequest, LoginRequest, ResetPasswordRequest } from 'api_client';

import { User } from 'interfaces';
import { ThunkDispatch } from 'redux-thunk';
import { Action, Dispatch } from 'redux';

import { persistor, TAppState } from '../index';
import { AppThunk } from '../types';

import {
  SET_USER,
  LOGOUT,
  SET_ACCESS_TOKEN,
  SET_FORGOTTEN_PASSWORD_ERROR,
  SET_FORGOTTEN_PASSWORD_LOADING,
  SET_FORGOTTEN_PASSWORD_SUCCESS,
  SET_LOGIN_ERROR,
  SET_LOGIN_LOADING,
  SET_LOGIN_SUCCESS,
  SET_LOGOUT_ERROR,
  SET_LOGOUT_LOADING,
  SET_LOGOUT_SUCCESS,
  SET_REFRESH_TOKEN,
  SET_RESET_PASSWORD_ERROR,
  SET_RESET_PASSWORD_LOADING,
  SET_RESET_PASSWORD_SUCCESS,
} from './actionTypes';

export const setUser =
  (payload: { user: User }): AppThunk =>
  (dispatch: Dispatch): void => {
    dispatch({ type: SET_USER, payload });
  };

const setLoginSuccess = (payload: {
  accessToken: string;
  refreshToken: string;
  user: User;
}): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_LOGIN_SUCCESS, payload });
};

const setLogoutSuccess = (loading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_LOGOUT_SUCCESS, payload: loading });
};

export const setForgottenPasswordSuccess = (isSuccess: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_FORGOTTEN_PASSWORD_SUCCESS, payload: isSuccess });
};

export const setResetPasswordSuccess = (isSuccess: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_RESET_PASSWORD_SUCCESS, payload: isSuccess });
};

export const setLoginError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_LOGIN_ERROR, payload: error });
};

const setLogoutError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_LOGOUT_ERROR, payload: error });
};

export const setForgottenPasswordError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_FORGOTTEN_PASSWORD_ERROR, payload: error });
};

export const setResetPasswordError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_RESET_PASSWORD_ERROR, payload: error });
};

const setLoginLoading = (loading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_LOGIN_LOADING, payload: loading });
};

const setLogoutLoading = (loading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_LOGOUT_LOADING, payload: loading });
};

const setForgottenPasswordLoading = (loading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_FORGOTTEN_PASSWORD_LOADING, payload: loading });
};

const setResetPasswordLoading = (loading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_RESET_PASSWORD_LOADING, payload: loading });
};

export const setAccessToken = (accessToken?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_ACCESS_TOKEN, payload: accessToken });
};

export const setRefreshToken = (refreshToken?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: SET_REFRESH_TOKEN, payload: refreshToken });
};

export const logoutUser = (): AppThunk => (dispatch: Dispatch): void => {
  dispatch({ type: LOGOUT });
};

export const login = (credentials: LoginRequest): AppThunk => async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
  try {
    dispatch(setLoginLoading(true));

    const response = await AuthClient.adminLogin(credentials);

    const accessToken = response?.accessToken;
    const refreshToken = response?.refreshToken;
    const user = response?.user;

    if (user && accessToken && refreshToken) {
      dispatch(setLoginSuccess({ accessToken, refreshToken, user }));
    }
  } catch (error) {
    dispatch(setLoginError(error.message));
  } finally {
    dispatch(setLoginLoading(false));
  }
};

export const sendForgottenPassword = (data: ForgotPasswordRequest): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setForgottenPasswordLoading(true));

    await AuthClient.forgotPassword(data);

    dispatch(setForgottenPasswordSuccess(true));
  } catch (error) {
    dispatch(setForgottenPasswordError(error.message));
  } finally {
    dispatch(setForgottenPasswordLoading(false));
  }
};

export const resetPassword = (data: ResetPasswordRequest): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setResetPasswordLoading(true));

    await AuthClient.resetPassword(data);

    dispatch(setResetPasswordSuccess(true));
  } catch (error) {
    dispatch(setResetPasswordError(error.message));
  } finally {
    dispatch(setResetPasswordLoading(false));
  }
};

export const logout =
  (): AppThunk =>
  async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
    try {
      dispatch(setLogoutLoading(true));

      await persistor.purge();

      AuthClient.logout();

      dispatch(logoutUser());

      dispatch(setLogoutSuccess(true));
      location.reload();
    } catch (error) {
      dispatch(setLogoutError(error.message));
    } finally {
      dispatch(setLogoutLoading(false));
    }
  };
