import React from 'react';

import { format } from 'date-fns';
import { Stream } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DATE_TIME_FORMAT, useFormValidation } from 'shared';
import { Modal, ModalRow } from 'web-components';

import { DateSelector, FormControl, TextArea, Select, TextInput, ImageInput } from '@components';

import { AppState } from '../../../store/types';
import Images from '../../../utils/images';
import StreamSchema from '../../../utils/validation/StreamSchema';

import { TrainerFormFieldName } from '../../TrainerListScreen/TrainerFormModal/types';

import styles from './StreamFormModal.module.scss';
import { StreamFormData, StreamFormFieldName, StreamFormModalProps } from './types';

const imagePlaceholder = Images.UPLOAD_IMAGE_PLACEHOLDER;

const formData = (data?: Stream) => {
  let trainer;
  if (data && data.trainer) {
    trainer = typeof data.trainer === 'string' ? data.trainer : data.trainer._id;
  }

  return {
    name: data?.name || '',
    trainer: trainer || '',
    url: data?.url || '',
    description: data?.description || undefined,
    startTime: data?.startTime || '',
    endTime: data?.endTime || '',
    picture: undefined,
    pictureUrl: !data?.pictures
      ? imagePlaceholder
      : `${data?.pictures.coverThumbnail}?${data?.pictures.hash}`,
  };
};

/**
 * Stream form modal to add or edit a user.
 * @param data - The stream's data.
 * @param trainerOptions - Trainer options.
 * @param show - If 'true', the modal is displayed.
 * @param onSave - A function what will be called when the save button was pressed.
 * @param onCancel - A function what will be called when the cancel button was pressed.
 */
const StreamFormModal: React.FC<StreamFormModalProps> = ({
  data,
  trainerOptions = [],
  isTrainersLoading,
  onSave,
  onCancel,
  onError,
  ...rest
}) => {
  const { t } = useTranslation();

  const [form, setForm] = React.useState<StreamFormData>(formData(data));
  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const { isUpdateStreamLoading, isCreateStreamLoading } = useSelector(
    (state: AppState) => state.stream,
  );
  const { validationErrors } = useFormValidation<StreamFormData>(StreamSchema, form);

  React.useEffect(() => {
    setIsFormSubmitted(false);
    setForm(formData(data));
  }, [rest.show, data]);

  React.useEffect(() => {
    const errorMessage = validationErrors[Object.keys(validationErrors)[0]];

    onError(errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationErrors]);

  React.useEffect(() => {
    if (isFormSubmitted) {
      onSave(form, data?._id);

      setIsFormSubmitted(false);
    }
  }, [isFormSubmitted, onSave, form, data?._id]);

  React.useEffect(() => {
    setIsSaving(isCreateStreamLoading || isUpdateStreamLoading);
  }, [isCreateStreamLoading, isUpdateStreamLoading]);

  const onFieldChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ): void => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const onDateFieldChange = (date: Date, field: string): void => {
    setForm({ ...form, [field]: date.toISOString() });
  };

  const onSaveButtonClicked = () => {
    setIsFormSubmitted(true);
  };

  const onCancelButtonClicked = () => {
    setIsFormSubmitted(false);

    onCancel();
  };

  return (
    <Modal
      {...rest}
      title={t(data ? 'stream_form_modal.edit_stream' : 'stream_form_modal.new_stream')}
      actionButtons={[
        {
          text: t('common.rejection'),
          onClick: onCancelButtonClicked,
          classes: ['bg-rejection', 'text-primary', 'font-weight-bold', styles.button],
        },
        {
          text: isSaving ? t('common.saving') : t('common.save'),
          arguments: isSaving ? [{ name: 'disabled', value: true }] : [],
          onClick: (): void => onSaveButtonClicked(),
          classes: ['bg-secondary', 'text-primary', 'font-weight-bold', styles.button],
        },
      ]}
    >
      <form className="text-primary" noValidate>
        <div className="row py-3">
          <div className="col-6 mb-3">
            <ImageInput
              url={form.pictureUrl ?? ''}
              alt={t('trainer_details_modal.image_alt')}
              name={TrainerFormFieldName.picture}
              type="small"
              onChange={(file?: File | null): void => {
                setForm({
                  ...form,
                  pictureUrl: file ? URL.createObjectURL(file) : imagePlaceholder,
                  picture: file,
                });
              }}
              onDelete={(): void => {
                setForm({ ...form, pictureUrl: imagePlaceholder, picture: null });
              }}
            />
          </div>

          <div className="col-6">
            <label>{t('stream_form_modal.stream_name')}</label>
            <FormControl className="m-0">
              <TextInput
                name={StreamFormFieldName.name}
                value={form.name}
                onChange={onFieldChange}
              />
            </FormControl>

            <label>
              {isTrainersLoading ? t('common.please_wait') : t('stream_form_modal.trainer_name')}
            </label>
            <FormControl className="m-0">
              <Select
                name={StreamFormFieldName.trainer}
                disabled={isTrainersLoading}
                options={trainerOptions}
                value={form.trainer}
                onChange={onFieldChange}
              />
            </FormControl>
          </div>
          <div className="col-12">
            <ModalRow
              items={[
                <div key="1">
                  <label>{t('stream_form_modal.start_time')}</label>
                  <FormControl className="m-0">
                    <DateSelector
                      name={StreamFormFieldName.startTime}
                      showTimeSelect
                      value={
                        form.startTime ? format(new Date(form.startTime), DATE_TIME_FORMAT) : ''
                      }
                      selected={form.startTime ? new Date(form.startTime) : null} // TODO: it doesn't work (value attr is not needed if this works)
                      onChange={(date: Date) =>
                        onDateFieldChange(date, StreamFormFieldName.startTime)
                      }
                    />
                  </FormControl>
                </div>,
                <div key="2">
                  <label>{t('stream_form_modal.end_time')}</label>
                  <FormControl className="m-0">
                    <DateSelector
                      name={StreamFormFieldName.endTime}
                      showTimeSelect
                      value={form.endTime ? format(new Date(form.endTime), DATE_TIME_FORMAT) : ''}
                      selected={form.endTime ? new Date(form.endTime) : null} // TODO: it doesn't work (value attr is not needed if this works)
                      onChange={(date: Date) =>
                        onDateFieldChange(date, StreamFormFieldName.endTime)
                      }
                    />
                  </FormControl>
                </div>,
              ]}
            />

            <ModalRow
              items={[
                <div key="3">
                  <label>{t('stream_form_modal.vimeo_url')}</label>
                  <FormControl className="m-0">
                    <TextInput
                      name={StreamFormFieldName.url}
                      value={form.url}
                      onChange={onFieldChange}
                    />
                  </FormControl>
                </div>,
                <div key="4">
                  <label>{t('stream_form_modal.description')}</label>
                  <FormControl className="m-0">
                    <TextArea
                      rows={1}
                      value={form.description}
                      name={StreamFormFieldName.description}
                      onChange={onFieldChange}
                    />
                  </FormControl>
                </div>,
              ]}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default StreamFormModal;
