import { User, UserRelationships, UserRole } from 'interfaces';

import { appendToList, updateListItem } from 'shared';

import {
  APPEND_USERS,
  INC_USERS_CURRENT_PAGE,
  RESET_USERS_CURRENT_PAGE,
  SET_CANCEL_SUBSCRIPTION_ERROR,
  SET_CANCEL_SUBSCRIPTION_LOADING,
  SET_CANCEL_SUBSCRIPTION_SUCCESS,
  SET_DISABLE_PROMOCODE_SUCCESS,
  SET_CREATE_USER_ERROR,
  SET_CREATE_USER_LOADING,
  SET_CREATE_USER_SUCCESS,
  SET_GET_USERS_ERROR,
  SET_GET_USERS_LOADING,
  SET_UPDATE_USER_ERROR,
  SET_DISABLE_PROMOCODE_ERROR,
  SET_UPDATE_USER_LOADING,
  SET_UPDATE_USER_STATUS_ERROR,
  SET_UPDATE_USER_STATUS_LOADING,
  SET_UPDATE_USER_STATUS_SUCCESS,
  SET_UPDATE_USER_SUCCESS,
  SET_USERS,
  SET_USERS_GET_PARAMS,
  SET_USERS_LAST_PAGE_REACHED,
  SET_DISABLE_PROMOCODE_LOADING,
  UserActionTypes,
} from './actionTypes';
import { UserState } from './types';

const defaultState: UserState = {
  userList: [],
  getParams: {
    include: [UserRelationships.Subscription],
    filter: { role: UserRole.USER },
  },
  isUserListLastPageReached: false,
  isCreateUserSuccess: false,
  isUpdateUserSuccess: false,
  isUpdateUserStatusSuccess: false,
  isCancelSubscriptionSuccess: false,
  getUsersError: undefined,
  createUserError: undefined,
  updateUserError: undefined,
  updateUserStatusError: undefined,
  cancelSubscriptionError: undefined,
  isGetUsersLoading: false,
  isCreateUserLoading: false,
  isUpdateUserLoading: false,
  isUpdateUserStatusLoading: false,
  isCancelSubscriptionLoading: false,
  isDisablePromoCodeLoading: false,
  isDisablePromoCodeSuccess: false,
  isDisablePromoCodeError: undefined,
};

export default (state = defaultState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        userList: action.payload,
      };

    case SET_USERS_GET_PARAMS:
      return {
        ...state,
        getParams: action.payload,
      };

    case INC_USERS_CURRENT_PAGE:
      return {
        ...state,
        getParams: { ...state.getParams, page: (state.getParams.page ?? 1) + 1 },
      };

    case RESET_USERS_CURRENT_PAGE:
      return {
        ...state,
        isUserListLastPageReached: false,
        getParams: { ...state.getParams, page: 1 },
      };

    case APPEND_USERS:
      return {
        ...state,
        userList: appendToList(state.userList, action.payload) as User[],
      };

    case SET_USERS_LAST_PAGE_REACHED:
      return {
        ...state,
        isUserListLastPageReached: action.payload,
      };

    case SET_CREATE_USER_SUCCESS:
      return {
        ...state,
        isCreateUserSuccess: action.payload,
        createUserError: undefined,
      };

    case SET_UPDATE_USER_SUCCESS: {
      const user = action.payload.data;

      return {
        ...state,
        userList: user ? (updateListItem(state.userList, user) as User[]) : state.userList,
        isUpdateUserSuccess: action.payload.success,
        updateUserError: undefined,
      };
    }

    case SET_UPDATE_USER_STATUS_SUCCESS: {
      const user = action.payload.data;

      return {
        ...state,
        userList: user ? (updateListItem(state.userList, user) as User[]) : state.userList,
        isUpdateUserStatusSuccess: action.payload.success,
        updateUserStatusError: undefined,
      };
    }

    case SET_CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isCancelSubscriptionSuccess: action.payload,
        cancelSubscriptionError: undefined,
      };

    case SET_GET_USERS_ERROR:
      return {
        ...state,
        getUsersError: action.payload,
      };

    case SET_CREATE_USER_ERROR:
      return {
        ...state,
        createUserError: action.payload,
        isCreateUserSuccess: false,
      };

    case SET_UPDATE_USER_ERROR:
      return {
        ...state,
        updateUserError: action.payload,
        isUpdateUserSuccess: false,
      };

    case SET_UPDATE_USER_STATUS_ERROR:
      return {
        ...state,
        updateUserStatusError: action.payload,
        isUpdateUserStatusSuccess: false,
      };

    case SET_CANCEL_SUBSCRIPTION_ERROR:
      return {
        ...state,
        cancelSubscriptionError: action.payload,
        isCancelSubscriptionSuccess: false,
      };

    case SET_GET_USERS_LOADING:
      return {
        ...state,
        isGetUsersLoading: action.payload,
      };

    case SET_CREATE_USER_LOADING:
      return {
        ...state,
        isCreateUserLoading: action.payload,
      };

    case SET_UPDATE_USER_LOADING:
      return {
        ...state,
        isUpdateUserLoading: action.payload,
      };

    case SET_UPDATE_USER_STATUS_LOADING:
      return {
        ...state,
        isUpdateUserStatusLoading: action.payload,
      };

    case SET_CANCEL_SUBSCRIPTION_LOADING:
      return {
        ...state,
        isCancelSubscriptionLoading: action.payload,
      };

    case SET_DISABLE_PROMOCODE_LOADING:
      return {
        ...state,
        isDisablePromoCodeLoading: action.payload,
      }

    case SET_DISABLE_PROMOCODE_SUCCESS:{
      const user = action.payload.data;
      return {
        ...state,
        isDisablePromoCodeSuccess: action.payload.isSuccess,
        userList: user ? (updateListItem(state.userList, user) as User[]) : state.userList,
      };
    }

    case SET_DISABLE_PROMOCODE_ERROR: 
      return {
        ...state,
        isDisablePromoCodeError: action.payload
      }

    default:
      return state;
  }
};
