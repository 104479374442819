import React from 'react';

import { Video } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFormValidation } from 'shared';

import { Modal, ModalRow } from 'web-components';

import { TextInput, FormControl, Switch, ImageInput } from '@components';

import { AppState } from '../../../store/types';
import Images from '../../../utils/images';
import ArchiveSchema from '../../../utils/validation/ArchiveSchema';
import { TrainerFormFieldName } from '../../TrainerListScreen/TrainerFormModal/types';

import styles from './ArchiveFormModal.module.scss';
import { ArchiveFormData, ArchiveFormFieldName, ArchiveFormModalProps } from './types';

const imagePlaceholder = Images.UPLOAD_IMAGE_PLACEHOLDER;

const formData = (data?: Video) => ({
  name: data?.name || '',
  isPublished: data?.isPublished || false,
  file: undefined,
  pictureUrl: !data?.pictures ? imagePlaceholder : `${data?.pictures.cover}?${data?.pictures.hash}`,
  picture: undefined,
});

/**
 * Archive form modal to add or edit a user.
 * @param data - The archive's data.
 * @param show - If 'true', the modal is displayed.
 * @param onSave - A function what will be called when the save button was pressed.
 * @param onCancel - A function what will be called when the cancel button was pressed.
 */
const ArchiveFormModal: React.FC<ArchiveFormModalProps> = ({
  data,
  onSave,
  onCancel,
  onError,
  ...rest
}) => {
  const { t } = useTranslation();

  const [form, setForm] = React.useState<ArchiveFormData>(formData(data));
  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const { isUpdateVideoLoading } = useSelector((state: AppState) => state.archive);
  const { validationErrors } = useFormValidation<ArchiveFormData>(ArchiveSchema, form);

  React.useEffect(() => {
    setIsFormSubmitted(false);
    setForm(formData(data));
  }, [rest.show, data]);

  React.useEffect(() => {
    const errorMessage = validationErrors[Object.keys(validationErrors)[0]];

    onError(errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationErrors]);

  React.useEffect(() => {
    if (isFormSubmitted) {
      onSave(form, data?._id);

      setIsFormSubmitted(false);
    }
  }, [isFormSubmitted, onSave, form, data?._id]);

  React.useEffect(() => {
    setIsSaving(isUpdateVideoLoading);
  }, [isUpdateVideoLoading]);

  const onFieldChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onSaveButtonClicked = () => {
    setIsFormSubmitted(true);
  };

  const onCancelButtonClicked = () => {
    setIsFormSubmitted(false);

    onCancel();
  };

  return (
    <Modal
      {...rest}
      title={t('archive_form_modal.edit_video')}
      actionButtons={[
        {
          text: t('common.rejection'),
          onClick: onCancelButtonClicked,
          classes: ['bg-rejection', 'text-primary', 'font-weight-bold', styles.button],
        },
        {
          text: isSaving ? t('common.saving') : t('common.save'),
          arguments: isSaving ? [{ name: 'disabled', value: true }] : [],
          onClick: (): void => onSaveButtonClicked(),
          classes: ['bg-secondary', 'text-primary', 'font-weight-bold', styles.button],
        },
      ]}
    >
      <form className="text-primary" noValidate>
        <ModalRow
          items={[
            <>
              <div className="pb-2">{t('archive_form_modal.cover_image')}</div>
              <ImageInput
                key="1"
                url={form.pictureUrl ?? ''}
                alt={t('trainer_details_modal.image_alt')}
                name={TrainerFormFieldName.picture}
                type="default"
                onChange={(file?: File | null): void => {
                  setForm({
                    ...form,
                    pictureUrl: file ? URL.createObjectURL(file) : '',
                    picture: file,
                  });
                }}
                onDelete={(): void =>
                  setForm({ ...form, pictureUrl: imagePlaceholder, picture: null })
                }
                className="mb-4"
              />
            </>,
          ]}
        />
        <ModalRow
          items={[
            <>
              <label>{t('archive_form_modal.name')}</label>
              <FormControl key="1">
                <TextInput
                  name={ArchiveFormFieldName.name}
                  value={form.name}
                  onChange={onFieldChange}
                />
              </FormControl>
            </>,
            <FormControl key="2">
              <>
                <label>{t('archive_details_modal.status')}</label>
                <Switch
                  label={
                    form.isPublished
                      ? t('archive_details_modal.status_published')
                      : t('archive_details_modal.status_unpublished')
                  }
                  name={ArchiveFormFieldName.isPublished}
                  checked={form.isPublished}
                  onChange={(name: string, value: boolean): void => {
                    setForm({ ...form, [name]: value });
                  }}
                />
              </>
            </FormControl>,
          ]}
        />
      </form>
    </Modal>
  );
};

export default ArchiveFormModal;
