import React from 'react';

import { ErrorProps } from './types';

/**
 * General error component
 * @param error - The error message.
 */
const Error: React.FC<ErrorProps> = ({ error }) => {
  return <div className="mt-2 px-2 text-danger">{error}</div>;
};

export default Error;
