import { GetVideosParams } from 'api_client';
import { Video } from 'interfaces';

export const SET_VIDEOS = 'SET_VIDEOS';
export const APPEND_VIDEOS = 'APPEND_VIDEOS';

export const SET_VIDEOS_GET_PARAMS = 'SET_VIDEOS_GET_PARAMS';
export const INC_VIDEOS_CURRENT_PAGE = 'INC_VIDEOS_CURRENT_PAGE';
export const RESET_VIDEOS_CURRENT_PAGE = 'RESET_VIDEOS_CURRENT_PAGE';
export const SET_VIDEOS_LAST_PAGE_REACHED = 'SET_VIDEOS_LAST_PAGE_REACHED';

export const SET_UPDATE_VIDEO_SUCCESS = 'SET_UPDATE_VIDEO_SUCCESS';
export const SET_UPDATE_VIDEO_STATUS_SUCCESS = 'SET_UPDATE_VIDEO_STATUS_SUCCESS';

export const SET_GET_VIDEOS_ERROR = 'SET_GET_VIDEOS_ERROR';
export const SET_UPDATE_VIDEO_ERROR = 'SET_UPDATE_VIDEO_ERROR';
export const SET_UPDATE_VIDEO_STATUS_ERROR = 'SET_UPDATE_VIDEO_STATUS_ERROR';

export const SET_GET_VIDEOS_LOADING = 'SET_GET_VIDEOS_LOADING';
export const SET_UPDATE_VIDEO_LOADING = 'SET_UPDATE_VIDEO_LOADING';
export const SET_UPDATE_VIDEO_STATUS_LOADING = 'SET_UPDATE_VIDEO_STATUS_LOADING';

export interface SetVideos {
  type: typeof SET_VIDEOS;
  payload: Video[];
}

export interface SetVideosGetParams {
  type: typeof SET_VIDEOS_GET_PARAMS;
  payload: GetVideosParams;
}

export interface IncVideosCurrentPage {
  type: typeof INC_VIDEOS_CURRENT_PAGE;
}

export interface ResetVideosCurrentPage {
  type: typeof RESET_VIDEOS_CURRENT_PAGE;
}

export interface AppendVideos {
  type: typeof APPEND_VIDEOS;
  payload: Video[];
}

export interface SetVideosLastPageReached {
  type: typeof SET_VIDEOS_LAST_PAGE_REACHED;
  payload: boolean;
}

export interface SetUpdateVideoSuccess {
  type: typeof SET_UPDATE_VIDEO_SUCCESS;
  payload: {
    success: boolean;
    data?: Video;
  };
}

export interface SetUpdateVideoStatusSuccess {
  type: typeof SET_UPDATE_VIDEO_STATUS_SUCCESS;
  payload: {
    success: boolean;
    data?: Video;
  };
}

export interface SetGetVideosError {
  type: typeof SET_GET_VIDEOS_ERROR;
  payload?: string;
}

export interface SetUpdateVideoError {
  type: typeof SET_UPDATE_VIDEO_ERROR;
  payload?: string;
}

export interface SetUpdateVideoStatusError {
  type: typeof SET_UPDATE_VIDEO_STATUS_ERROR;
  payload?: string;
}

export interface SetGetVideosLoading {
  type: typeof SET_GET_VIDEOS_LOADING;
  payload: boolean;
}

export interface SetUpdateVideoLoading {
  type: typeof SET_UPDATE_VIDEO_LOADING;
  payload: boolean;
}

export interface SetUpdateVideoStatusLoading {
  type: typeof SET_UPDATE_VIDEO_STATUS_LOADING;
  payload: boolean;
}

export type ArchiveActionTypes =
  | SetVideos
  | SetVideosGetParams
  | ResetVideosCurrentPage
  | IncVideosCurrentPage
  | AppendVideos
  | SetVideosLastPageReached
  | SetUpdateVideoSuccess
  | SetUpdateVideoStatusSuccess
  | SetGetVideosError
  | SetUpdateVideoError
  | SetUpdateVideoStatusError
  | SetGetVideosLoading
  | SetUpdateVideoLoading
  | SetUpdateVideoStatusLoading;
