import React from 'react';

import styles from './TextArea.module.scss';
import { TextAreaProps } from './types';

/**
 * Textarea component.
 * @param type - The type of the textarea.
 * @param value - The value.
 * @param name - The name.
 * @param placeholder - The placeholder text.
 * @param className - Custom classes.
 * @param onChange - A function what will be called when the value was changed.
 */
const TextArea: React.FC<TextAreaProps> = ({ className, ...rest }) => {
  return (
    <textarea
      {...rest}
      className={`form-control text-dark border border-dark shadow-none ${styles.textArea} ${className}`}
    />
  );
};

export default TextArea;
