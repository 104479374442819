import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware, compose, CombinedState } from 'redux';

import adminReducer from './admin/reducers';
import archiveReducer from './archive/reducers';
import { LOGOUT } from './auth/actionTypes';
import authReducer from './auth/reducers';
import persistConfig, { authPersistConfig } from './persistConfig';
import promoCodeReducer from './promo-code/reducers';
import streamReducer from './stream/reducers';
import trainerReducer from './trainer/reducers';
import { AppActions } from './types';
import userReducer from './user/reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const appReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  stream: streamReducer,
  trainer: trainerReducer,
  promoCode: promoCodeReducer,
  user: userReducer,
  admin: adminReducer,
  archive: archiveReducer,
});

export type TAppState = ReturnType<typeof appReducer>;

export const rootReducer = (
  state: TAppState | undefined,
  action: AppActions,
): CombinedState<TAppState> => {
  return appReducer(action.type === LOGOUT ? undefined : state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(thunk))
    : applyMiddleware(thunk),
);

export const persistor = persistStore(store);
