import React from 'react';

import styles from './TextInput.module.scss';
import { TextInputProps } from './types';

/**
 * Text input component.
 * @param type - The type of the input.
 * @param value - The value.
 * @param name - The name.
 * @param placeholder - The placeholder text.
 * @param className - Custom classes.
 * @param onChange - A function what will be called when the value was changed.
 */
const TextInput: React.FC<TextInputProps> = ({ type = 'text', className = '', ...rest }) => {
  return (
    <input
      {...rest}
      type={type}
      className={`form-control text-dark border border-dark shadow-none ${styles.input} ${className}`}
    />
  );
};

export default TextInput;
