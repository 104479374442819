import React from 'react';

import { createPortal } from 'react-dom';

import Button from '../Button';

import { ModalProps } from './types';

const modal = document.querySelector('#modal-root');

/**
 * General modal component
 * @param actionButtons
 * @param title
 * @param show
 * @param children
 * @constructor
 */
const Modal: React.FC<ModalProps> = ({ actionButtons, title, show = false, children }) => {
  if (!show) {
    return null;
  }

  return createPortal(
    <div>
      <div className="modal fade show d-block" tabIndex={-1} role="dialog" aria-hidden={true}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content p-3">
            <div className="modal-header border-0">
              <h4 className="modal-title text-uppercase">{title}</h4>
            </div>

            <div className="modal-body">{children}</div>

            <div className="modal-footer border-0">
              {actionButtons.map((button, index) => (
                <Button
                  onClick={button.onClick}
                  key={index}
                  className={button.classes ? button.classes.join(' ') : ''}
                  {...(button.arguments
                    ? button.arguments.reduce((prev: { [key: string]: Record<string, unknown> }, curr) => {
                        prev[curr.name] = curr.value;

                        return prev;
                      }, {})
                    : [])}
                >
                  {button.text}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show" />
    </div>,
    modal as Element,
  );
};

export default Modal;
