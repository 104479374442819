import React from 'react';

import { Trainer } from 'interfaces';
import { useTranslation } from 'react-i18next';

import { getFormattedDate, DATE_TIME_FORMAT } from 'shared';

import { Modal, ModalRow } from 'web-components';

import { StreamDetailsModalProps } from './types';

/**
 * Stream details modal component.
 * @param data - The stream data.
 * @param show - If 'true', the modal is displayed.
 * @param onCancel - A function what will be called when the cancel button was pressed.
 */
const StreamDetailsModal: React.FC<StreamDetailsModalProps> = ({ data, onCancel, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Modal
      {...rest}
      title={t('stream_details_modal.title')}
      actionButtons={[
        {
          text: t('stream_details_modal.back'),
          classes: ['bg-secondary', 'text-primary', 'font-weight-bold'],
          onClick: onCancel,
        },
      ]}
    >
      <div className="text-primary">
        <ModalRow
          items={[
            <div key="1">
              <div>{t('stream_details_modal.stream_name')}</div>
              <div className="font-weight-bold">{data.name}</div>
            </div>,
            <div key="2">
              <div>{t('stream_details_modal.trainer_name')}</div>
              <div className="font-weight-bold">{`${(data.trainer as Trainer).lastName} ${
                (data.trainer as Trainer).firstName
              }`}</div>
            </div>,
          ]}
          className="p-2"
        />
        <ModalRow
          items={[
            <div key="3">
              <div>{t('stream_details_modal.vimeo_url')}</div>
              <div className="font-weight-bold">
                <a href={data.url} target="_blank" rel="noreferrer">
                  {data.url}
                </a>
              </div>
            </div>,
            <div key="4">
              <div>{t('stream_details_modal.start_time')}</div>
              <div className="font-weight-bold">
                {data?.startTime && getFormattedDate(data?.startTime, DATE_TIME_FORMAT)}
              </div>
            </div>,
          ]}
          className="p-2"
        />
        <ModalRow
          items={[
            <div key="5">
              <div>{t('stream_details_modal.description')}</div>
              <div className="font-weight-bold">{data.description}</div>
            </div>,
            <div key="6">
              <div>{t('stream_details_modal.end_time')}</div>
              <div className="font-weight-bold">
                {data?.endTime && getFormattedDate(data?.endTime, DATE_TIME_FORMAT)}
              </div>
            </div>,
          ]}
          className="p-2"
        />
      </div>
    </Modal>
  );
};

export default StreamDetailsModal;
