import React from 'react';

import { TextValuePair, UserRole } from 'interfaces';

import { useTranslation } from "react-i18next";

import { Select } from '@components';

import styles from './RoleSelect.module.scss';
import { RoleSelectProps } from './types';

/**
 * Role Select component.
 * @param name - The select component name
 * @param value - The selected value.
 * @param placeholder - The placeholder text.
 * @param className - Custom classes.
 * @param onChange - A function what will be called when changed the selected value.
 * @param multiple - Multiselect true | false
 * @param placeholderDisable
 * @param rest
 * @param onChange - A function what will be called when changed the selected value.
 */
const RoleSelect: React.FC<RoleSelectProps> = ({
  name,
  value,
  placeholder,
  className = '',
  onChange,
  multiple,
  placeholderDisable,
  ...rest
}) => {
  const { t } = useTranslation();

  const getRoleOptions: TextValuePair[] = Object.keys(UserRole).map((val: string) => {
    return ({
      text: t(`user_form_modal.role_${val.toLocaleLowerCase()}`),
      value: val,
    });
  });

  return (
    <div className={styles.roleSelect}>
      <Select
        {...rest}
        name={name}
        value={value}
        multiple={multiple}
        options={getRoleOptions}
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        placeholderDisable={placeholderDisable}
      />
    </div>
  );
};

export default RoleSelect;
