import React from 'react';

import { Link as RLink } from 'react-router-dom';

import styles from './Link.module.scss';
import { LinkProps } from './types';

/**
 * Navigation link component.
 * @param to - The route where the app navigates when pressed the link.
 * @param type - The type of the link.
 * @param children - The label.
 * @param className - Custom classes.
 * @param onClick
 */
const Link: React.FC<LinkProps> = ({ children, type = 'normal', className = '', to, onClick }) => {
  return (
    <RLink
      to={to}
      className={`${styles.link} ${type === 'small' ? styles.small : ''} ${className}`}
      onClick={onClick}
    >
      {children}
    </RLink>
  );
};

export default Link;
