import React from 'react';

import { User } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFormValidation } from 'shared';

import { Modal, ModalRow } from 'web-components';

import { FormControl, TextInput, RoleSelect } from '@components';

import { AppState } from '../../../store/types';
import AdminSchema from '../../../utils/validation/AdminSchema';
import { UserFormFieldName } from '../../UserListScreen/UserFormModal/types';

import styles from './AdminFormModal.module.scss';
import { AdminFormData, AdminFormProps } from './types';

const formData = (data?: User) => {
  return {
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    email: data?.email || '',
    roles: data?.roles || [],
  };
};

/**
 * admin form modal to edit an admin user.
 * @param data - The admin's data.
 */
const AdminFormModal: React.FC<AdminFormProps> = ({ data, onCancel, onSave, onError, ...rest }) => {
  const { t } = useTranslation();

  const [form, setForm] = React.useState<AdminFormData>(formData(data));
  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const { isUpdateAdminLoading, isCreateAdminLoading } = useSelector(
    (state: AppState) => state.admin,
  );
  const { validationErrors } = useFormValidation<AdminFormData>(AdminSchema, form);

  React.useEffect(() => {
    setIsFormSubmitted(false);
    setForm(formData(data));
  }, [rest.show, data]);

  React.useEffect(() => {
    const errorMessage = validationErrors[Object.keys(validationErrors)[0]];

    onError(errorMessage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationErrors]);

  React.useEffect(() => {
    if (isFormSubmitted) {
      onSave(form, data?._id);

      setIsFormSubmitted(false);
    }
  }, [isFormSubmitted, onSave, form, data?._id]);

  React.useEffect(() => {
    setIsSaving(isCreateAdminLoading || isUpdateAdminLoading);
  }, [isCreateAdminLoading, isUpdateAdminLoading]);

  const onFieldChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onFieldChangeRoles = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ): void => {
    const { value } = e.target;

    if (!form?.roles.filter((role) => role === value).length && value) {
      setForm({...form, roles: [...form.roles, value] as unknown as []})
    } else {
      setForm({...form, roles: form?.roles.filter((item) => item !== value) as []})
    }
  };

  const onSaveButtonClicked = () => {
    setIsFormSubmitted(true);
  };

  const onCancelButtonClicked = () => {
    setIsFormSubmitted(false);

    onCancel();
  };

  return (
    <Modal
      {...rest}
      title={t(data ? 'admin_form_modal.edit_admin' : 'admin_form_modal.new_admin')}
      actionButtons={[
        {
          text: t('common.cancel'),
          onClick: onCancelButtonClicked,
          classes: ['bg-rejection', 'font-weight-bold', styles.button],
        },
        {
          text: isSaving ? t('common.saving') : t('common.save'),
          arguments: isSaving ? [{ name: 'disabled', value: true }] : [],
          onClick: (): void => onSaveButtonClicked(),
          classes: ['bg-secondary', 'font-weight-bold', styles.button],
        },
      ]}
    >
      <form className="text-primary" noValidate>
        <ModalRow
          items={[
            <>
              <label>{t('admin_form_modal.email')}</label>
              <FormControl key="1">
                <TextInput
                  type="email"
                  name={UserFormFieldName.email}
                  value={form.email}
                  onChange={onFieldChange}
                />
              </FormControl>
            </>,
          ]}
        />

        <ModalRow
          items={[
            <>
              <label>{t('admin_form_modal.lastname')}</label>
              <FormControl key="1">
                <TextInput
                  name={UserFormFieldName.lastName}
                  value={form.lastName}
                  onChange={onFieldChange}
                />
              </FormControl>
            </>,
            <>
              <label>{t('admin_form_modal.firstname')}</label>
              <FormControl key="2">
                <TextInput
                  name={UserFormFieldName.firstName}
                  value={form.firstName}
                  onChange={onFieldChange}
                />
              </FormControl>
            </>,
          ]}
        />
        <ModalRow
          items={[
            <>
              <label>{t('user_form_modal.roles')}</label>
              <RoleSelect
                name={UserFormFieldName.roles}
                multiple
                placeholderDisable
                value={form.roles as []}
                className={styles.country}
                onChange={onFieldChangeRoles}
              />
            </>,
            <></>
          ]}
        />
      </form>
    </Modal>
  );
};

export default AdminFormModal;
