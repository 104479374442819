import React from 'react';

import styles from './Switch.module.scss';
import { SwitchProps } from './types';

/**
 * Switch component.
 * @param name - The name.
 * @param label - The label of the checkbox.
 * @param checked - If 'true', the switch is checked.
 * @param disabled - If 'true', the switch is disabled.
 * @param onChange - A function what will be called when changed the checked state.
 * @param className - Custom classes.
 */
const Switch: React.FC<SwitchProps> = ({
  name,
  label,
  checked,
  disabled = false,
  onChange,
  className = '',
}) => {
  const [isChecked, setIsChecked] = React.useState<boolean>(checked);

  React.useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <div className={`custom-control custom-switch ${styles.switchContainer} ${className}`}>
      <label className={`custom-control-label ${styles.label} ${isChecked ? styles.checked : ''}`}>
        <input
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          className="custom-control-input"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setIsChecked(e.target.checked);
            onChange(name, e.target.checked);
          }}
        />
        {label}
      </label>
    </div>
  );
};

export default Switch;
