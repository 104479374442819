import React from 'react';

import style from './RadioButton.module.scss';
import { RadioButtonProps } from './type';

/**
 * Radio button component.
 * @param label - The label text.
 * @param name - The name.
 * @param value - The value.
 * @param checked - If 'true', the radio button is checked.
 * @param className - Custom classes.
 * @param onChange - A function what will be called when the checked prop was changed.
 */
const RadioButton: React.FC<RadioButtonProps> = ({ label, checked, className = '', ...rest }) => {
  return (
    <div className={`form-check pl-4 ${className}`}>
      <label className={`form-check-label ${style.label}`}>
        <input
          {...rest}
          type="radio"
          checked={checked}
          className={`form-check-input ${style.input}`}
        />
        <span className={`form-check-input ${style.checkmark} ${checked ? style.checked : ''}`} />
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
