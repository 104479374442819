import { Trainer } from 'interfaces';

export interface TrainerFormModalProps {
  data?: Trainer;
  show: boolean;
  onSave: (data: TrainerFormData, id?: string) => void;
  onCancel: () => void;
  onError: (message: string) => void;
}

export enum TrainerFormFieldName {
  firstName = 'firstName',
  lastName = 'lastName',
  rideHours = 'rideHours',
  carrierStartYear = 'carrierStartYear',
  favoriteMusic = 'favoriteMusic',
  hobbies = 'hobbies',
  picture = 'picture',
  pictureUrl = 'pictureUrl',
  description = 'description',
  qualifications = 'qualifications',
  profession = 'profession',
}

export interface TrainerFormData {
  [TrainerFormFieldName.firstName]: string;
  [TrainerFormFieldName.lastName]: string;
  [TrainerFormFieldName.rideHours]: string;
  [TrainerFormFieldName.carrierStartYear]: string;
  [TrainerFormFieldName.favoriteMusic]?: string;
  [TrainerFormFieldName.hobbies]?: string;
  [TrainerFormFieldName.picture]?: File | null;
  [TrainerFormFieldName.pictureUrl]?: string;
  [TrainerFormFieldName.description]?: string;
  [TrainerFormFieldName.qualifications]: string;
  [TrainerFormFieldName.profession]: string;
}
