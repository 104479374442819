import { GetStreamsParams, SortOrder, StreamClient } from 'api_client';

import { isLastPageReached } from 'api_client';

import { Stream, StreamSortField } from 'interfaces';

import { ThunkDispatch } from 'redux-thunk';

import { Action, Dispatch } from "redux";


import { StreamFormData } from '../../screens/StreamListScreen/StreamFormModal/types';

import { TAppState } from "../index";

import { AppThunk } from '../types';

import {
  SET_CREATE_STREAM_ERROR,
  SET_CREATE_STREAM_LOADING,
  SET_CREATE_STREAM_SUCCESS,
  SET_GET_STREAMS_ERROR,
  SET_GET_STREAMS_LOADING,
  SET_UPDATE_STREAM_ERROR,
  SET_UPDATE_STREAM_LOADING,
  SET_UPDATE_STREAM_SUCCESS,
  SET_STREAMS,
  SET_DELETE_STREAM_SUCCESS,
  SET_DELETE_STREAM_ERROR,
  SET_DELETE_STREAM_LOADING,
  APPEND_STREAMS,
  SET_STREAMS_LAST_PAGE_REACHED,
  INC_STREAMS_CURRENT_PAGE,
  RESET_STREAMS_CURRENT_PAGE,
  SET_STREAMS_GET_PARAMS,
} from './actionTypes';

export const setStreams = (streams: Stream[]): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_STREAMS,
    payload: streams,
  });
};

export const setStreamsGetParams = (params: GetStreamsParams): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_STREAMS_GET_PARAMS,
    payload: params,
  });
};

export const appendStreams = (users: Stream[]): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: APPEND_STREAMS,
    payload: users,
  });
};

export const setStreamsLastPageReached = (reached: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_STREAMS_LAST_PAGE_REACHED,
    payload: reached,
  });
};

export const setCreateStreamSuccess = (isSuccess: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_CREATE_STREAM_SUCCESS,
    payload: isSuccess,
  });
};

export const setUpdateStreamSuccess = (isSuccess: boolean, data?: Stream): AppThunk => (
  dispatch: Dispatch,
): void => {
  dispatch({
    type: SET_UPDATE_STREAM_SUCCESS,
    payload: {
      success: isSuccess,
      data: data,
    },
  });
};

export const setDeleteStreamSuccess = (isSuccess: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_DELETE_STREAM_SUCCESS,
    payload: isSuccess,
  });
};

export const setGetStreamsError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GET_STREAMS_ERROR,
    payload: error,
  });
};

export const setCreateStreamError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_CREATE_STREAM_ERROR,
    payload: error,
  });
};

export const setUpdateStreamError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_UPDATE_STREAM_ERROR,
    payload: error,
  });
};

export const setDeleteStreamError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_DELETE_STREAM_ERROR,
    payload: error,
  });
};

export const setGetStreamsLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GET_STREAMS_LOADING,
    payload: isLoading,
  });
};

export const setCreateStreamLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_CREATE_STREAM_LOADING,
    payload: isLoading,
  });
};

export const setUpdateStreamLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_UPDATE_STREAM_LOADING,
    payload: isLoading,
  });
};

export const setDeleteStreamLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_DELETE_STREAM_LOADING,
    payload: isLoading,
  });
};

export const incStreamsCurrentPage = (): AppThunk => (dispatch: Dispatch, getState): void => {
  if (!getState().stream.isStreamListLastPageReached) {
    dispatch({
      type: INC_STREAMS_CURRENT_PAGE,
    });
  }
};

export const resetStreamsCurrentPage = (): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: RESET_STREAMS_CURRENT_PAGE,
  });
};

export const getStreams = (params?: GetStreamsParams, lazyLoad = false): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setGetStreamsLoading(true));

    const newParams: GetStreamsParams = {
      ...params,
      order: SortOrder.Desc,
      sort: StreamSortField.Start,
    }

    const streams = await StreamClient.getAll(newParams);

    if (lazyLoad) {
      dispatch(appendStreams(streams));
    } else {
      dispatch(setStreams(streams));
    }

    dispatch(setStreamsLastPageReached(isLastPageReached(streams, newParams)));
  } catch (error) {
    dispatch(setGetStreamsError(error.message));
  } finally {
    dispatch(setGetStreamsLoading(false));
  }
};

export const createStream = (streamData: StreamFormData): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setCreateStreamLoading(true));
    dispatch(setCreateStreamSuccess(false));

    const requestData = {
      name: streamData.name,
      trainerId: streamData.trainer,
      url: streamData.url,
      description: streamData.description,
      picture: streamData.picture,
      startTime: streamData.startTime,
      endTime: streamData.endTime,
    };

    await StreamClient.create(requestData);

    dispatch(setCreateStreamSuccess(true));
  } catch (error) {
    dispatch(setCreateStreamError(error.message));
  } finally {
    dispatch(setCreateStreamLoading(false));
  }
};

export const updateStream = (id: string, streamData: StreamFormData): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setUpdateStreamLoading(true));
    dispatch(setUpdateStreamSuccess(false));

    const requestData = {
      name: streamData.name,
      trainerId: streamData.trainer,
      url: streamData.url,
      description: streamData.description,
      picture: streamData.picture,
      startTime: streamData.startTime,
      endTime: streamData.endTime,
    };

    const data = await StreamClient.update(id, requestData);
    if (data.pictures) data.pictures.hash = Date.now().toString();
    dispatch(setUpdateStreamSuccess(true, data));
  } catch (error) {
    dispatch(setUpdateStreamError(error.message));
  } finally {
    dispatch(setUpdateStreamLoading(false));
  }
};

export const deleteStream = (id: string): AppThunk => async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
  try {
    dispatch(setDeleteStreamLoading(true));
    dispatch(setDeleteStreamSuccess(false));

    await StreamClient.delete(id);

    dispatch(setDeleteStreamSuccess(true));
  } catch (error) {
    dispatch(setDeleteStreamError(error.message));
  } finally {
    dispatch(setDeleteStreamLoading(false));
  }
};
