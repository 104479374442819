import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './SearchBar.module.scss';
import { SearchBarProps } from './types';

/**
 * Search bar component for lists.
 * @param onStartSearch - A function what will be called when the enter key was pressed.
 * @param className - Custom classes.
 * @param initialValue - Initial value.
 */
const SearchBar: React.FC<SearchBarProps> = ({
  onStartSearch,
  className = '',
  initialValue = '',
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>(initialValue);

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const val = e.currentTarget.value;

    setValue(val);

    if (e.key === 'Enter') {
      onStartSearch(val);
    }
  };

  return (
    <input
      type="text"
      placeholder={t('searchbar.placeholder')}
      className={`px-3 bg-white text-dark border border-dark shadow-none text-uppercase ${styles.input} ${className}`}
      onKeyPress={(e) => onKeyPress(e)}
      defaultValue={value}
    />
  );
};

export default SearchBar;
