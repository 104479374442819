import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { Image } from 'web-components';


import { ROUTE } from '@navigation/types';

import { AppState } from '../../store/types';
import Images from '../../utils/images';
import LogoutButton from '../LogoutButton';

import styles from './NavBar.module.scss';
import NavLink from './NavLink';
import { NavBarParams } from './types';

/**
 * Side navigation bar component for the private routes.
 */
const NavBar: React.FC<NavBarParams> = ({ onLogout }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useSelector((state: AppState) => state.auth);

  const renderNavLink = (route: ROUTE, labelKey: string): React.ReactElement => {

    const isLinkActive = location.pathname === route;

    return (
      <NavLink
        className={`font-secondary ${isLinkActive ? 'active font-weight-bold text-secondary' : ''}`}
        to={route}
      >
        {t(labelKey)}
      </NavLink>
    );
  };

  return (
    <div
      className={`sticky-top p-4 pb-5 bg-primary border-right border-dark d-flex flex-column ${styles.navBarContainer}`}
    >
      <Image src={Images.MYRIDE_LOGO} />

      <h3 className="my-4 text-light font-weight-normal">{user?.firstName}</h3>

      <div className={`d-flex flex-column vh-100 mt-5 ${styles.content}`}>
        <div>
          {renderNavLink(ROUTE.StreamList, 'navbar.streams')}
          {renderNavLink(ROUTE.UserList, 'navbar.users')}
          {renderNavLink(ROUTE.AdminList, 'navbar.admins')}
          {renderNavLink(ROUTE.TrainerList, 'navbar.trainers')}
          {renderNavLink(ROUTE.PromoCodeList, 'navbar.promo_codes')}
          {renderNavLink(ROUTE.Archive, 'navbar.archive')}
        </div>

        <LogoutButton onClick={onLogout} />
      </div>
    </div>
  );
};

export default NavBar;
