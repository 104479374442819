import { Stream, StreamRelationships } from 'interfaces';

import { appendToList, updateListItem } from 'shared';

import {
  SET_CREATE_STREAM_ERROR,
  SET_CREATE_STREAM_LOADING,
  SET_CREATE_STREAM_SUCCESS,
  SET_DELETE_STREAM_ERROR,
  SET_DELETE_STREAM_LOADING,
  SET_DELETE_STREAM_SUCCESS,
  SET_GET_STREAMS_ERROR,
  SET_GET_STREAMS_LOADING,
  SET_STREAMS,
  SET_UPDATE_STREAM_ERROR,
  SET_UPDATE_STREAM_LOADING,
  SET_UPDATE_STREAM_SUCCESS,
  APPEND_STREAMS,
  SET_STREAMS_LAST_PAGE_REACHED,
  RESET_STREAMS_CURRENT_PAGE,
  INC_STREAMS_CURRENT_PAGE,
  SET_STREAMS_GET_PARAMS,
  StreamActionTypes,
} from './actionTypes';
import { StreamState } from './types';

const defaultState: StreamState = {
  streamList: [],
  getParams: {
    include: [StreamRelationships.Trainer],
  },
  isStreamListLastPageReached: false,
  getStreamsError: undefined,
  isCreateStreamSuccess: false,
  isUpdateStreamSuccess: false,
  isDeleteStreamSuccess: false,
  createStreamError: undefined,
  updateStreamError: undefined,
  deleteStreamError: undefined,
  isGetStreamsLoading: false,
  isUpdateStreamLoading: false,
  isCreateStreamLoading: false,
  isDeleteStreamLoading: false,
};

export default (state = defaultState, action: StreamActionTypes): StreamState => {
  switch (action.type) {
    case SET_STREAMS:
      return {
        ...state,
        streamList: action.payload,
      };

    case SET_STREAMS_GET_PARAMS:
      return {
        ...state,
        getParams: action.payload,
      };

    case INC_STREAMS_CURRENT_PAGE:
      return {
        ...state,
        getParams: { ...state.getParams, page: (state.getParams.page ?? 1) + 1 },
      };

    case RESET_STREAMS_CURRENT_PAGE:
      return {
        ...state,
        isStreamListLastPageReached: false,
        getParams: { ...state.getParams, page: 1 },
      };

    case APPEND_STREAMS:
      return {
        ...state,
        streamList: appendToList(state.streamList, action.payload) as Stream[],
      };

    case SET_STREAMS_LAST_PAGE_REACHED:
      return {
        ...state,
        isStreamListLastPageReached: action.payload,
      };

    case SET_CREATE_STREAM_SUCCESS:
      return {
        ...state,
        isCreateStreamSuccess: action.payload,
        createStreamError: undefined,
      };

    case SET_UPDATE_STREAM_SUCCESS: {
      const stream = action.payload.data;

      return {
        ...state,
        streamList: stream
          ? (updateListItem(state.streamList, stream) as Stream[])
          : state.streamList,
        isUpdateStreamSuccess: action.payload.success,
        updateStreamError: undefined,
      };
    }

    case SET_DELETE_STREAM_SUCCESS:
      return {
        ...state,
        isDeleteStreamSuccess: action.payload,
        deleteStreamError: undefined,
      };

    case SET_GET_STREAMS_ERROR:
      return {
        ...state,
        getStreamsError: action.payload,
      };

    case SET_CREATE_STREAM_ERROR:
      return {
        ...state,
        createStreamError: action.payload,
        isCreateStreamSuccess: false,
      };

    case SET_UPDATE_STREAM_ERROR:
      return {
        ...state,
        updateStreamError: action.payload,
        isUpdateStreamSuccess: false,
      };

    case SET_DELETE_STREAM_ERROR:
      return {
        ...state,
        deleteStreamError: action.payload,
        isDeleteStreamSuccess: false,
      };

    case SET_GET_STREAMS_LOADING:
      return {
        ...state,
        isGetStreamsLoading: action.payload,
      };

    case SET_CREATE_STREAM_LOADING:
      return {
        ...state,
        isCreateStreamLoading: action.payload,
      };

    case SET_UPDATE_STREAM_LOADING:
      return {
        ...state,
        isUpdateStreamLoading: action.payload,
      };

    case SET_DELETE_STREAM_LOADING:
      return {
        ...state,
        isDeleteStreamLoading: action.payload,
      };

    default:
      return state;
  }
};
