export interface UseToastNotificationReturn {
  errorNotification: (message: string) => void;
  successNotification: (message: string) => void;
  warningNotification: (message: string) => void;
  infoNotification: (message: string) => void;
  removeAllNotifications: () => void;
}

export enum NotificationTypes {
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
}
