import { PASSWORD_REGEX, getErrorMessage } from 'shared';
import * as yup from 'yup';

import { LoginFields } from '../../screens/LoginScreen/types';

const LoginSchema: yup.SchemaOf<LoginFields> = yup.object().shape({
  password: yup
    .string()
    .required(getErrorMessage('password', 'password_required'))
    .matches(PASSWORD_REGEX, { message: getErrorMessage('password', 'password_invalid') }),
  email: yup
    .string()
    .required(getErrorMessage('email', 'email_required'))
    .email(getErrorMessage('email', 'email_invalid')),
});

export default LoginSchema;
