import { GetPromoCodesParams } from 'api_client';
import { PromoCode } from 'interfaces';

export const SET_PROMO_CODES = 'SET_PROMO_CODES';
export const APPEND_PROMO_CODES = 'APPEND_PROMO_CODES';

export const SET_PROMO_CODES_GET_PARAMS = 'SET_PROMO_CODES_GET_PARAMS';
export const INC_PROMO_CODES_CURRENT_PAGE = 'INC_PROMO_CODES_CURRENT_PAGE';
export const RESET_PROMO_CODES_CURRENT_PAGE = 'RESET_PROMO_CODES_CURRENT_PAGE';
export const SET_PROMO_CODES_LAST_PAGE_REACHED = 'SET_PROMO_CODES_LAST_PAGE_REACHED';

export const SET_GENERATED_CODE = 'SET_GENERATED_CODE';

export const SET_CREATE_PROMO_CODE_SUCCESS = 'SET_CREATE_PROMO_CODE_SUCCESS';
export const SET_UPDATE_PROMO_CODE_SUCCESS = 'SET_UPDATE_PROMO_CODE_SUCCESS';
export const SET_UPDATE_PROMO_CODE_STATUS_SUCCESS = 'SET_UPDATE_PROMO_CODE_STATUS_SUCCESS';
export const SET_DELETE_PROMO_CODE_SUCCESS = 'SET_DELETE_PROMO_CODE_SUCCESS';
export const SET_GENERATE_CODE_SUCCESS = 'SET_GENERATE_CODE_SUCCESS';

export const SET_GET_PROMO_CODES_ERROR = 'SET_GET_PROMO_CODES_ERROR';
export const SET_CREATE_PROMO_CODE_ERROR = 'SET_CREATE_PROMO_CODE_ERROR';
export const SET_UPDATE_PROMO_CODE_ERROR = 'SET_UPDATE_PROMO_CODE_ERROR';
export const SET_UPDATE_PROMO_CODE_STATUS_ERROR = 'SET_UPDATE_PROMO_CODE_STATUS_ERROR';
export const SET_DELETE_PROMO_CODE_ERROR = 'SET_DELETE_PROMO_CODE_ERROR';
export const SET_GENERATE_CODE_ERROR = 'SET_GENERATE_CODE_ERROR';

export const SET_GET_PROMO_CODES_LOADING = 'SET_GET_PROMO_CODES_LOADING';
export const SET_CREATE_PROMO_CODE_LOADING = 'SET_CREATE_PROMO_CODE_LOADING';
export const SET_UPDATE_PROMO_CODE_LOADING = 'SET_UPDATE_PROMO_CODE_LOADING';
export const SET_UPDATE_PROMO_CODE_STATUS_LOADING = 'SET_UPDATE_PROMO_CODE_STATUS_LOADING';
export const SET_DELETE_PROMO_CODE_LOADING = 'SET_DELETE_PROMO_CODE_LOADING';
export const SET_GENERATE_CODE_LOADING = 'SET_GENERATE_CODE_LOADING';

export interface SetPromoCodes {
  type: typeof SET_PROMO_CODES;
  payload: PromoCode[];
}

export interface SetPromoCodesGetParams {
  type: typeof SET_PROMO_CODES_GET_PARAMS;
  payload: GetPromoCodesParams;
}

export interface IncPromoCodesCurrentPage {
  type: typeof INC_PROMO_CODES_CURRENT_PAGE;
}

export interface ResetPromoCodesCurrentPage {
  type: typeof RESET_PROMO_CODES_CURRENT_PAGE;
}

export interface AppendPromoCodes {
  type: typeof APPEND_PROMO_CODES;
  payload: PromoCode[];
}

export interface SetPromoCodesLastPageReached {
  type: typeof SET_PROMO_CODES_LAST_PAGE_REACHED;
  payload: boolean;
}

export interface SetGeneratedCode {
  type: typeof SET_GENERATED_CODE;
  payload: string | undefined;
}

export interface SetCreatePromoCodeSuccess {
  type: typeof SET_CREATE_PROMO_CODE_SUCCESS;
  payload: boolean;
}

export interface SetUpdatePromoCodeSuccess {
  type: typeof SET_UPDATE_PROMO_CODE_SUCCESS;
  payload: {
    success: boolean;
    data?: PromoCode;
  };
}

export interface SetUpdatePromoCodeStatusSuccess {
  type: typeof SET_UPDATE_PROMO_CODE_STATUS_SUCCESS;
  payload: {
    success: boolean;
    data?: PromoCode;
  };
}

export interface SetDeletePromoCodeSuccess {
  type: typeof SET_DELETE_PROMO_CODE_SUCCESS;
  payload: boolean;
}

export interface SetGenerateCodeSuccess {
  type: typeof SET_GENERATE_CODE_SUCCESS;
  payload: boolean;
}

export interface SetGetPromoCodesError {
  type: typeof SET_GET_PROMO_CODES_ERROR;
  payload?: string;
}

export interface SetCreatePromoCodeError {
  type: typeof SET_CREATE_PROMO_CODE_ERROR;
  payload?: string;
}

export interface SetUpdatePromoCodeError {
  type: typeof SET_UPDATE_PROMO_CODE_ERROR;
  payload?: string;
}

export interface SetUpdatePromoCodeStatusError {
  type: typeof SET_UPDATE_PROMO_CODE_STATUS_ERROR;
  payload?: string;
}

export interface SetDeletePromoCodeError {
  type: typeof SET_DELETE_PROMO_CODE_ERROR;
  payload?: string;
}

export interface SetGenerateCodeError {
  type: typeof SET_GENERATE_CODE_ERROR;
  payload?: string;
}

export interface SetGetPromoCodesLoading {
  type: typeof SET_GET_PROMO_CODES_LOADING;
  payload: boolean;
}

export interface SetCreatePromoCodeLoading {
  type: typeof SET_CREATE_PROMO_CODE_LOADING;
  payload: boolean;
}

export interface SetUpdatePromoCodeLoading {
  type: typeof SET_UPDATE_PROMO_CODE_LOADING;
  payload: boolean;
}

export interface SetUpdatePromoCodeStatusLoading {
  type: typeof SET_UPDATE_PROMO_CODE_STATUS_LOADING;
  payload: boolean;
}

export interface SetDeletePromoCodeLoading {
  type: typeof SET_DELETE_PROMO_CODE_LOADING;
  payload: boolean;
}

export interface SetGenerateCodeLoading {
  type: typeof SET_GENERATE_CODE_LOADING;
  payload: boolean;
}

export type PromoCodeActionTypes =
  | SetPromoCodes
  | SetPromoCodesGetParams
  | IncPromoCodesCurrentPage
  | ResetPromoCodesCurrentPage
  | AppendPromoCodes
  | SetPromoCodesLastPageReached
  | SetGeneratedCode
  | SetCreatePromoCodeSuccess
  | SetUpdatePromoCodeSuccess
  | SetUpdatePromoCodeStatusSuccess
  | SetDeletePromoCodeSuccess
  | SetGenerateCodeSuccess
  | SetGetPromoCodesError
  | SetCreatePromoCodeError
  | SetUpdatePromoCodeError
  | SetUpdatePromoCodeStatusError
  | SetDeletePromoCodeError
  | SetGenerateCodeError
  | SetGetPromoCodesLoading
  | SetCreatePromoCodeLoading
  | SetUpdatePromoCodeLoading
  | SetUpdatePromoCodeStatusLoading
  | SetDeletePromoCodeLoading
  | SetGenerateCodeLoading;
