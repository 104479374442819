import { Video } from 'interfaces';

import { appendToList, updateListItem } from 'shared';

import {
  SET_GET_VIDEOS_ERROR,
  SET_GET_VIDEOS_LOADING,
  SET_UPDATE_VIDEO_ERROR,
  SET_UPDATE_VIDEO_LOADING,
  SET_UPDATE_VIDEO_STATUS_ERROR,
  SET_UPDATE_VIDEO_STATUS_LOADING,
  SET_UPDATE_VIDEO_STATUS_SUCCESS,
  SET_UPDATE_VIDEO_SUCCESS,
  SET_VIDEOS,
  APPEND_VIDEOS,
  SET_VIDEOS_LAST_PAGE_REACHED,
  RESET_VIDEOS_CURRENT_PAGE,
  INC_VIDEOS_CURRENT_PAGE,
  ArchiveActionTypes,
  SET_VIDEOS_GET_PARAMS,
} from './actionTypes';
import { VideoState } from './types';

const defaultState: VideoState = {
  videoList: [],
  getParams: {},
  isVideoListLastPageReached: false,
  isUpdateVideoSuccess: false,
  isUpdateVideoStatusSuccess: false,
  getVideosError: undefined,
  updateVideoError: undefined,
  updateVideoStatusError: undefined,
  isGetVideosLoading: false,
  isUpdateVideoLoading: false,
  isUpdateVideoStatusLoading: false,
};

export default (state = defaultState, action: ArchiveActionTypes): VideoState => {
  switch (action.type) {
    case SET_VIDEOS:
      return {
        ...state,
        videoList: action.payload,
      };

    case SET_VIDEOS_GET_PARAMS:
      return {
        ...state,
        getParams: action.payload,
      };

    case INC_VIDEOS_CURRENT_PAGE:
      return {
        ...state,
        getParams: { ...state.getParams, page: (state.getParams.page ?? 1) + 1 },
      };

    case RESET_VIDEOS_CURRENT_PAGE:
      return {
        ...state,
        isVideoListLastPageReached: false,
        getParams: { ...state.getParams, page: 1 },
      };

    case APPEND_VIDEOS:
      return {
        ...state,
        videoList: appendToList(state.videoList, action.payload) as Video[],
      };

    case SET_VIDEOS_LAST_PAGE_REACHED:
      return {
        ...state,
        isVideoListLastPageReached: action.payload,
      };

    case SET_UPDATE_VIDEO_SUCCESS: {
      const video = action.payload.data;

      return {
        ...state,
        videoList: video ? (updateListItem(state.videoList, video) as Video[]) : state.videoList,
        isUpdateVideoSuccess: action.payload.success,
        updateVideoError: undefined,
      };
    }

    case SET_UPDATE_VIDEO_STATUS_SUCCESS: {
      const video = action.payload.data;

      return {
        ...state,
        videoList: video ? (updateListItem(state.videoList, video) as Video[]) : state.videoList,
        isUpdateVideoStatusSuccess: action.payload.success,
        updateVideoStatusError: undefined,
      };
    }

    case SET_GET_VIDEOS_ERROR:
      return {
        ...state,
        getVideosError: action.payload,
      };

    case SET_UPDATE_VIDEO_ERROR:
      return {
        ...state,
        updateVideoError: action.payload,
        isUpdateVideoSuccess: false,
      };

    case SET_UPDATE_VIDEO_STATUS_ERROR:
      return {
        ...state,
        updateVideoStatusError: action.payload,
        isUpdateVideoStatusSuccess: false,
      };

    case SET_GET_VIDEOS_LOADING:
      return {
        ...state,
        isGetVideosLoading: action.payload,
      };

    case SET_UPDATE_VIDEO_LOADING:
      return {
        ...state,
        isUpdateVideoLoading: action.payload,
      };

    case SET_UPDATE_VIDEO_STATUS_LOADING:
      return {
        ...state,
        isUpdateVideoStatusLoading: action.payload,
      };

    default:
      return state;
  }
};
