import React from 'react';

import { format } from 'date-fns';
import countries from 'i18n-iso-countries';
import english from 'i18n-iso-countries/langs/en.json';
import hungary from 'i18n-iso-countries/langs/hu.json';
import i18next from 'i18next';
import { User, UserSubscriptionData, TextValuePair } from 'interfaces';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFormValidation, DATE_FORMAT } from 'shared';

import { Modal, ModalRow } from 'web-components';

import { Button, FormControl, TextInput, CountrySelect, RoleSelect } from '@components';

import { AppState } from '../../../store/types';
import UserSchema from '../../../utils/validation/UserSchema';

import { UserFormData, UserFormFieldName, UserFormProps } from './types';
import styles from './UserFormModal.module.scss';

const formData = (data?: User) => ({
  email: data?.email || '',
  firstName: data?.firstName || '',
  lastName: data?.lastName || '',
  companyName: data?.billingInfo?.companyName || undefined,
  companyVatId: data?.billingInfo?.companyVatId || undefined,
  address: data?.billingInfo?.address || '',
  zipCode: data?.billingInfo?.zipCode || '',
  city: data?.billingInfo?.city || '',
  country: data?.billingInfo?.country || '',
  roles: data?.roles || [],
});

/**
 * User form modal to add or edit a user.
 * @param data - The user's data.
 * @param show - If 'true', the modal is displayed.
 * @param onSave - A function what will be called when the save button was pressed.
 * @param onCancel - A function what will be called when the cancel button was pressed.
 */
const UserFormModal: React.FC<UserFormProps> = ({
  data,
  onSave,
  onSubscriptionCancel,
  onCancel,
  onError,
  onDisablePromoCode,
  ...rest
}) => {
  const { t } = useTranslation();
  const [form, setForm] = React.useState<UserFormData>(formData(data));
  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const { isUpdateUserLoading, isCreateUserLoading, isCancelSubscriptionLoading, isDisablePromoCodeLoading } = useSelector(
    (state: AppState) => state.user,
  );
  const { validationErrors } = useFormValidation<UserFormData>(UserSchema, form);

  React.useEffect(() => {
    setIsFormSubmitted(false);
    setForm(formData(data));
  }, [rest.show, data]);

  React.useEffect(() => {
    const errorMessage = validationErrors[Object.keys(validationErrors)[0]];

    onError(errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationErrors]);

  React.useEffect(() => {
    if (isFormSubmitted) {
      onSave(form, data?._id);

      setIsFormSubmitted(false);
    }
  }, [isFormSubmitted, onSave, form, data?._id]);

  React.useEffect(() => {
    setIsSaving(isCreateUserLoading || isUpdateUserLoading || isDisablePromoCodeLoading);
  }, [isCreateUserLoading, isUpdateUserLoading, isDisablePromoCodeLoading]);


  const onFieldChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ): void => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onFieldChangeRoles = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ): void => {
    const { value } = e.target;

    if (!form?.roles.filter((role) => role === value).length && value) {
      setForm({ ...form, roles: [...form.roles, value] as unknown as [] });
    } else {
      setForm({ ...form, roles: form?.roles.filter((item) => item !== value) as [] });
    }
  };

  const onSaveButtonClicked = () => {
    setIsFormSubmitted(true);
  };

  const onCancelButtonClicked = () => {
    setIsFormSubmitted(false);

    onCancel();
  };

  const currentLang = i18next.language.toUpperCase();

  countries.registerLocale(currentLang === 'HU' ? hungary : english);

  const countriesOptions: [string, string][] = Object.entries(
    countries.getNames('hu', { select: 'official' }),
  );

  const getTrainerListOptions: TextValuePair[] = [];

  Object.keys(countriesOptions).map((val: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const country = countriesOptions[val];

    if (country.length) {
      getTrainerListOptions.push({
        text: country[1],
        value: country[0],
      });
    }
  });

  return (
    <Modal
      {...rest}
      title={t(data ? 'user_form_modal.edit_user' : 'user_form_modal.new_user')}
      actionButtons={[
        {
          text: t('common.cancel'),
          onClick: onCancelButtonClicked,
          classes: ['bg-rejection', 'font-weight-bold', styles.button],
        },
        {
          text: isSaving ? t('common.saving') : t('common.save'),
          arguments: isSaving ? [{ name: 'disabled', value: true }] : [],
          onClick: (): void => onSaveButtonClicked(),
          classes: ['bg-secondary', 'font-weight-bold', styles.button],
        },
      ]}
    >
      <form className="text-primary" noValidate>
        <ModalRow
          items={[
            <>
              <label>{t('user_form_modal.email')}</label>
              <FormControl key="1">
                <TextInput
                  type="email"
                  name={UserFormFieldName.email}
                  value={form.email}
                  onChange={onFieldChange}
                />
              </FormControl>
            </>,
          ]}
        />
        <ModalRow
          items={[
            <>
              <label>{t('user_form_modal.lastname')}</label>
              <FormControl key="1">
                <TextInput
                  name={UserFormFieldName.lastName}
                  value={form.lastName}
                  onChange={onFieldChange}
                />
              </FormControl>
            </>,
            <>
              <label>{t('user_form_modal.firstname')}</label>
              <FormControl key="2">
                <TextInput
                  name={UserFormFieldName.firstName}
                  value={form.firstName}
                  onChange={onFieldChange}
                />
              </FormControl>
            </>,
          ]}
        />
        <ModalRow
          items={[
            <>
              <label>{t('user_form_modal.roles')}</label>
              <RoleSelect
                name={UserFormFieldName.roles}
                multiple
                placeholderDisable
                value={form.roles as []}
                className={styles.country}
                onChange={onFieldChangeRoles}
              />
            </>,
            <></>,
          ]}
        />
        <div className="mt-4 mb-4 text-uppercase font-weight-bold">
          {t('user_form_modal.billing_info')}
        </div>
        <ModalRow
          items={[
            <>
              <label>{t('user_form_modal.company_name')}</label>
              <FormControl key="1">
                <TextInput
                  name={UserFormFieldName.companyName}
                  value={form.companyName || ''}
                  onChange={onFieldChange}
                />
              </FormControl>
            </>,
            <>
              <label>{t('user_form_modal.vat_id')}</label>
              <FormControl key="2">
                <TextInput
                  name={UserFormFieldName.companyVatId}
                  value={form.companyVatId || ''}
                  onChange={onFieldChange}
                />
              </FormControl>
            </>,
          ]}
        />
        <ModalRow
          items={[
            <>
              <label>{t('user_form_modal.address')}</label>
              <FormControl key="1">
                <TextInput
                  name={UserFormFieldName.address}
                  value={form.address}
                  onChange={onFieldChange}
                />
              </FormControl>
            </>,
            <>
              <label>{t('user_form_modal.zip_code')}</label>
              <FormControl key="2">
                <TextInput
                  name={UserFormFieldName.zipCode}
                  value={form.zipCode}
                  onChange={onFieldChange}
                />
              </FormControl>
            </>,
          ]}
        />
        <ModalRow
          items={[
            <>
              <label>{t('user_form_modal.city')}</label>
              <FormControl key="1">
                <TextInput
                  name={UserFormFieldName.city}
                  value={form.city}
                  onChange={onFieldChange}
                />
              </FormControl>
            </>,
            <>
              <label>{t('user_form_modal.country')}</label>
              <CountrySelect
                name={UserFormFieldName.country}
                value={form.country}
                className={`${styles.country} col`}
                onChange={onFieldChange}
              />
            </>,
          ]}
        />

        {data && (
          <>
            {data.subscription && (
              <div className="mb-3">
                {t(`subscription_plans.${data.subscription?.plan?.toLowerCase()}`)}
              </div>
            )}

            {data.subscription && data.subscription?.expirationDate && (
              <div className="mb-3">
                <span className="mr-2">
                  {t('user_form_modal.active_until')}
                  {': '}
                </span>
                <span>
                  {format(new Date(data.subscription.expirationDate as string), DATE_FORMAT)}
                </span>
              </div>
            )}

            {data.subscription && data.subscription?.cancelledAt && (
              <div className="mb-3">
                <span className="mr-2">
                  {t('user_form_modal.cancelled_at')}
                  {': '}
                </span>
                <span>
                  {format(new Date(data.subscription.cancelledAt as string), DATE_FORMAT)}
                </span>
              </div>
            )}


            {data.subscription && data.subscription?.getFullPrice && (
              <div className="mb-3">
                <span className="mr-2">
                  {t('promo_code_form_modal.full_price')}
                  {': '}
                </span>
                <span>
                  {data.subscription.getFullPrice} Ft
                </span>
              </div>
            )}

            {data.subscription && data.subscription?.promoCodePercentage && (
              <div className="mb-3">
                <span className="mr-2">
                  {t('promo_code_form_modal.discount')}
                  {': '}
                </span>
                {data.subscription.promoCodeDisabled ? 
                  <span>
                    <del>{data.subscription.promoCodePercentage}%</del>
                  </span> 
                  :
                  <span>
                    {data.subscription.promoCodePercentage}%
                  </span>
                }

              </div>
            )}

            {data.subscription && data.subscription?.getPriceWithPromoCode && (
              <div className="mb-3">
                <span className="mr-2">
                  {t('promo_code_form_modal.price_with_discount')}
                  {': '}
                </span>
                <span>
                  {data.subscription.getPriceWithPromoCode} Ft
                </span>
              </div>
            )}

            {data.subscription && data.subscription?.isActive && !data.subscription?.cancelledAt && (
              <div className="mb-3">
                <Button
                  disabled={isCancelSubscriptionLoading}
                  onClick={() =>
                    onSubscriptionCancel((data.subscription as UserSubscriptionData)._id)
                  }
                >
                  {isCancelSubscriptionLoading
                    ? t('common.saving')
                    : t('user_form_modal.cancel_subscription')}
                </Button>
              </div>
            )}

            {
              data.subscription && data.subscription.promoCodePercentage &&
              <div className="mb-3">
                <Button
                  disabled={isDisablePromoCodeLoading}
                  onClick={() => {
                    if (!data.subscription) return;
                    onDisablePromoCode(data.subscription)
                  }}
                >
                  {data.subscription.promoCodeDisabled ? t('user_form_modal.enable_promo_code') : t('user_form_modal.disable_promo_code')}
                </Button>
              </div>
            }

            {data.subscription && !data.subscription?.isActive && (
              <div>{t('user_form_modal.inactive_subscription')}</div>
            )}
          </>
        )}
      </form>
    </Modal>
  );
};

export default UserFormModal;
