import React from 'react';

import { useTranslation } from 'react-i18next';

import { Modal } from 'web-components';

import { Error } from '@components';

import styles from './AreYouSureModal.module.scss';
import { AreYouSureModalProps } from './types';

/**
 * Are you sure modal for delete.
 * @param show - If 'true', the modal is displayed.
 * @param isLoading - If saving.
 * @param error - Saving error.
 * @param onYesClick - A function what will be called when the yes button was pressed.
 * @param onNoClick - A function what will be called when the no button was pressed.
 * @param title - Modal title text
 * @param yesButton - Yes button title text
 */
const AreYouSureModal: React.FC<AreYouSureModalProps> = ({
  show,
  isLoading = false,
  error,
  onYesClick,
  onNoClick,
  title,
  yesButtonTitle,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      title={title || t('common.are_you_sure')}
      actionButtons={[
        {
          text: t('common.cancel'),
          classes: ['bg-secondary', 'font-weight-bold', 'px-4', styles.button],
          onClick: onNoClick,
        },
        {
          text: isLoading ? t('common.please_wait') : yesButtonTitle || t('common.yes'),
          arguments: isLoading ? [{ name: 'disabled', value: true }] : [],
          classes: ['bg-danger', 'text-light', 'font-weight-bold', 'px-4', styles.button],
          onClick: onYesClick,
        },
      ]}
    >
      <>{error && <Error error={error} />}</>
    </Modal>
  );
};

export default AreYouSureModal;
