import React, { useEffect, useRef, useState, useCallback } from 'react';

import { User } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useListModal, useScrollPagination, getFullName } from 'shared';

import { Button, Image } from 'web-components';

import {
  AreYouSureModal,
  ListItem,
  ListItemActions,
  SearchBar,
  Loading,
  TopScrollButton,
  Error,
} from '@components';

import useToastNotification from '../../hooks/useToastNotification';

import {
  createAdmin,
  getAdmins,
  updateAdmin,
  deleteAdmin,
  setUpdateAdminError,
  setCreateAdminError,
  setGetAdminsError,
  incAdminsCurrentPage,
  resetAdminsCurrentPage,
  setAdminsGetParams,
} from '../../store/admin/actions';

import { AppState } from '../../store/types';

import AdminDetailsModal from '../AdminListScreen/AdminDetailsModal';

import AdminFormModal from './AdminFormModal';

import { AdminFormData } from './AdminFormModal/types';

import styles from './AdminListScreen.module.scss';

const AdminListScreen: React.FC = () => {
  const { t, i18n } = useTranslation();

  const {
    adminList,
    getParams,
    isAdminListLastPageReached,
    getAdminsError,
    createAdminError,
    updateAdminError,
    deleteAdminError,
    isGetAdminsLoading,
    isDeleteAdminLoading,
    isUpdateAdminSuccess,
    isCreateAdminSuccess,
    isDeleteAdminSuccess,
  } = useSelector((state: AppState) => state.admin);

  const dispatch = useDispatch();

  const { loadingIndicator } = useScrollPagination(adminList.length > 0, () =>
    dispatch(incAdminsCurrentPage()),
  );

  const { errorNotification, removeAllNotifications } = useToastNotification();
  const [enableToastMessage, setEnableToastMessage] = useState<boolean>(false);
  const [validationErrorMessage, setValidationErrorMessage] =
    useState<string | undefined>(undefined);

  const needToFetchData = useRef(!adminList.length);

  const showErrorMessage = useCallback((message: string) => {
    errorNotification(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setGetAdminsError(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getAdminsError && enableToastMessage) {
      showErrorMessage(getAdminsError);
    }
    if (createAdminError && enableToastMessage) {
      showErrorMessage(createAdminError);
    }
    if (updateAdminError && enableToastMessage) {
      showErrorMessage(updateAdminError);
    }
    if (deleteAdminError && enableToastMessage) {
      showErrorMessage(deleteAdminError);
    }
  }, [
    getAdminsError,
    createAdminError,
    updateAdminError,
    deleteAdminError,
    enableToastMessage,
    showErrorMessage,
  ]);

  useEffect(() => {
    if (isCreateAdminSuccess) {
      onFormModalClose();

      dispatch(resetAdminsCurrentPage());
      dispatch(setAdminsGetParams({ ...getParams, page: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateAdminSuccess, dispatch]);

  useEffect(() => {
    if (isUpdateAdminSuccess) {
      onFormModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateAdminSuccess, dispatch]);

  useEffect(() => {
    if (isDeleteAdminSuccess) {
      dispatch(getAdmins(getParams));
      onAreYouSureModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteAdminSuccess, dispatch]);

  useEffect(() => {
    //prevent unnecessary reload after return to the current screen with loaded data
    if (needToFetchData.current && !isAdminListLastPageReached) {
      dispatch(getAdmins(getParams, (getParams.page ?? 1) > 1));
    }

    if (!needToFetchData.current) {
      needToFetchData.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams, dispatch]);

  useEffect(() => {
    removeAllNotifications();
    // (removeAllNotifications is excluded bc it doesn't work with that)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateAdminSuccess, isCreateAdminSuccess, isDeleteAdminSuccess]);

  const onSaveButtonClicked = (data: AdminFormData, id?: string) => {
    setEnableToastMessage(true);
    if (validationErrorMessage) {
      showErrorMessage(validationErrorMessage);
    } else if (data) {
      if (!id) {
        dispatch(createAdmin(data));
      } else {
        dispatch(updateAdmin(id, data));
      }
    }
  };

  const onDelete = (item: User) => {
    setEnableToastMessage(true);
    dispatch(deleteAdmin(item._id));
  };

  const onCancelButtonClicked = () => {
    dispatch(setUpdateAdminError(undefined));
    dispatch(setCreateAdminError(undefined));

    onFormModalClose();
  };

  const onStartSearch = (searchTerm: string): void => {
    dispatch(resetAdminsCurrentPage());
    dispatch(setAdminsGetParams({ ...getParams, page: undefined, search: searchTerm }));
  };

  const {
    showFormModal,
    showDetailsModal,
    showAreYouSureModal,
    modalData,
    onNewItemClick,
    onEditItemClick,
    onItemDetailsClick,
    onDetailsModalClose,
    onDeleteItemClick,
    onFormModalClose,
    onAreYouSureModalClose,
  } = useListModal();

  const listItemLeftContent = (item: User): React.ReactElement => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-1 p-0 my-auto">
          <Image className={styles.iconSize} src="/icons/mail.svg" alt="mail" />
        </div>
        <div className="col-11">{item.email}</div>
      </div>
    </div>
  );
  const listItemRightContent = (item: User): React.ReactElement => (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <ListItemActions
            detailsLabel={t('admin_list_screen.admin_details')}
            editLabel={t('admin_list_screen.edit_admin')}
            deleteLabel={t('admin_list_screen.delete_admin')}
            onEditClick={(): void => onEditItemClick(item)}
            onDeleteClick={(): void => onDeleteItemClick(item)}
            onDetailsClick={(): void => onItemDetailsClick(item)}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="container p-5">
      <div className="row">
        <div className="col-7">
          <SearchBar
            className={`${styles.input} w-100 font-weight-bold`}
            initialValue={getParams.search}
            onStartSearch={onStartSearch}
          />
        </div>
        <div className="col-3">
          <Button
            additionalStyles={`bg-secondary text-primary btn-sm font-weight-bold w-100 ${styles.button}`}
            onClick={onNewItemClick}
          >
            {t('admin_list_screen.add_new')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-10">
          <div className="container-fluid mt-5 mb-5">
            {getAdminsError && <Error error={getAdminsError} />}

            {adminList.map((item) => (
              <ListItem
                key={item._id}
                title={getFullName(item.firstName, item.lastName, i18n.language)}
                className="my-2"
                leftContent={listItemLeftContent(item)}
                rightContent={listItemRightContent(item)}
              />
            ))}

            {isGetAdminsLoading && <Loading />}

            <div ref={loadingIndicator} />
          </div>
        </div>
      </div>
      <TopScrollButton containerId="root_router" />
      {modalData && (
        <AdminDetailsModal
          data={modalData as User}
          show={showDetailsModal}
          onCancel={onDetailsModalClose}
        />
      )}
      <AdminFormModal
        data={modalData as User}
        show={showFormModal}
        onSave={onSaveButtonClicked}
        onCancel={onCancelButtonClicked}
        onError={(message) => setValidationErrorMessage(message)}
      />
      <AreYouSureModal
        show={showAreYouSureModal}
        isLoading={isDeleteAdminLoading}
        error={deleteAdminError}
        onNoClick={onAreYouSureModalClose}
        onYesClick={() => onDelete(modalData as User)}
        title={t('admin_form_modal.delete_title')}
        yesButtonTitle={t('admin_form_modal.delete_yes_button_title')}
      />
    </div>
  );
};

export default AdminListScreen;
