import React from 'react';

import { Client } from 'api_client';

import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import {
  ArchiveListScreen,
  AdminListScreen,
  LoginScreen,
  PromoCodeListScreen,
  StreamListScreen,
  TrainerListScreen,
  UserListScreen,
  ForgottenPasswordScreen,
  ResetPasswordScreen,
} from '@screens';

import { logout, setAccessToken, setRefreshToken } from '../store/auth/actions';
import { AppState } from '../store/types';

import PrivateRoute from './PrivateRoute';
import { ROUTE } from './types';

const RootRouter: React.FC = () => {
  const dispatch = useDispatch();

  const { accessToken, refreshToken } = useSelector(({ auth }: AppState) => auth);

  const accessTokenChangedCallback = (token?: string): void => {
    dispatch(setAccessToken(token));
  };

  const refreshTokenChangedCallback = (token?: string): void => {
    dispatch(setRefreshToken(token));
  };

  const logoutCallback = (): void => {
    dispatch(logout());
  };

  accessToken && Client.setAccessToken(accessToken);
  refreshToken && Client.setRefreshToken(refreshToken);
  Client.setCallbacks(accessTokenChangedCallback, refreshTokenChangedCallback, logoutCallback);
  Client.setLanguage(i18n.language);
  Client.setGeneralErrorMessage(i18n.t('errors.general_error'));

  return (
    <div id="root_router" className="d-flex overflow-auto vh-100 text-uppercase br-primary bg-primary">
      <Router>
        <Routes>
          <Route path={ROUTE.Login} element={<LoginScreen />} />
          <Route path={ROUTE.ForgottenPassword} element={<ForgottenPasswordScreen />} />
          <Route path={ROUTE.ResetPassword} element={<ResetPasswordScreen />} />

          <Route path={ROUTE.StreamList} element={<PrivateRoute component={<StreamListScreen />} />} />
          <Route path={ROUTE.UserList} element={<PrivateRoute component={<UserListScreen />} />} />
          <Route path={ROUTE.AdminList} element={<PrivateRoute component={<AdminListScreen />} />} />
          <Route path={ROUTE.TrainerList} element={<PrivateRoute component={<TrainerListScreen />} />} />
          <Route path={ROUTE.PromoCodeList} element={<PrivateRoute component={<PromoCodeListScreen />} />} />
          <Route path={ROUTE.Archive} element={<PrivateRoute component={<ArchiveListScreen />} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default RootRouter;
