import React from 'react';

import styles from './RatingStars.module.scss';
import { RatingStarsProps } from './types';

/**
 *
 * @param visibleOnly If it is true, the component only show the given rating.
 * @param goldenColor If it is true, the stars changes to golden color.
 * @param bigSize If it is true, the size of the stars changes to bigger.
 * @param ratersNumber How many people rated.
 * @param rating Given rate.
 * @param containerAdditionalStyles Container additional style classes.
 * @param rowAdditionalStyles Row additional style classes.
 * @param colAdditionalStyles Col additional style classes.
 * @param onChange On change method.
 * @constructor
 */
const RatingStars: React.FC<RatingStarsProps> = ({
  visibleOnly,
  goldenColor,
  bigSize,
  ratersNumber,
  rating,
  containerAdditionalStyles,
  rowAdditionalStyles,
  colAdditionalStyles,
  onChange,
}) => {
  const [ratingState, setRatingState] = React.useState<number>(rating / ratersNumber);
  const [tempRating, setTempRating] = React.useState<number>(rating / ratersNumber);

  const handleMouseover = (rate: number) => {
    setRatingState(rate);
    setTempRating((prevTempRating) => prevTempRating);
  };

  const handleMouseout = () => {
    setRatingState(tempRating);
  };

  const setRate = (rate: number) => {
    setRatingState(rate);
    setTempRating(rate);
  };

  const roundRating = (rate: number) => {
    if (rate % 1 <= 0.25) {
      return Math.floor(rate);
    } else if (rate % 1 <= 0.5) {
      return Math.round(rate * 2) * 0.5;
    } else if (rate % 1 <= 0.75) {
      return Math.floor(rate * 2) * 0.5;
    } else {
      return Math.round(rate);
    }
  };

  const fillStarArray = () => {
    return [...new Array(10)].map((_, i) => {
      let iconSrc = `/icons/star_${goldenColor ? 'gold' : 'blue'}_outline_${
        bigSize ? 'big' : 'small'
      }.svg`;
      if (roundRating(ratingState) >= (i + 1) / 2) {
        iconSrc = `/icons/star_${goldenColor ? 'gold' : 'blue'}_filled_${
          bigSize ? 'big' : 'small'
        }.svg`;
      }
      return (
        <img
          style={{ transform: i % 2 === 0 ? 'scaleX(1)' : 'scaleX(-1)' }}
          className={`${styles.starImg} ${visibleOnly ? styles.starImgVisibleOnly : ''}`}
          alt={`${i}star`}
          key={`starImg_${i}`}
          src={iconSrc}
          onMouseOver={
            !visibleOnly
              ? () => {
                  handleMouseover((i + 1) / 2);
                }
              : undefined
          }
          onClick={
            !visibleOnly && onChange
              ? () => {
                  setRate((i + 1) / 2);
                  onChange((i + 1) / 2);
                }
              : undefined
          }
          onMouseOut={!visibleOnly ? () => handleMouseout() : undefined}
        />
      );
    });
  };

  const stars = fillStarArray();

  return (
    <div className={`container ${containerAdditionalStyles}`}>
      <div className={`row ${rowAdditionalStyles}`}>
        <div className={`col ${colAdditionalStyles}`}>
          <div
            className={`${styles.resetStars} ${visibleOnly ? styles.resetStarsVisibleOnly : ''} ${
              bigSize ? styles.resetStarsBig : styles.resetStarsSmall
            }`}
            onMouseOver={!visibleOnly ? () => handleMouseover(0) : undefined}
            onClick={
              !visibleOnly && onChange
                ? () => {
                    setRate(0);
                    onChange(0);
                  }
                : undefined
            }
            onMouseOut={!visibleOnly ? () => handleMouseout() : undefined}
          />
          {stars}
          {ratersNumber ? (
            <span
              className={`${bigSize ? styles.ratersNumberLabelBig : styles.ratersNumberLabelSmall}`}
            >
              {ratersNumber}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RatingStars;
