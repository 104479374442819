import React, { useEffect } from 'react';

import { Trainer } from 'interfaces';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { useFormValidation } from 'shared';

import { Modal } from 'web-components';

import { FormControl, ImageInput, TextArea, TextInput } from '@components';

import { AppState } from '../../../store/types';
import Images from '../../../utils/images';
import TrainerSchema from '../../../utils/validation/TrainerSchema';

import styles from './TrainerFormModal.module.scss';
import { TrainerFormData, TrainerFormFieldName, TrainerFormModalProps } from './types';

const imagePlaceholder = Images.UPLOAD_IMAGE_PLACEHOLDER;

const formData = (data?: Trainer) => ({
  firstName: data?.firstName || '',
  lastName: data?.lastName || '',
  rideHours: data?.rideHours || '',
  carrierStartYear: data?.carrierStartYear || '',
  hobbies: data?.hobbies || undefined,
  favoriteMusic: data?.favoriteMusic || undefined,
  description: data?.description || undefined,
  qualifications: data?.qualifications || '',
  profession: data?.profession || '',
  picture: undefined,
  pictureUrl: !data?.pictures
    ? imagePlaceholder
    : `${data?.pictures.defaultThumbnail}?${data?.pictures.hash}`,
});

/**
 * Trainer form modal to add a new and edit a trainer.
 * @param data - The trainer data.
 * @param show - If 'true', the modal is displayed.
 * @param onSave - A function what will be called when the save button was pressed.
 * @param onCancel - A function what will be called when the cancel button was pressed.
 */
const TrainerFormModal: React.FC<TrainerFormModalProps> = ({
  data,
  onSave,
  onCancel,
  onError,
  ...rest
}) => {
  const { t } = useTranslation();

  const [form, setForm] = React.useState<TrainerFormData>(formData(data));
  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const { isUpdateTrainerLoading, isCreateTrainerLoading } = useSelector(
    (state: AppState) => state.trainer,
  );
  const { validationErrors } = useFormValidation<TrainerFormData>(TrainerSchema, form);

  useEffect(() => {
    setIsFormSubmitted(false);
    setForm(formData(data));
  }, [rest.show, data]);

  React.useEffect(() => {
    const errorMessage = validationErrors[Object.keys(validationErrors)[0]];

    onError(errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationErrors]);

  React.useEffect(() => {
    if (isFormSubmitted) {
      onSave(form, data?._id);

      setIsFormSubmitted(false);
    }
  }, [isFormSubmitted, onSave, form, data?._id]);

  useEffect(() => {
    setIsSaving(isCreateTrainerLoading || isUpdateTrainerLoading);
  }, [isCreateTrainerLoading, isUpdateTrainerLoading]);

  const onFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onSaveButtonClicked = () => {
    setIsFormSubmitted(true);
  };

  const onCancelButtonClicked = () => {
    setIsFormSubmitted(false);

    onCancel();
  };

  return (
    <Modal
      {...rest}
      title={t(data ? 'trainer_form_modal.edit_trainer' : 'trainer_form_modal.new_trainer')}
      actionButtons={[
        {
          text: t('common.rejection'),
          onClick: onCancelButtonClicked,
          classes: ['bg-rejection', 'text-primary', 'font-weight-bold', styles.button],
        },
        {
          text: isSaving ? t('common.saving') : t('common.save'),
          arguments: isSaving ? [{ name: 'disabled', value: true }] : [],
          onClick: (): void => onSaveButtonClicked(),
          classes: ['bg-secondary', 'text-primary', 'font-weight-bold', styles.button],
        },
      ]}
    >
      <form className="text-primary" noValidate>
        <div className="row py-3">
          <div className="col-4">
            <ImageInput
              url={form.pictureUrl ?? ''}
              alt={t('trainer_details_modal.image_alt')}
              name={TrainerFormFieldName.picture}
              type="small"
              onChange={(file?: File | null): void => {
                setForm({
                  ...form,
                  pictureUrl: file ? URL.createObjectURL(file) : imagePlaceholder,
                  picture: file,
                });
              }}
              onDelete={(): void => {
                setForm({ ...form, pictureUrl: imagePlaceholder, picture: null });
              }}
            />
          </div>

          <div className="col-8">
            <FormControl className="w-100 m-0">
              <>
                <label>{t('trainer_form_modal.last_name')}</label>
                <TextInput
                  name={TrainerFormFieldName.lastName}
                  value={form.lastName}
                  onChange={onFieldChange}
                />
              </>
            </FormControl>

            <FormControl className="w-100 m-0">
              <>
                <label>{t('trainer_form_modal.first_name')}</label>
                <TextInput
                  name={TrainerFormFieldName.firstName}
                  value={form.firstName}
                  onChange={onFieldChange}
                />
              </>
            </FormControl>

            <div className="row">
              <div className="col">
                <FormControl className="w-100 m-0">
                  <>
                    <label>{t('trainer_form_modal.ride_hours')}</label>
                    <TextInput
                      name={TrainerFormFieldName.rideHours}
                      value={form.rideHours}
                      onChange={onFieldChange}
                    />
                  </>
                </FormControl>
              </div>
              <div className="col">
                <FormControl className="w-100 m-0">
                  <>
                    <label>{t('trainer_form_modal.carrier_start')}</label>
                    <TextInput
                      name={TrainerFormFieldName.carrierStartYear}
                      value={form.carrierStartYear}
                      onChange={onFieldChange}
                    />
                  </>
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormControl className="w-100 m-0">
              <>
                <label>{t('trainer_form_modal.favorite_music')}</label>
                <TextInput
                  name={TrainerFormFieldName.favoriteMusic}
                  value={form.favoriteMusic}
                  onChange={onFieldChange}
                />
              </>
            </FormControl>
          </div>
          <div className="col">
            <FormControl className="w-100 m-0">
              <>
                <label>{t('trainer_form_modal.hobbies')}</label>
                <TextInput
                  name={TrainerFormFieldName.hobbies}
                  value={form.hobbies}
                  onChange={onFieldChange}
                />
              </>
            </FormControl>
          </div>
        </div>

        <div className="row">
          <FormControl className="w-100 m-0 px-3">
            <>
              <label>{t('trainer_form_modal.profession')}</label>
              <TextArea
                rows={1}
                value={form[TrainerFormFieldName.profession]}
                name={TrainerFormFieldName.profession}
                onChange={onFieldChange}
              />
            </>
          </FormControl>

          <FormControl className="w-100 m-0 px-3">
            <>
              <label>{t('trainer_form_modal.qualifications')}</label>
              <TextArea
                rows={1}
                value={form.qualifications}
                name={TrainerFormFieldName.qualifications}
                onChange={onFieldChange}
              />
            </>
          </FormControl>
        </div>

        <div className="row">
          <FormControl className="w-100 m-0 px-3">
            <>
              <label>{t('trainer_form_modal.description')}</label>
              <TextArea
                rows={2}
                value={form.description}
                name={TrainerFormFieldName.description}
                onChange={onFieldChange}
              />
            </>
          </FormControl>
        </div>
      </form>
    </Modal>
  );
};

export default TrainerFormModal;
