import { Video } from 'interfaces';

export interface ArchiveFormModalProps {
  data?: Video;
  show: boolean;
  onSave: (data: ArchiveFormData, id?: string) => void;
  onCancel: () => void;
  onError: (message: string) => void;
}

export enum ArchiveFormFieldName {
  name = 'name',
  isPublished = 'isPublished',
  picture = 'picture',
  pictureUrl = 'pictureUrl',
}

export interface ArchiveFormData {
  [ArchiveFormFieldName.name]: string;
  [ArchiveFormFieldName.isPublished]: boolean;
  [ArchiveFormFieldName.picture]?: File | null;
  [ArchiveFormFieldName.pictureUrl]?: string;
}
