import { getErrorMessage } from 'shared';
import * as yup from 'yup';

import { ForgottenPasswordFields } from '../../screens/ForgottenPasswordScreen/types';

const ForgottenPasswordSchema: yup.SchemaOf<ForgottenPasswordFields> = yup.object().shape({
  email: yup
    .string()
    .required(getErrorMessage('email', 'email_required'))
    .email(getErrorMessage('email', 'email_invalid')),
});

export default ForgottenPasswordSchema;
