import React, { useEffect, useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useFormValidation } from 'shared';

import { Input, Button, Card, Image } from 'web-components';

import { FormControl, Link, Error } from '@components';

import { ROUTE } from '@navigation/types';

import { login } from '../../store/auth/actions';
import { AppState } from '../../store/types';
import Images from '../../utils/images';
import LoginSchema from '../../utils/validation/LoginSchema';

import styles from './LoginScreen.module.scss';
import { LoginFields } from './types';

const LoginScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated, loginError, isLoginLoading } = useSelector(
    (state: AppState) => state.auth,
  );

  const [fields, setFields] = React.useState<LoginFields>({ email: '', password: '' });
  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

  const { isFormValid, validationErrors } = useFormValidation<LoginFields>(LoginSchema, fields);

  useLayoutEffect(() => {
    if (!isLoginLoading) {
      isAuthenticated && navigate(ROUTE.StreamList);
    }
  }, [isAuthenticated, navigate, isLoginLoading]);

  useEffect(() => {
    setErrorMessage(
      (isFormSubmitted && validationErrors[Object.keys(validationErrors)[0]]) || loginError,
    );
  }, [isFormSubmitted, validationErrors, loginError]);

  const onFieldChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    setIsFormSubmitted(true);

    if (isFormValid) {
      dispatch(login({ email: fields.email, password: fields.password }));
    }
  };

  return (
    <div className={`container-fluid vh-100 d-flex bg-primary mt-2 ${styles.loginScreenContainer}`}>
      <div className={`d-flex flex-column align-items-center ${styles.loginContainerSize}`}>
        <Image src={Images.LOGIN_LOGO} />
        <form className="text-right text-secondary mt-4 w-100" onSubmit={onSubmit} noValidate>
          <Card additionalStyles={`text-primary mb-5 mt-2 w-100 ${styles.card}`}>
            <>
              <FormControl>
                <Input
                  placeholder={t('login_screen.email')}
                  type="email"
                  name="email"
                  value={fields.email}
                  onChange={onFieldChange}
                  className={styles.input}
                />
              </FormControl>

              <FormControl>
                <Input
                  placeholder={t('login_screen.password')}
                  type="password"
                  name="password"
                  value={fields.password}
                  onChange={onFieldChange}
                  className={styles.input}
                />
              </FormControl>

              <Link to={ROUTE.ForgottenPassword} type="small" className="text-decoration-none">
                {t('login_screen.forgotten_password')}
              </Link>

              {errorMessage && <Error error={errorMessage} />}
              <div />
            </>
          </Card>

          <div className="text-center">
            <Button
              type="submit"
              additionalStyles={`${styles.button} rounded-5 w-100 text-uppercase text-primary btn-lg font-italic text-primary bg-light mb-5 text-center text-normal`}
              disabled={isLoginLoading}
            >
              {isLoginLoading ? t('login_screen.logging_in') : t('login_screen.login')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
