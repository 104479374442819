import { GetStreamsParams } from 'api_client';
import { Stream } from 'interfaces';

export const SET_STREAMS = 'SET_STREAMS';
export const APPEND_STREAMS = 'APPEND_STREAMS';

export const SET_STREAMS_GET_PARAMS = 'SET_STREAMS_GET_PARAMS';
export const INC_STREAMS_CURRENT_PAGE = 'INC_STREAMS_CURRENT_PAGE';
export const RESET_STREAMS_CURRENT_PAGE = 'RESET_STREAMS_CURRENT_PAGE';
export const SET_STREAMS_LAST_PAGE_REACHED = 'SET_STREAMS_LAST_PAGE_REACHED';

export const SET_CREATE_STREAM_SUCCESS = 'SET_CREATE_STREAM_SUCCESS';
export const SET_UPDATE_STREAM_SUCCESS = 'SET_UPDATE_STREAM_SUCCESS';
export const SET_DELETE_STREAM_SUCCESS = 'SET_DELETE_STREAM_SUCCESS';

export const SET_GET_STREAMS_ERROR = 'SET_GET_STREAMS_ERROR';
export const SET_CREATE_STREAM_ERROR = 'SET_CREATE_STREAM_ERROR';
export const SET_UPDATE_STREAM_ERROR = 'SET_UPDATE_STREAM_ERROR';
export const SET_DELETE_STREAM_ERROR = 'SET_DELETE_STREAM_ERROR';

export const SET_GET_STREAMS_LOADING = 'SET_GET_STREAMS_LOADING';
export const SET_CREATE_STREAM_LOADING = 'SET_CREATE_STREAM_LOADING';
export const SET_UPDATE_STREAM_LOADING = 'SET_UPDATE_STREAM_LOADING';
export const SET_DELETE_STREAM_LOADING = 'SET_DELETE_STREAM_LOADING';

export interface SetStreams {
  type: typeof SET_STREAMS;
  payload: Stream[];
}

export interface AppendStreams {
  type: typeof APPEND_STREAMS;
  payload: Stream[];
}

export interface SetStreamsGetParams {
  type: typeof SET_STREAMS_GET_PARAMS;
  payload: GetStreamsParams;
}

export interface IncStreamsCurrentPage {
  type: typeof INC_STREAMS_CURRENT_PAGE;
}

export interface ResetStreamsCurrentPage {
  type: typeof RESET_STREAMS_CURRENT_PAGE;
}

export interface SetStreamsLastPageReached {
  type: typeof SET_STREAMS_LAST_PAGE_REACHED;
  payload: boolean;
}

export interface SetCreateStreamSuccess {
  type: typeof SET_CREATE_STREAM_SUCCESS;
  payload: boolean;
}

export interface SetUpdateStreamSuccess {
  type: typeof SET_UPDATE_STREAM_SUCCESS;
  payload: {
    success: boolean;
    data?: Stream;
  };
}

export interface SetDeleteStreamSuccess {
  type: typeof SET_DELETE_STREAM_SUCCESS;
  payload: boolean;
}

export interface SetGetStreamsError {
  type: typeof SET_GET_STREAMS_ERROR;
  payload?: string;
}

export interface SetCreateStreamError {
  type: typeof SET_CREATE_STREAM_ERROR;
  payload?: string;
}

export interface SetUpdateStreamError {
  type: typeof SET_UPDATE_STREAM_ERROR;
  payload?: string;
}

export interface SetDeleteStreamError {
  type: typeof SET_DELETE_STREAM_ERROR;
  payload?: string;
}

export interface SetGetStreamsLoading {
  type: typeof SET_GET_STREAMS_LOADING;
  payload: boolean;
}

export interface SetCreateStreamLoading {
  type: typeof SET_CREATE_STREAM_LOADING;
  payload: boolean;
}

export interface SetUpdateStreamLoading {
  type: typeof SET_UPDATE_STREAM_LOADING;
  payload: boolean;
}

export interface SetDeleteStreamLoading {
  type: typeof SET_DELETE_STREAM_LOADING;
  payload: boolean;
}

export type StreamActionTypes =
  | SetStreams
  | SetStreamsGetParams
  | IncStreamsCurrentPage
  | ResetStreamsCurrentPage
  | AppendStreams
  | SetStreamsLastPageReached
  | SetCreateStreamSuccess
  | SetUpdateStreamSuccess
  | SetDeleteStreamSuccess
  | SetGetStreamsError
  | SetCreateStreamError
  | SetUpdateStreamError
  | SetDeleteStreamError
  | SetGetStreamsLoading
  | SetCreateStreamLoading
  | SetUpdateStreamLoading
  | SetDeleteStreamLoading;
