import React from 'react';

import styles from './Button.module.scss';
import { ButtonProps } from './types';

/**
 * General button component
 * @param children - The label.
 * @param type - The type of the button.
 * @param className - Custom classes.
 * @param onClick - A function what will be called when the button was pressed.
 */
const Button: React.FC<ButtonProps> = ({ children, type = 'button', className = '', ...rest }) => {
  return (
    <button
      {...rest}
      type={type}
      className={`btn btn-primary shadow-none ${styles.customButton} ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
