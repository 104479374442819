import React from 'react';

import { useTranslation } from 'react-i18next';

import { Modal, Image } from 'web-components';

import styles from './TrainerDetailsModal.module.scss';
import { TrainerDetailsModalProps } from './types';

/**
 * Trainer details modal.
 * @param data - The trainer's data.
 * @param show - If 'true', the modal is displayed.
 * @param onCancel - A function what will be called when the cancel button was pressed.
 */
const TrainerDetailsModal: React.FC<TrainerDetailsModalProps> = ({ data, onCancel, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Modal
      {...rest}
      title={t('trainer_details_modal.title')}
      actionButtons={[
        {
          text: t('common.back'),
          onClick: onCancel,
          classes: ['bg-secondary', 'text-primary', 'font-weight-bold'],
        },
      ]}
    >
      <div className="text-primary">
        <div className="row py-3">
          <div className="col">
            <label>{t('trainer_details_modal.profile_image')}</label>
            <div className={styles.imageBox}>
              <Image
                src={data.pictures?.default ?? ''}
                alt={t('trainer_details_modal.image_alt')}
                className={`${styles.image} border border-dark`}
              />
            </div>
          </div>

          <div className="col">
            <div className="mb-1">{t('trainer_details_modal.last_name')}</div>
            <div className="mb-3 font-weight-bold">{data.lastName}</div>

            <div className="mb-1">{t('trainer_details_modal.first_name')}</div>
            <div className="mb-3 font-weight-bold">{data.firstName}</div>

            <div className="mb-1">{t('trainer_details_modal.ride_hours')}</div>
            <div className="mb-3 font-weight-bold">{data.rideHours}</div>

            <div className="mb-1">{t('trainer_details_modal.carrier_start')}</div>
            <div className="mb-3 font-weight-bold">{data.carrierStartYear}</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="mb-1">{t('trainer_details_modal.favorite_music')}</div>
            <div className="mb-3 font-weight-bold">{data.favoriteMusic}</div>
          </div>
          <div className="col">
            <div className="mb-1">{t('trainer_details_modal.hobbies')}</div>
            <div className="mb-3 font-weight-bold">{data.hobbies}</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="mt-3 mb-1">{t('trainer_details_modal.profession')}</div>
            <div className="mb-3 font-weight-bold">{data.profession}</div>
          </div>
          <div className="col">
            <div className="mt-3 mb-1">{t('trainer_details_modal.qualifications')}</div>
            <div className="mb-3 font-weight-bold">{data.qualifications}</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="mt-3 mb-3">{t('trainer_details_modal.description')}</div>
            <div className="mb-3 font-weight-bold">{data.description}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TrainerDetailsModal;
