import { GetTrainersParams, TrainerClient, isLastPageReached } from 'api_client';

import { Trainer } from 'interfaces';

import { ThunkDispatch } from 'redux-thunk';

import { Action, Dispatch } from "redux";

import { TrainerFormData } from '../../screens/TrainerListScreen/TrainerFormModal/types';

import { TAppState } from "../index";

import { AppThunk } from '../types';

import {
  SET_CREATE_TRAINER_ERROR,
  SET_CREATE_TRAINER_LOADING,
  SET_CREATE_TRAINER_SUCCESS,
  SET_GET_TRAINERS_ERROR,
  SET_GET_TRAINERS_LOADING,
  SET_UPDATE_TRAINER_ERROR,
  SET_UPDATE_TRAINER_LOADING,
  SET_UPDATE_TRAINER_SUCCESS,
  SET_TRAINERS,
  SET_DELETE_TRAINER_SUCCESS,
  SET_DELETE_TRAINER_ERROR,
  SET_DELETE_TRAINER_LOADING,
  SET_ALL_TRAINERS,
  SET_GET_ALL_TRAINERS_ERROR,
  SET_GET_ALL_TRAINERS_LOADING,
  APPEND_TRAINERS,
  SET_TRAINERS_LAST_PAGE_REACHED,
  RESET_TRAINERS_CURRENT_PAGE,
  INC_TRAINERS_CURRENT_PAGE,
  SET_TRAINERS_GET_PARAMS,
} from './actionTypes';

export const setTrainers = (trainers: Trainer[]): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_TRAINERS,
    payload: trainers,
  });
};

export const setTrainersGetParams = (params: GetTrainersParams): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_TRAINERS_GET_PARAMS,
    payload: params,
  });
};

export const setAllTrainers = (trainers: Trainer[]): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_ALL_TRAINERS,
    payload: trainers,
  });
};

export const appendTrainers = (users: Trainer[]): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: APPEND_TRAINERS,
    payload: users,
  });
};

export const setTrainersLastPageReached = (reached: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_TRAINERS_LAST_PAGE_REACHED,
    payload: reached,
  });
};

export const setCreateTrainerSuccess = (isSuccess: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_CREATE_TRAINER_SUCCESS,
    payload: isSuccess,
  });
};

export const setUpdateTrainerSuccess = (isSuccess: boolean, data?: Trainer): AppThunk => (
  dispatch: Dispatch,
): void => {
  dispatch({
    type: SET_UPDATE_TRAINER_SUCCESS,
    payload: {
      success: isSuccess,
      data: data,
    },
  });
};

export const setDeleteTrainerSuccess = (isSuccess: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_DELETE_TRAINER_SUCCESS,
    payload: isSuccess,
  });
};

export const setGetTrainersError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GET_TRAINERS_ERROR,
    payload: error,
  });
};

export const setGetAllTrainersError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GET_ALL_TRAINERS_ERROR,
    payload: error,
  });
};

export const setCreateTrainerError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_CREATE_TRAINER_ERROR,
    payload: error,
  });
};

export const setUpdateTrainerError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_UPDATE_TRAINER_ERROR,
    payload: error,
  });
};

export const setDeleteTrainerError = (error?: string): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_DELETE_TRAINER_ERROR,
    payload: error,
  });
};

export const setGetTrainersLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GET_TRAINERS_LOADING,
    payload: isLoading,
  });
};

export const setGetAllTrainersLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_GET_ALL_TRAINERS_LOADING,
    payload: isLoading,
  });
};

export const setCreateTrainerLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_CREATE_TRAINER_LOADING,
    payload: isLoading,
  });
};

export const setUpdateTrainerLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_UPDATE_TRAINER_LOADING,
    payload: isLoading,
  });
};

export const setDeleteTrainerLoading = (isLoading: boolean): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: SET_DELETE_TRAINER_LOADING,
    payload: isLoading,
  });
};

export const incTrainersCurrentPage = (): AppThunk => (dispatch: Dispatch, getState): void => {
  if (!getState().trainer.isTrainerListLastPageReached) {
    dispatch({
      type: INC_TRAINERS_CURRENT_PAGE,
    });
  }
};

export const resetTrainersCurrentPage = (): AppThunk => (dispatch: Dispatch): void => {
  dispatch({
    type: RESET_TRAINERS_CURRENT_PAGE,
  });
};

export const getTrainers = (params?: GetTrainersParams, lazyLoad = false): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setGetTrainersLoading(true));

    const trainers = await TrainerClient.getAll(params);

    if (lazyLoad) {
      dispatch(appendTrainers(trainers));
    } else {
      dispatch(setTrainers(trainers));
    }

    dispatch(setTrainersLastPageReached(isLastPageReached(trainers, params)));
  } catch (error) {
    dispatch(setGetTrainersError(error.message));
  } finally {
    dispatch(setGetTrainersLoading(false));
  }
};

export const getAllTrainers = (params?: GetTrainersParams): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setGetAllTrainersLoading(true));

    const trainers = await TrainerClient.getAll({
      ...params,
      limit: 100,
    });

    dispatch(setAllTrainers(trainers));
  } catch (error) {
    dispatch(setGetAllTrainersError(error.message));
  } finally {
    dispatch(setGetAllTrainersLoading(false));
  }
};

export const createTrainer = (trainerData: TrainerFormData): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setCreateTrainerLoading(true));
    dispatch(setCreateTrainerSuccess(false));

    const requestData = {
      firstName: trainerData.firstName,
      lastName: trainerData.lastName,
      hobbies: trainerData.hobbies,
      rideHours: trainerData.rideHours,
      favoriteMusic: trainerData.favoriteMusic,
      carrierStartYear: trainerData.carrierStartYear,
      picture: trainerData.picture,
      description: trainerData.description,
      qualifications: trainerData.qualifications,
      profession: trainerData.profession,
    };

    await TrainerClient.create(requestData);

    dispatch(setCreateTrainerSuccess(true));
  } catch (error) {
    dispatch(setCreateTrainerError(error.message));
  } finally {
    dispatch(setCreateTrainerLoading(false));
  }
};

export const updateTrainer = (id: string, trainerData: TrainerFormData): AppThunk => async (
  dispatch: ThunkDispatch<TAppState, void, Action<string>>,
): Promise<void> => {
  try {
    dispatch(setUpdateTrainerLoading(true));
    dispatch(setUpdateTrainerSuccess(false));

    const requestData = {
      firstName: trainerData.firstName,
      lastName: trainerData.lastName,
      hobbies: trainerData.hobbies,
      rideHours: trainerData.rideHours,
      favoriteMusic: trainerData.favoriteMusic,
      carrierStartYear: trainerData.carrierStartYear,
      picture: trainerData.picture,
      description: trainerData.description,
      qualifications: trainerData.qualifications,
      profession: trainerData.profession,
    };

    const data = await TrainerClient.update(id, requestData);
    if (data.pictures) data.pictures.hash = Date.now().toString();
    dispatch(setUpdateTrainerSuccess(true, data));
  } catch (error) {
    dispatch(setUpdateTrainerError(error.message));
  } finally {
    dispatch(setUpdateTrainerLoading(false));
  }
};

export const deleteTrainer = (id: string): AppThunk => async (dispatch: ThunkDispatch<TAppState, void, Action<string>>): Promise<void> => {
  try {
    dispatch(setDeleteTrainerLoading(true));
    dispatch(setDeleteTrainerSuccess(false));

    await TrainerClient.delete(id);

    dispatch(setDeleteTrainerSuccess(true));
  } catch (error) {
    dispatch(setDeleteTrainerError(error.message));
  } finally {
    dispatch(setDeleteTrainerLoading(false));
  }
};
